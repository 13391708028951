import Requester from '../requester';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from 'src/app/etc/queryParams';
import { Observable } from 'rxjs';
import FormField from 'src/app/models/form-field.model';

@Injectable()
export class FormFieldService extends Requester {

	constructor(
		protected http: HttpClient,
		protected queryParam: QueryParams
	) {
		super('forms/fields', FormField, http, queryParam);
	}

	public getList(_body = null): Observable<any> {
		return super.getList(_body);
	}

	public getById(_id, _body = null): Observable<any> {
		return super.getById(_id, _body);
	}
}
