import checkField from './check-field';
import Event from './event.model';
import Organisation from './organisation.model';
import Tax from './tax.model';
import TicketType from './ticket-type.model';

export default class AddOn {
    static selected: { [key: string]: number[] } = {};
    _id: string;
    ticketTypeIds: TicketType[];
    eventId: Event;
    organisationId: Organisation;
    name: string
    description?: string
    price: number;
    taxId: Tax;
    isChecked = false;
    quota: number;
    stock: number;
    available: boolean;
    get ticketsIndex(): number[] {
        return AddOn.selected[this._id];
    }
    set ticketsIndex(value) {
        AddOn.selected[this._id] = value;
    }


    constructor(addon?: any) {
        this._id = addon._id;
        this.ticketTypeIds = addon.ticketTypeIds;
        this.eventId = addon.eventId;
        this.organisationId = addon.organisationId;
        this.name = addon.name;
        this.description = addon.description;
        this.price = addon.price;
        this.taxId = addon.taxId;
        this.quota = addon.quota;
        this.stock = addon.stock;
        if (!this.ticketsIndex) {
            AddOn.selected[this._id] = [];
        }
    }

    public add(i: number) {
        this.ticketsIndex.push(i);
    }

    public remove(i: number) {
        this.ticketsIndex = this.ticketsIndex.filter(current => current !== i);
    }

    public unavailableFor(i: number): boolean {
        return this.quota ? (this.stock - this.ticketsIndex.length) <= 0 && !this.setFor(i) : false
    }

    public setFor(i: number) {
        return this.ticketsIndex.includes(i);
    }
}
