import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-m-place-left',
  templateUrl: './m-place-left.component.html',
  styleUrls: ['./m-place-left.component.scss']
})
export class MPlaceLeftComponent implements OnDestroy {
  @Input() stock: number;

  constructor() {}

  ngAfterViewInit() {}

  ngOnDestroy(): void {
  }

}
