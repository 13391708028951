import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-form-radio-list',
  templateUrl: './m-form-radio-list.component.html',
  styleUrls: ['./m-form-radio-list.component.scss']
})
export class MFormRadioListComponent implements OnInit {

  @Input() options: Array<any> = [];
  @Input() name: string;
  @Input() selected: string;
  @Input() label: string;
  @Input() formNumber: number;
  @Input() required: boolean;
  @Input() description: string;
  @Output() updateSelection: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.selected && this.selected !== null) {
        this.selected = this.options[0].value || this.options[0];
        this.selectedRadioChanged(this.selected);
      }
    })
  }

  selectedRadioChanged(value: string): void {
    this.updateSelection.emit(value);
  }

}
