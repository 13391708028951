<app-t-content-center>
  <div slotContent>

    <p>{{"Veuillez renseigner vos coordonnées afin que notre équipe soit en mesure de vous recontacter en cas de" + 
      " disponibilité de nouveaux tickets :" | customTranslation: "descRegistration"}}</p>

    <form [formGroup]="registrationForm">
      <app-m-form-row columns="full">
        <app-a-form-select-field [label]="'Ticket souhaité' | customTranslation" [required]="true" id="ticketTypeId"
          [options]="tickets"
          (InputChange)="changeField('ticketTypeId', $event)">
        </app-a-form-select-field>
      </app-m-form-row>

      <app-m-form-row columns="1fr-1fr-1fr">
        <app-a-form-input-field [label]="'Nom' | customTranslation" [required]="true" id="lastName" 
          (InputChange)="changeField('lastName',$event)">
        </app-a-form-input-field>
        <app-a-form-input-field [label]="'Prénom' | customTranslation" [required]="true" id="firstName" 
          (InputChange)="changeField('firstName', $event)">
        </app-a-form-input-field>
        <app-a-form-input-field [label]="'Adresse e-mail' | customTranslation" type="email" [required]="true" id="email" 
          (InputChange)="changeField('email', $event)">
        </app-a-form-input-field>
      </app-m-form-row>
      <div class="form-actions">
        <app-a-button [text]="'Enregistrer' | customTranslation" (buttonClicked)='onSubmit()' [isDisabled]="registrationForm.invalid"></app-a-button>
      </div>
    </form>
  </div>
</app-t-content-center>
