import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeLineService {
  noPayment$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  noCountdown$ : BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentStep$: Subject<string> = new Subject();
  countDownSignal$: Subject<boolean>= new Subject
  
  constructor() { }

  updateTimeLine(step: string): void {
    /**
     * SetTimeout used to avoid ExpressionChangedAfterItHasBeenCheckedError
     */
    setTimeout(() => this.currentStep$.next(step))
  }

  deletePayment(){
    this.noPayment$.next(true);
  }

  restorePayment(){
    this.noPayment$.next(false);
  }

  deleteCountdown(){
    this.noCountdown$.next(true);
  }

  initCountDown(){
    this.countDownSignal$.next(true);
  }

  stopCountDown(){
    this.countDownSignal$.next(false);
  }

}
