<div class="o-confirmation">
  <figure><img src="assets/img/confirmation.svg" alt="confirmation"></figure>
  <ng-content select="[slotTitle]"></ng-content>
  <div class="text">
    <ng-content select="[slotText]"></ng-content>
  </div>
  <div class="actions">
    <ng-content select="[slotActions]"></ng-content>
  </div>

</div>
