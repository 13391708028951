<app-t-content-w-sidebar>

    <div id="order-summary" slotContent>
        <app-a-title-main text="Vérification de la commande"></app-a-title-main>

        <app-m-content-section>

            <p class="page-desc" slotContent>Un email sera transmis aux emails ci-dessous afin d'accéder à leur e-billet.</p>

            <p class="page-desc" slotContent><strong>Important :</strong> Vous êtes sur le point d'acheter des billets sécurisés qui seront émis dans une blockchain. Merci de vérifier que les emails ci-dessous sont correctement orthographiés. <strong>En cas d'erreur les billets seront perdus et vous ne pourrez pas les récupérer.</strong></p>

            <h2 slotContent>{{widget.forProduct ? ('Liste des produits' | customTranslation: 'productList') :( 'Liste des tickets' | customTranslation: 'ticketList')}} :</h2>

            <ng-container slotContent class="tickets_list">

                <div class="email-item" *ngFor="let email of emailsList; let index = index;">
                    <p style="font-weight: bold;font-size:20px;margin-bottom: 10px;">Billet #{{index+1}} - {{ tickets[index].name }}</p>
                    <div class="email-wrapper">
	                    <input 
	                    	class="email-input"
	                        type="email" 
	                        [formControl]="emailsForm[index]" 
	                        [class.is-invalid] = "emailsForm[index].invalid && emailsForm[index].touched"
	                    />
	                    <div class="email-button" *ngIf="emailsForm[index].disabled">
                            <app-a-button (buttonClicked)="enableField(index)" text="{{'Modifier l\'adresse email'}}"></app-a-button>
                        </div>
                        <div class="email-button" *ngIf="!emailsForm[index].disabled">
                            <app-a-button 
                                [class.disabled]="emailsForm[index].invalid && emailsForm[index].touched" 
                                (buttonClicked)="validateEmail(index)" 
                                text="{{'Valider'}}"
                            >
                            </app-a-button>
                        </div>
                    </div>
                    <p *ngIf="email.exists" class="email-desc">Un compte OandB Wallet existe à cette adresse. Le billet sera rattaché à se compte déjà existant.</p>
                    <p *ngIf="!email.exists" class="email-desc">Aucun compte OandB Wallet n'existe à cette adresse, vous pourrez le créer plus tard.</p>
                    <!-- <div *ngIf="emailsForm[index].disabled">
                        <app-a-button (buttonClicked)="enableField(index)" text="{{'Modifier l\'adresse email'}}"></app-a-button>
                    </div>
                    <div *ngIf="!emailsForm[index].disabled">
                        <app-a-button 
                            [class.disabled]="emailsForm[index].invalid && emailsForm[index].touched" 
                            (buttonClicked)="validateEmail(index)" 
                            text="{{'Valider'}}"
                        >
                        </app-a-button>
                    </div> -->   
                </div>

            </ng-container>
        </app-m-content-section>

    </div>

    <app-o-summary slotSidebar [billingForm]="billingForm" [orderTicketForms]="orderTicketForms"
    [ticketsToOrder]="tickets"></app-o-summary>

</app-t-content-w-sidebar>