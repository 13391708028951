import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { translateString } from 'src/app/etc/custom-translation/custom-translation.pipe';
import Order from 'src/app/models/order.model';
import { AppPrice, GlobalStateObject } from 'src/app/models/type.definition';
import Widget from 'src/app/models/widget.model';
import { OrderService, TimeLineService } from 'src/app/providers';
import { SetInsurance } from 'src/app/store/actions/global.actions';
import { GlobalState, GlobalStateModel } from 'src/app/store/states/global.state';


@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.html',
  styleUrls: ['./insurance.scss']
})
export class InsurancePage implements OnInit, OnDestroy {
  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;
  public selectedDate: Date;
  public hasInsurance: boolean;
  public btnDisabled: boolean = true
  public subscriptions = new Subscription();
  public widgetId: string;
  globalObject: GlobalStateObject;
  order: Order;
  insurancePrice: AppPrice

  public insuranceOptions = [
    {
      value: 'yes',
      label: translateString('Je souhaite assurer mes billets.', "radioInsuranceYes"),
      description: `J'atteste avoir pris
  préalablement connaissance de la fiche d'informations
  et de conseil, du document d'information sur le produit
  d'assurance et de la notice d'information, et y adhère
  sans réserve et j'accepte de signer électroniquement
  les différents documents constituant mon contrat. Je consens expressément à la collecte et à l'utilisation
  de mes données personnelles par la OandB et par NEAT
  dans le cadre de la souscription et de la gestion de mon
  contrat d'assurance.`,
    },
    {
      value: 'no',
      label: translateString('Non merci.', "radioInsuranceNo")
    }
  ];

  constructor(
    private timeLineService: TimeLineService,
    private store: Store,
    public orderService: OrderService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.timeLineService.updateTimeLine('insurance');
    const widget: Widget = this.store.selectSnapshot(state => GlobalState.get(state, 'widget'));
    this.subscriptions.add(this.globalState$.subscribe(async (state: GlobalStateModel) => {
      this.globalObject = state.globalObject;
      this.order = this.globalObject.order;
      await this.getPrice();
    }))
  }

  async getPrice() {
    this.orderService.getInsurancePrice(this.order.token).subscribe((res: any) => {
      this.insurancePrice = res.amount
    })
  }

  async changeInsuranceOption(value: string) {
    this.btnDisabled = false;
    if (value === 'yes') {
      this.store.dispatch(new SetInsurance({ amount: this.insurancePrice }));
    }
    else {
      this.store.dispatch(new SetInsurance({ amount: null }));
    }

  }

}
