import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { PopInService, TrackingService } from './providers';
import { GlobalState, GlobalStateModel } from './store/states/global.state';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;
  subscription = new Subscription();
  steps = ['closed', 'date-selection', 'tickets-list', 'seat-selection', 'contact-information', 'insurance', "order-summary", 'summary', 'confirmation', 'registration'];
  currentStepId: number = 0;
  currentStep: string;
  preventRouting: boolean;
  isMaintenance: boolean = true
  reload: boolean;
  popIn: any;

  trackingGTM: string | null = null;
  trackingPixel: string | null = null;
  trackingGA: string | null = null;


  @HostListener("window:beforeunload", ["$event"]) async unloadHandler(event: BeforeUnloadEvent) {
    if (!this.reload) {
      /** @todo décommenter quand widget terminé */
      // event.returnValue = true;
    }
  }

  constructor(
    private router: Router,
    private location: Location,
    private popInService: PopInService,
    private trackingService: TrackingService,
    private store: Store,
  ) {
    this.subscription.add(this.popInService.displayPopIn$.subscribe(data => {
      this.popIn = data;
    }))

    this.subscription.add(router.events.subscribe((event) => {
      if (event instanceof NavigationStart && !event.url.includes('closed')) {
        this.reload = false;
        const splitUrl = event.url.split('/');
        if (splitUrl.length > 2) {

          if (event.id === 1) {
            this.router.navigate([`/${splitUrl[1]}`]);
          }
          else {
            let newStepId: number;
            if (splitUrl[2]) {
              newStepId = splitUrl[2] === 'seat-selection' ? 2 : this.steps.indexOf(splitUrl[2]);
            }
            else {
              newStepId = 0;
            }

            if (newStepId < this.currentStepId) {


              if (!['registration', 'confirmation'].includes(this.currentStep)) {
                this.reload = true;
                const url = `/${splitUrl[1]}/${this.steps[this.currentStepId]}`
                location.go(url)
                this.router.navigate([url]);
                this.preventRouting = true;
              }
            }
            else {
              this.currentStepId = newStepId;
            }
          }
        }
        // this.currentStep = splitUrl[2];
      }


    }));
  }

  ngOnInit(): void {


    this.subscription.add(this.globalState$.subscribe((state: GlobalStateModel) => {

      const isTrackingLoaded = (state.globalObject.tracking.ga !== null)

      if (!this.trackingGTM && state.globalObject.tracking.gtm) {
        this.trackingGTM = state.globalObject.tracking.gtm;
        this.trackingService.loadGTM(state.globalObject.tracking.gtm);
      }

      if (isTrackingLoaded) {
        if (this.trackingGA === null && state.globalObject.tracking.ga) {
          this.trackingGA = state.globalObject.tracking.ga;
          this.trackingService.loadGA("G-3PK0ETLW10", state.globalObject.tracking.ga);
        }
        else if (this.trackingGA === null) {
          this.trackingGA = ""
          this.trackingService.loadGA("G-3PK0ETLW10");
        }
      }

      if (!this.trackingPixel && state.globalObject.tracking.pixel) {
        this.trackingPixel = state.globalObject.tracking.pixel;
        this.trackingService.loadPixel(state.globalObject.tracking.pixel);
      }

      this.isMaintenance = state.globalObject.isMaintenance
    }))

  }

  cancelRouting(reload: boolean) {
    this.preventRouting = false;
    if (reload) {
      location.reload();
    }
  }

  closePopIn() {
    this.popInService.closePopIn();
  }

}
