import checkField from './check-field';
import Event from './event.model';
import TicketType from './ticket-type.model';

export default class TicketTypeCategory {
    _id: string;
    quota: number;
    isOpened: boolean;
    name: string;
    eventId: Event;
    stock: number;
    tickets: TicketType[];
    isProduct: boolean;
    seatsIoCategoryId: string;
    seatsIoColor: string;
    startPrice: number;
    stockBuffer: number;
    constructor(_ticketTypeCategory?: any) {
        if (checkField(_ticketTypeCategory?._id)) {
            this._id = _ticketTypeCategory._id;
        }
        if (checkField(_ticketTypeCategory?.quota)) {
            this.quota = _ticketTypeCategory.quota;
        }
        if (checkField(_ticketTypeCategory?.isOpened)) {
            this.isOpened = _ticketTypeCategory.isOpened;
        }
        if (checkField(_ticketTypeCategory?.name)) {
            this.name = _ticketTypeCategory.name;
        }
        if (checkField(_ticketTypeCategory?.eventId)) {
            this.eventId = _ticketTypeCategory.eventId;
        }
        if (checkField(_ticketTypeCategory?.isProduct)) {
            this.isProduct = _ticketTypeCategory.isProduct;
        }
        if (checkField(_ticketTypeCategory?.seatsIoCategoryId)) {
            this.seatsIoCategoryId = _ticketTypeCategory.seatsIoCategoryId;
        }
        if (checkField(_ticketTypeCategory?.seatsIoColor)) {
            this.seatsIoColor = _ticketTypeCategory.seatsIoColor;
        }
        this.stock = _ticketTypeCategory.stock;
        this.startPrice = 0;
        this.stockBuffer = 0;
    }
}
