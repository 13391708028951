<div class="contain_pop_in" (click)="clickOut()">
    <div class="pop_in" (click)="$event.stopPropagation()">
        <div *ngIf="!emailSent" class="pop-in-content">
            <p>{{"Veuillez entrer l'adresse email avec laquelle la commande a été passée" | customTranslation:
                "enterResendEmail"}}</p>

            <form [formGroup]="form">
                <app-a-form-input-field type="email" (InputChange)="setEmail($event)"></app-a-form-input-field>
                <div class="button_container">
                    <div class="button">
                        <app-a-button [isLoading]="loading" [isDisabled]="form.invalid"
                            text="{{'Envoyer' | customTranslation}}" (buttonClicked)="send()"></app-a-button>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="emailSent" class="pop-in-content">
            <p>{{"Si cette adresse email existe vous recevrez votre commande" | customTranslation: 'sentIfExist'}}</p>
            <div class="button_container">
                <div class="button">
                    <app-a-button text="{{'Retour' | customTranslation}}" (buttonClicked)="clickOut()"></app-a-button>
                </div>
            </div>
        </div>
    </div>
</div>