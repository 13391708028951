<p *ngIf="label !== ''">{{label}}</p>
<ul class="m-form-radio-list">
  <li *ngFor="let option of options">
    <app-a-form-checkbox-radio-field [id]="formNumber" [label]="option.label || option"
      [value]="option.value || option.label || option" [name]="label + formNumber"
      [description]="option.description"
      [checked]="selected === (option.value || option.label || option)" type="radio"
      (checkedChange)='selectedRadioChanged($event)'>
    </app-a-form-checkbox-radio-field>
  </li>
</ul>
<p class="description" *ngIf="description">
  {{description}}
</p>