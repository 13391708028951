<div class="m-card-rate" [ngClass]="{'soldout': unavailable, 'isEarlybirds': showCountDown || showRemainingPlace }">
    <div class="left">
        <h3>
            <span>{{rateName}}</span>
            <button *ngIf="rateDescription && rateDescription !== ''" class="webfont"
                [ngClass]="{'webfont-less': showDescription === true, 'webfont-add': showDescription === false}"
                (click)="showDescription = !showDescription"></button>
        </h3>
        <p class="description" *ngIf="rateDescription && rateDescription !== '' && showDescription "
            [innerHTML]="rateDescription">
        </p>

        <div class="earlybirds" *ngIf="showRemainingPlace || showCountDown">
            <app-m-place-left *ngIf="showRemainingPlace" [stock]="limit"></app-m-place-left>
            <app-m-countdown (onExpire)="disableTicket()" *ngIf="showCountDown && endDate" [dueDate]="endDate"></app-m-countdown>
        </div>

    </div>
    <div class="right">
        <strong>
            {{rateAmount ? (rateAmount | currency:currency.isoCode) : 'Gratuit'}}
        </strong>
        <app-a-quantity-selector *ngIf="!unavailable" (quantityUpdate)="updateRateQuantity($event)"
            [quantity]="rateQuantity" [quantityMax]="limit" [limitString]="limitString"></app-a-quantity-selector>
        <p class="soldout-text" *ngIf="unavailable">
            {{"indisponible" | customTranslation: 'unavailable'}}
        </p>
    </div>
</div>