import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-o-soldout',
  templateUrl: './o-soldout.component.html',
  styleUrls: ['./o-soldout.component.scss']
})
export class OSoldoutComponent implements OnInit {
  widgetId: string;
  constructor(
    public route: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(_params => {
      this.widgetId = _params.id;
    });
  }

  goWaitingList(): void {
    this.router.navigate([`${this.widgetId}/registration`]);
  }

}
