import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-o-summary-table',
  templateUrl: './o-summary-table.component.html',
  styleUrls: ['./o-summary-table.component.scss']
})
export class OSummaryTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
