<div class="o-summary-seat-selection">
    <header>
      <div class="list-category-seat">
        <!-- <app-a-category-seat-item categoryColor="#dddddd" categoryName="Pas à vendre"></app-a-category-seat-item> -->
        <app-a-category-seat-item *ngFor="let item of categories" categoryColor="{{ item.seatsIoColor }}" categoryName="{{ item.name }}" categoryPriceStart="{{ item.startPrice }}">
        </app-a-category-seat-item>
      </div>

      <h2>{{"Récapitulatif" | customTranslation: "summary"}}</h2>

      <!-- <app-m-category-seat-summary-item [seatInfos]="['Accès : J ','Rang : Q','Siège : 157' ]" categoryName="Category 2"
        priceType="Tarif Normal" price="65.5" (onShowSeat)="showSeat()" (onDeleteSeat)="deleteSeat()">
      </app-m-category-seat-summary-item>
      <app-m-category-seat-summary-item [seatInfos]="['Accès : J ','Rang : Q','Siège : 20' ]" categoryName="Category 1"
        priceType="Tarif Normal" price="150.5" (onShowSeat)="showSeat()" (onDeleteSeat)="deleteSeat()">
      </app-m-category-seat-summary-item> -->
    </header>

    <ul class="list--summary">
        <li>
            <div class="price-line">
                <p class="label">{{nbTickets}}
                    <ng-container *ngIf="!isRegistration">
                        <ng-container>
                            {{(globalObject.widgetId.forProduct ? 'produit' : 'ticket') | customTranslation}}{{nbTickets > 1 ? 's' : ''}}
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="isRegistration">{{'inscription' | customTranslation}}{{nbTickets > 1 ? 's' : ''}}</ng-container>

                    </p>
                <p class="price">
                    {{ allFree ? "" : (ticketsTotal | currency:currency.isoCode)}}
                </p>
            </div>
            <div class="tickets-options" *ngIf="ticketsOptionsPrice">
                <span>{{"Options" | customTranslation}}</span>
                <span>
                    {{ticketsOptionsPrice | currency:currency.isoCode}}
                </span>
            </div>
        </li>
        <li *ngFor="let addOn of addons">
            <div class="price-line">
                <p class="label">{{ addOn.name }}</p>
                <p class="price">
                    {{ addOn.price*nbTickets | currency:currency.isoCode}}
                </p>
            </div>
        </li>
        <li *ngIf="promoCode">
            <div class="price-line promo-code">
                <p class="label">{{"Code Promo" | customTranslation: 'promoCode'}} : {{ promoCode.code }}</p>
                <p class="price" *ngIf="promoReduct !== null">
                    -{{ promoReduct | currency:currency.isoCode}}
                </p>
            </div>
        </li>
    </ul>

    <footer>
        <div *ngIf="charges" class="price-line service_charges">
            <p class="label">{{"Frais de service" | customTranslation: 'service'}}</p>
            <p class="price">{{ charges | currency:currency.isoCode}}</p>
        </div>
        <p class="total">
            <small>{{"Total" | customTranslation}}</small>
            <strong>{{allFree ? ("Gratuit" | customTranslation: 'free') : (total | currency:currency.isoCode)}}</strong>
        </p>

        <app-a-button [hover]="hoverText" [text]="textButton" [isDisabled]="!nbTickets  || buttonIsDisabled"
            (buttonClicked)='nextStep()' [isLoading]="loading">
        </app-a-button>

        <app-m-coupon-add *ngIf="!allFree && currentPage === 'seat-selection'"></app-m-coupon-add>

        <p *ngIf="!allFree" class="description">
            {{"Les prix affichés sont toutes taxes comprises" | customTranslation: "infoPriceDisplayed"}}.
        </p>
    </footer>

  <!-- <footer>

    <p class="total">
      <small>Total</small>
      <strong>{{ 585.56 |  currency:currency.isoCode}} </strong>
    </p>

    <app-a-button text="Acheter" (buttonClicked)='nextStep()'></app-a-button>
    <app-a-button text="Annuler" type="cancel" (buttonClicked)='cancel()'></app-a-button>

  </footer> -->
</div>
