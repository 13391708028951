import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-m-countdown',
  templateUrl: './m-countdown.component.html',
  styleUrls: ['./m-countdown.component.scss']
})
export class MCountdownComponent implements OnDestroy {
  @Input() dueDate: Date;
  @Output() onExpire = new EventEmitter<any>();

  date: any;
  now: any;
  targetDate: any;
  targetTime: any;
  difference: number = 0;
  timeInterval: any = 0;
  expired: boolean = false;

  @ViewChild('days', { static: true }) days: ElementRef;
  @ViewChild('hours', { static: true }) hours: ElementRef;
  @ViewChild('minutes', { static: true }) minutes: ElementRef;
  @ViewChild('seconds', { static: true }) seconds: ElementRef;

  constructor() { }

  ngAfterViewInit() {
    const dateObject = new Date(this.dueDate)
    this.targetTime = dateObject.getTime()
    this.ticTac()
    this.timeInterval = setInterval(() => {
      this.ticTac()
      if (this.difference < 0) {
        clearInterval(this.timeInterval)
        this.expired = true
        this.days.nativeElement.innerText = '--'
        this.hours.nativeElement.innerText = '--'
        this.minutes.nativeElement.innerText = '--'
        this.seconds.nativeElement.innerText = '--'
        this.onExpire.emit();
      }
    }, 1000);
  }

  ticTac() {
    this.now = new Date().getTime();
    this.difference = this.targetTime - this.now;
    this.days.nativeElement.innerText = this.convertText(Math.floor(this.difference / (1000 * 60 * 60 * 24)));
    this.hours.nativeElement.innerText = this.convertText(Math.floor((this.difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
    this.minutes.nativeElement.innerText = this.convertText(Math.floor((this.difference % (1000 * 60 * 60)) / (1000 * 60)))
    this.seconds.nativeElement.innerText = this.convertText(Math.floor((this.difference % (1000 * 60)) / 1000))
  }

  convertText(number: number): string {
    const string = String(number)
    return string.padStart(2, '0')
  }

  ngOnDestroy(): void {
    clearInterval(this.timeInterval)
  }

}
