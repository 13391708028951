<header id="o-header">
    <div class="left">
        <app-a-logo [registration]="registration"></app-a-logo>
    </div>

    <div class="right" *ngIf="widget">
        <ng-container *ngIf="!countdownLaunch && !isNft">
            <p *ngIf="registration" class="already_order">{{"Déjà inscrits ? Récupérez vos billets en" | customTranslation:
                'alreadyRegistered'}} <a (click)="resend = true">{{"cliquant ici" | customTranslation}}</a></p>
            <p *ngIf="!registration" class="already_order">{{"Déjà commandé ? Récupérez vos billets en" | customTranslation:
                'alreadyOrdered'}} <a (click)="resend = true">{{"cliquant ici" | customTranslation}}</a></p>
            <app-a-box-close-countdown [registration]="registration" [remainDays]="remainDays"></app-a-box-close-countdown>
        </ng-container>
        <p *ngIf="countdownLaunch" class="countdown">
            <i class="webfont webfont-timer"></i>
            <span>
                {{countdownMinutes}} MIN {{countdownSeconds > 9 ? countdownSeconds : '0' + countdownSeconds}} SEC
            </span>
        </p>
    </div>
</header>

<app-o-resend *ngIf="resend" [eventId]="widget.eventId" (close)="resend = false"></app-o-resend>