<div class="a-quantity-selector">
  <ng-container *ngIf="quantityMax !== undefined">
    <button class="minus webfont webfont-less" (click)="decrement()" [ngClass]="{disable: isMinusDisabled() }"></button>
    <strong>{{quantity}}</strong>

    <button class="plus webfont webfont-add" (click)="increment()" [ngClass]="{disable: isPlusDisabled() }"
      (mouseenter)="displayTooltip()" (mouseleave)="hideTooltip()"></button>
    <div *ngIf="plusDisabled && tooltipDisplayed" class="disabled-tooltip">
      <div>
        <span>{{'Plus de stock disponible pour' | customTranslation: 'noMoreStockFor'}} {{limitString}}</span>
      </div>
    </div>
  </ng-container>

</div>