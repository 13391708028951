import Requester from '../requester';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import PromoCode from 'src/app/models/promo-code.model';
import { QueryParams } from 'src/app/etc/queryParams';
import { Observable } from 'rxjs';


@Injectable()
export class PromoCodeService extends Requester {

	constructor(
		protected http: HttpClient,
		protected queryParam: QueryParams
	) {
		super('ticket-types', PromoCode, http, queryParam);
	}

	public getList(_body = null): Observable<any> {
		return super.getList(_body);
	}

	public getById(_id, _body = null): Observable<any> {
		return super.getById(_id, _body);
	}

	public checkPromoCode(eventId: string, code: string){
		const url = `${environment.apiUrl}/v1/events/promo-codes/${eventId}/${code}`;
		return this.http.get(url);
	}
}
