import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-a-form-file-field',
  templateUrl: './a-form-file-field.component.html',
  styleUrls: ['./a-form-file-field.component.scss']
})
export class AFormFileFieldComponent implements OnInit {


  @Input() label: string;
  @Input() description: string;
  @Input() id: string;
  @Input() required: boolean;
  @Input() validate: string;
  @Output() FileChange = new EventEmitter<any>();
  @Output() FileDelete = new EventEmitter<any>();
  @Input() constraintsInfos: string;

  @Input() file: any;


  constructor() { }

  ngOnInit(): void {
  }

  fileChange(event): void {
    this.file = event.target.files[0]
    this.FileChange.emit(this.file);
  }

  deleteFile(): void {
    this.file = null;
    this.FileDelete.emit();
  }

  displayExt(type: string): string {
    const arrayType = type.split('/');
    return arrayType[1];
  }

}
