import checkField from './check-field';
import Event from './event.model';
import Tax from './tax.model';
import { TotalPrice, Payment } from './type.definition';
import Widget from './widget.model';

export default class Order {
    _id: string;
    orderNumber: string;
    name: string;
    token: string;
    status: string;
    paymentMethod: string;
    totalPrice: TotalPrice;
    payment: Payment;
    tax: Tax;
    eventId: Event;
    eventWidgetId: Widget;
    isNft: boolean;

    constructor(_order?: any) {
        if (checkField(_order?._id)) {
            this._id = _order._id;
        }
        if (checkField(_order?.orderNumber)) {
            this.orderNumber = _order.orderNumber;
        }
        if (checkField(_order?.name)) {
            this.name = _order.name;
        }
        if (checkField(_order?.status)) {
            this.status = _order.status;
        }
        if (checkField(_order?.paymentMethod)) {
            this.paymentMethod = _order.paymentMethod;
        }
        if (checkField(_order?.totalPrice)) {
            this.totalPrice = _order.totalPrice;
        }
        if (checkField(_order?.payment)) {
            this.payment = _order.payment;
        }
        if (checkField(_order?.tax)) {
            this.tax = _order.tax;
        }
        if (checkField(_order?.eventId)) {
            this.eventId = _order.eventId;
        }
        if (checkField(_order?.eventWidgetId)) {
            this.eventWidgetId = _order.eventWidgetId;
        }
    }
}


export type TicketOrder = {
    ticketTypeId: string,
    firstName: string,
    lastName: string,
    email?: string,
    form?: {
      id: string;
      fields: {
        formFieldId: string,
        value: string | number
      }[]
    }[],
    addons?: string[],
    isProduct?: boolean;
  }