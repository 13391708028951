import { Component, ChangeDetectorRef, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { translateString } from 'src/app/etc/custom-translation/custom-translation.pipe';

// Providers
import { OrderService, TrackingEvent, TrackingService } from '../../../providers';
import { Store } from '@ngxs/store';
import { GlobalState } from '../../../store/states/global.state';
import { Cart } from '../../../models/type.definition';

@Component({
  selector: 'app-o-payment-box',
  templateUrl: './o-payment-box.component.html',
  styleUrls: ['./o-payment-box.component.scss']
})

export class OPaymentBoxComponent implements AfterViewInit, OnDestroy {

  @Output() tokenValid: EventEmitter<any> = new EventEmitter();
  @Input() order: any;

  error: string;
  cardNumberElement: any;
  cardCvcElement: any;
  cardExpiryElement: any;
  cardNameElement: any;
  widgetId: string;
  subscriptions = new Subscription();
  loading: boolean = false;
  card: any;
  paymentRequest: any;
  displayMessage: string = null;
  displayApplePay: boolean = false;
  free: string;

  constructor(
    private orderService: OrderService,
    private cd: ChangeDetectorRef,
    private router: Router,
    public route: ActivatedRoute,
    public trackingService: TrackingService,
    public store: Store
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.route.params.subscribe((_params: Params) => {
      this.widgetId = _params.id;
    }));
    if (this.order.amount > 0) {
      this.initForm();
      this.initApplePay();
    }
  }

  initForm() {
    var style = {
      base: {
        color: "#32325d",
      }
    };
    stripe._locale = currentLang
    const elements = stripe.elements()
    this.card = elements.create("card", { style: style });
    this.card.mount("#card-element");

    this.card.on('change', function (event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

  }

  // Init Apple Pay
  async initApplePay() {
    const amount = Number(this.order.amount) * 100;
    this.paymentRequest = stripe.paymentRequest({
			country: currentLang.toUpperCase(),
			currency: 'eur',
			total: {
			  label: 'Ma commande',
			  amount:  amount,
			},
			requestPayerName: false,
			requestPayerEmail: false,
		})

    const elements = stripe.elements();
		const prButton = elements.create('paymentRequestButton', {
			paymentRequest: this.paymentRequest
		});

    const result = await this.paymentRequest.canMakePayment();

    if (result) {
			prButton.mount('#payment-request-button');
      this.displayApplePay = true
		} else {
			document.getElementById('payment-request-button').style.display = 'none';
		}

    this.paymentRequest.on('paymentmethod', async (ev) => {
      if (this.order) {
        this.loading = true;
        this.orderService.proceedPayment(this.order.token).subscribe(async (_result) => {
          if (_result && _result['clientSecret']) {
            const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
              _result['clientSecret'],
              {payment_method: ev.paymentMethod.id},
              {handleActions: false}
            );
            if (confirmError) {
              this.displayMessage = translateString("Le paiement n'a pas pu être effectué. Votre établissement bancaire a refusé le paiement.", "paymentError")
              ev.complete('fail');
            }
            else {
              ev.complete('success');
              if (paymentIntent.status === "requires_action") {
                const {error} = await stripe.confirmCardPayment(_result['clientSecret']);
                if (error) {
                  this.displayMessage = translateString("Le paiement n'a pas pu être effectué. Votre établissement bancaire a refusé le paiement.", "paymentError")
                }
                else {
                  // SUCCESS.
                  if (this.order.isNft) {
                    this.router.navigate([`${this.widgetId}/confirmation/nft/${this.order.token}`]);
                  } else {
                    this.router.navigate([`${this.widgetId}/confirmation/ticketing/${this.order.token}`]);
                  }
                }
              }
              else {
                // SUCCESS.
                if (this.order.isNft) {
                  this.router.navigate([`${this.widgetId}/confirmation/nft/${this.order.token}`]);
                } else {
                  this.router.navigate([`${this.widgetId}/confirmation/ticketing/${this.order.token}`]);
                }
              }
            }
          }
          else if (_result && _result['free']) {
            this.router.navigate([`${this.widgetId}/confirmation/ticketing/${this.order.token}`]);
          }
          this.loading = false;
        })
      }
    })

  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  validatePayment(): void {
    if (!this.cardNameElement && this.order.amount > 0) {
      this.error = translateString("Informations invalides", "infoInvalids")
    }
    else {
      this.loading = true;
      const cart: Cart = this.store.selectSnapshot(state => GlobalState.get(state, "cart"));
      if (this.order) {
        this.orderService.proceedPayment(this.order.token).subscribe(_result => {
          if (_result && _result['clientSecret']) {
            stripe.confirmCardPayment(_result['clientSecret'], {
              payment_method: {
                card: this.card,
                billing_details: {
                  name: this.cardNameElement
                }
              }
            }).then(_stripeResult => {
              this.loading = false;
              if (_stripeResult.error) {
                // Show error to your customer (e.g., insufficient funds)
                // :id/confirmation/:type ConfirmationPage
                this.displayMessage = translateString("Le paiement n'a pas pu être effectué. Votre établissement bancaire a refusé le paiement.", "paymentError")
              } else {
                // The payment has been processed!
                if (_stripeResult.paymentIntent.status === 'succeeded') {
                  // Show a success message to your customer
                  // There's a risk of the customer closing the window before callback
                  // execution. Set up a webhook or plugin to listen for the
                  // payment_intent.succeeded event that handles any business critical
                  // post-payment actions.
                  if (this.order.isNft) {
                    this.router.navigate([`${this.widgetId}/confirmation/nft/${this.order.token}`]);
                  } else {
                    this.router.navigate([`${this.widgetId}/confirmation/ticketing/${this.order.token}`]);
                  }
                  this.trackingService.sendEvent(TrackingEvent.PURCHASE, { cart });
                } else {
                  this.displayMessage = translateString("Le paiement n'a pas pu être effectué. Votre établissement bancaire a refusé le paiement.", "paymentError")
                }
              }
            });
          } else if (_result && _result['free']) {
            this.router.navigate([`${this.widgetId}/confirmation/ticketing/${this.order.token}`]);
            this.trackingService.sendEvent(TrackingEvent.PURCHASE, { cart });
          }
        });
      }
    }
  }
}
