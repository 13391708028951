<div class="a-form-input-field">
  <label [for]="id">{{label}} <span *ngIf="required">*</span></label>
  <div class="input-container" [class]="validate">
    <!-- default INPUT -->
    <div *ngIf="type === 'url'" class="http-prefill"><span>http(s)://</span></div>
    <input [readonly]="readonly" [id]="id" *ngIf="!creditCardType" [type]="type" [(ngModel)]="value" (ngModelChange)="onChange()"
      [autocomplete]="autocomplete" [placeholder]="placeholder" />
    <!-- credit card expiration date INPUT -->
    <input *ngIf="creditCardType == 'ccExp'" [id]="id" [readonly]="readonly" [type]="type" [(ngModel)]="value" (ngModelChange)="onChange()"
      [autocomplete]="autocomplete" ccExp />
    <!-- credit card number INPUT -->
    <input *ngIf="creditCardType == 'ccNumber'" [id]="id" [readonly]="readonly" [type]="type" [(ngModel)]="value" (ngModelChange)="onChange()"
      [autocomplete]="autocomplete" ccNumber />
    <!-- credit card cvc INPUT -->
    <input *ngIf="creditCardType == 'ccCVC'" [id]="id" [readonly]="readonly" [type]="type" [(ngModel)]="value" (ngModelChange)="onChange()"
      [autocomplete]="autocomplete" ccCVC />

    <i *ngIf="validate === 'success'" class="webfont webfont-check"></i>
    <i *ngIf="validate === 'error'" class="webfont webfont-close"></i>
    <div class="input-error">
      <p *ngIf="validate === 'error'">
        {{"Veuillez entrer une donnée valide" | customTranslation: "invalidInput"}}
      </p>
    </div>
  </div>
  <p class="description" *ngIf="description">
    {{description}}
  </p>

  <p class="constraints" *ngIf="constraintsInfos">
    {{constraintsInfos}}
  </p>
</div>
