import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrencyService, TimeLineService } from 'src/app/providers';
import { Subscription, Observable } from 'rxjs';
import { GlobalStateModel, GlobalState } from 'src/app/store/states/global.state';
import { Select } from '@ngxs/store';
import { AppPrice, Cart, GlobalStateObject } from 'src/app/models/type.definition';
import PromoCode from 'src/app/models/promo-code.model';
import Currency from 'src/app/models/currency.model';
import { translateString } from 'src/app/etc/custom-translation/custom-translation.pipe';
import Event from 'src/app/models/event.model';
import TicketType from 'src/app/models/ticket-type.model';


@Component({
  selector: 'app-summary',
  templateUrl: './summary.html',
  styleUrls: ['./summary.scss']
})
export class SummaryPage implements OnInit, OnDestroy {

  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;
  title: string;
  widgetId: string;
  nbTickets: number;
  tickets: any[];
  addons: any;
  promoCode: PromoCode;
  subscriptions = new Subscription;
  totalToPay: number;
  public event: Event;
  currency: Currency;
  public charges: number;
  globalObject: GlobalStateObject;
  order: any;
  nonFreeTickets: number = 0;
  insurancePrice: AppPrice | null

  constructor(
    private timeLineService: TimeLineService,
    public router: Router,
    public route: ActivatedRoute,
    private currencyService: CurrencyService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.title = translateString("Paiement", "timelinePayment")
    this.subscriptions.add(this.currencyService.currency$.subscribe(res => {
      this.currency = res;
    }));
    this.timeLineService.updateTimeLine('summary');
    this.subscriptions.add(this.globalState$.subscribe((state: GlobalStateModel) => {
      this.globalObject = state.globalObject;

      const cart = this.globalObject.cart;
      this.event = this.globalObject.widget.eventId;
      this.promoCode = cart.promoCode;
      this.setTickets(cart);
      this.addons = cart.addons;
      this.setTotalToPay();
      this.prepareDataToSend();
    }));
  }

  hasAddOn(_ticket: any): boolean {
    for (const addOn of _ticket.addons) {
      if (addOn.quantity) {
        return true;
      }
    }
    return false;
  }

  setTickets(_cart: Cart): void {
    this.tickets = [];
    _cart.tickets.forEach(_ticket => {
      const ticketIndex = this.getIndexOfTicket(_ticket._id);
      if (ticketIndex === -1) {
        _ticket.quantity = 1;
        this.tickets.push(_ticket);
        this.tickets[this.tickets.length - 1].addons = _ticket.addons.map(_ => ({ ..._, quantity: _.isChecked ? 1 : 0 }));
      }
      else {
        this.tickets[ticketIndex].quantity++;
        for (let i = 0; i < _ticket.addons.length; i++) {
          if (_ticket.addons[i].isChecked) {
            this.tickets[ticketIndex].addons[i].quantity++;
          }
        }
      }
      if (_ticket.price || _ticket.addons.filter(addon => addon.isChecked && addon.price).length) {
        this.nonFreeTickets++;
      }
    });
    this.nbTickets = 0;
    this.tickets.forEach(_ => this.nbTickets += _.quantity)
  }

  getIndexOfTicket(id: string): number {
    for (let i = 0; i < this.tickets.length; i++) {
      if (this.tickets[i].id === id) {
        return i;
      }
    }
    return -1;
  }

  setTotalToPay() {
    this.totalToPay = 0;
    this.tickets.forEach(_ticket => {
      if(_ticket.price){
        this.totalToPay += _ticket.price * _ticket.quantity;
      }
      _ticket.addons.forEach(_addOn => {
        this.totalToPay += _addOn.price * _addOn.quantity;
      });
    });
    this.tickets.sort((a: any, b: any) => this.sortTickets(a, b))
    this.addons.forEach(_addOn => {
      this.totalToPay += _addOn.price * this.nbTickets;
    })



    if (this.promoCode) {
      this.totalToPay -= this.promoCode.totalReduction;
    }

    if (this.totalToPay < 0) {
      this.totalToPay = 0;
    }
    this.charges = 0;
    if (this.totalToPay) {
      if (this.event.commissionIncreased) {
        this.charges += this.event.commission.percentage * this.totalToPay  / 100
        this.charges += (this.event.commission.flat / 100) * this.nonFreeTickets;
      }
      this.totalToPay += this.charges
    }

    if (this.globalObject.insurance) {
      this.insurancePrice = this.globalObject.insurance;
      this.totalToPay += this.insurancePrice.floated;
    }

  }

  sortTickets(a: any, b: any): number {
    const catA = a.categoryName;
    const catB = b.categoryName;
    if (!catA) {
      return 1
    }
    if (!catB) {
      return -1
    }
    return catA > catB ? 1 : (catA < catB ? -1 : 0);
  }

  getTicketName(ticket: any): string {
    return (ticket.categoryName ? ticket.categoryName + ' - ' : '') + ticket.name;
  }

  validateOrder(token: any) {
    this.prepareDataToSend();
    this.router.navigate([`${this.widgetId}/confirmation/order`]);
  }

  prepareDataToSend() {
    this.globalObject.order = {
      ...this.globalObject.order,
      amount: this.totalToPay,
      tickets: [],
      addons: this.globalObject.generalAddOns.filter(_ => _.isChecked).map(_ => ({ id: _._id })),
      sessionId: this.globalObject.session?._id || null,
      form: {
        fields: this.globalObject.billingForm.fields.map(_ => ({ id: _._id, value: _.value || null }))
      }
    }
    this.globalObject.order.tickets = this.globalObject.cart.tickets.map(ticket => {
      return {
        id: ticket._id,
        form: ticket.form.map(form => ({
          id: form._id,
          fields: form.fields.map(_ => ({ id: _._id, value: _.value || null }))
        })),
        ticketTypeCategory: ticket.ticketTypeCategoryId,
        addons: ticket.addons.filter(_ => _.isChecked).map(_ => ({ id: _._id }))
      }
    });
  }
}
