<p *ngIf="label !== ''">{{label}}</p>
<ul class="m-form-checkbox-list">
  <li *ngFor="let option of options">
    <app-a-form-checkbox-radio-field [id]="formNumber" [label]="option.label || option" [value]="option.value || option" [name]="label + formNumber"
      [checked]="selected === (option.value || option.label || option)" type="checkbox" (checkedChange)='selectedCheckboxChanged($event)'>
    </app-a-form-checkbox-radio-field>
  </li>
</ul>
<p class="description" *ngIf="description">
  {{description}}
</p>