import { Component, OnInit, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { GlobalState, GlobalStateModel } from 'src/app/store/states/global.state';

import { RequestResponseList, SetCartModel, Cart, GlobalStateObject } from 'src/app/models/type.definition';
import { SetCart, SetKeysGlobal } from 'src/app/store/actions/global.actions';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';

// Providers
import {
	CurrencyService,
	EventService,
	TicketTypeCategoryService,
	TicketTypeService,
	TimeLineService
} from 'src/app/providers';

import TicketTypeCategory from 'src/app/models/ticket-type-category.model';
import TicketType from 'src/app/models/ticket-type.model';
import Event from 'src/app/models/event.model';
import Session from 'src/app/models/session.model';
import Currency from 'src/app/models/currency.model';
import { map } from 'rxjs/operators';
import Widget from 'src/app/models/widget.model';
import { Router } from '@angular/router';

@Component({
	selector: 'app-tickets-list',
	templateUrl: './tickets-list.html',
	styleUrls: ['./tickets-list.scss']
})
export class TicketsListPage implements OnInit, OnDestroy {

	@Select(GlobalState) globalState$: Observable<GlobalStateModel>;
	globalObject: GlobalStateObject;
	categories: TicketTypeCategory[];
	noCategory: TicketType[] = [];
	subscriptions = new Subscription();
	stockChange$ = new BehaviorSubject<any>(null);
	ticketsAvailable: boolean;
	event: Event;
	currency: Currency
	session: Session;
	loaded: boolean;
	remainDays: number;
	bufferStock = null
	bufferStockTickets = null
	bufferStockCategories = null
	public isRegistration: boolean;
	public allFree: boolean;
	public widget: Widget;

	constructor(
		private timeLineService: TimeLineService,
		private ticketTypeCategoryService: TicketTypeCategoryService,
		private ticketTypeService: TicketTypeService,
		private eventService: EventService,
		private store: Store,
		private currencyService: CurrencyService,
		private router: Router
	) { }

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	async ngOnInit(): Promise<void> {
		this.currencyService.currency$.subscribe(res => {
			this.currency = res;
		});
		this.timeLineService.updateTimeLine('ticketsList');
		this.subscriptions.add(this.globalState$.subscribe((state: GlobalStateModel) => {
			this.globalObject = state.globalObject;
		}));
		this.widget = this.store.selectSnapshot(state => GlobalState.get(state, 'widget'));
		this.remainDays = this.calcRemainDays(this.widget.eventId);
		this.session = this.store.selectSnapshot(state => GlobalState.get(state, 'session'));
		this.store.selectSnapshot(state => GlobalState.get(state, 'session'));
		const eventId = this.widget.eventId
		this.event = this.widget.eventId;


		const bufferStock: any = await this.eventService.bufferStock(eventId._id).toPromise()
		if (bufferStock) {
			this.bufferStockCategories = bufferStock.categories
			this.bufferStockTickets = bufferStock.tickets
		}
		const categoriesResult: RequestResponseList = await this.getTicketTypeCategories(eventId._id).toPromise();
		this.categories = categoriesResult?.data || [];
		const ticketsResult: TicketType[] = await this.getTicketType(eventId._id, this.widget).toPromise();

		if (ticketsResult?.length) {
			this.formatTickets(ticketsResult);
		}
		else {
			this.store.dispatch(new SetKeysGlobal({ value: null, key: 'widget' }));
			this.timeLineService.currentStep$.next();
			this.router.navigate([`${this.widget._id}/closed`]);
		}
		this.formatEvents();
		this.formatCategories();
		// const addons = AddOn.createAddOn();
		// this.separateAddOns(addons);
		const cart = this.store.selectSnapshot(state => GlobalState.get(state, 'cart'));
		this.checkSelectedTickets(cart)
		const availableTickets = this.sortAndGetAvailableTickets();
		this.isRegistration = this.store.selectSnapshot(state => GlobalState.get(state, 'isRegistration'));
		this.allFree = this.store.selectSnapshot(state => GlobalState.get(state, 'allFree'));
		this.store.dispatch(new SetKeysGlobal({ value: availableTickets, key: 'tickets' }));
		this.ticketsAvailable = this.checkIfAvailable();
		if (!this.ticketsAvailable) {
			this.timeLineService.deletePayment();
			this.timeLineService.deleteCountdown();
		}
		this.loaded = true;

	}

	getTicketTypeCategories(_eventId: string): Observable<RequestResponseList> {
		// Body de la requête getList du TicketTypeCategoryService
		const body = {
			perPage: 500,
			filter: { eventId: _eventId, isProduct: this.widget.forProduct },
			select: ['id', 'name', 'isOpened', 'stocks'],
		};
		// Requête pour récupérer les catégories de types tickets liés à l'évènement
		return this.ticketTypeCategoryService.getList(body);
	}

	getTicketType(_eventId: string, widget: Widget): Observable<TicketType[]> {
		// Body de la requête getList du TicketTypeService
		const body = {
			perPage: 500,
			filter: {
				eventId: _eventId,
				isProduct: this.widget.forProduct
			},
			customFilter: {
				canBeSold: true,
			}
		};

		// Requête pour récupérer les types de tickets
		return this.ticketTypeService.getList(body)
			.pipe(
				map((tickets: { data: TicketType[] }) => {
					const widgetTickets = widget.ticketTypes;
					//const sessionTickets = this.session?.ticketTypes || {};
					return (tickets?.data || []).filter(ticket => {
						if (widgetTickets.restrict && !widgetTickets.ids.includes(ticket._id)) {
							return false
						}
						else if (this.session && this.session.isTicketTypeRestricted && !this.session.ticketTypeIds.map(_ => _._id).includes(ticket._id)) {
							return false
						}
						else {
							return true
						}
					})
			}))
	}

	formatTickets(_tickets: TicketType[]): void {

		// On ajoute le stock tampon en cours sur le ticket type.
		if (this.bufferStockTickets) {
			_tickets = _tickets.map((elTicket: TicketType) => {
				const stockBuffer = this.bufferStockTickets.find(el => el.id === elTicket._id)
				if (stockBuffer) {
					elTicket.stockBuffer = stockBuffer.stock_buffer
				}
				return elTicket
			})
		}

		for (let i = 0; i < _tickets.length; i++) {
			if (!_tickets[i].ticketTypeCategoryId) {
				if (_tickets[i].isVisible) {
					this.noCategory.push(_tickets[i]);
				}
				_tickets.splice(i--, 1)[0];
			}
			else {
				for (const category of this.categories) {
					if (category._id === _tickets[i].ticketTypeCategoryId._id) {
						if (_tickets[i].isVisible) {
							if (typeof category.tickets === 'undefined') {
								category.tickets = [];
							}
							_tickets[i].categoryName = category.name;
							category.tickets.push(_tickets[i]);
						}
						_tickets.splice(i--, 1)[0];
						break;
					}
				}
			}
		}
	}

	formatCategories(): void {
		this.categories = this.categories.filter(category => category.tickets?.length);
		this.categories.forEach(category => {
			const bufferEl = this.bufferStockCategories.find(el => el.id == category._id)
			if (bufferEl) {
				category.stockBuffer = bufferEl.stock_buffer
			}
			if (category.quota && category.stock === 0) {
				category.tickets.forEach(ticket => {
					ticket.unavailable = true;
				})
			}
		});
	}

	formatEvents(): void {
		if (this.event.ticketing.quota && this.event.ticketing.stock == 0) {
			this.categories.forEach(category => {
				category.stock = 0;
			})
			this.noCategory.forEach(ticket => {
				ticket.unavailable = true;
			})
		}
	}

	checkIfAvailable() {
		if (!this.event.ticketing.quota || this.event.ticketing?.stock) {
			if (!this.session || (!this.session.quota || this.session.stock)) {
				for (const category of this.categories) {
					if (category.stock) {
						if (category.tickets) {
							for (const ticket of category.tickets) {
								if (!ticket.quota|| ticket.stock) {
									return true;
								}
							}
						}
					}
				}
				for (const ticket of this.noCategory) {
					if (!ticket.quota || ticket.stock) {
						return true;
					}
				}
			}
		}
		return false;
	}

	getCategoryLowestPrice(category: TicketTypeCategory): number {
		if (category.tickets) {
			return Math.min(...category.tickets.map(ticket => ticket.price));
		}
	}

	// Met à jour la quantité de tickets sélectionnés
	updateQuantity(_ticket: TicketType, event: any): void {
		_ticket.quantity = event.quantity;
		const updateOption: SetCartModel = {
			quantity: _ticket.quantity,
			ticket: _ticket,
		};
		this.event.ticketing.stock += event.sum;
		if (this.session) {
			this.session.stock += event.sum;
		}
		let category: TicketTypeCategory;
		if (_ticket.ticketTypeCategoryId) {
			category = this.categories.find(_ => _._id === _ticket.ticketTypeCategoryId._id);
			category.stock += event.sum
		}
		this.stockChange$.next({
			categoryId: category?._id,
			categoryStock: category?.stock,
			eventStock: this.event.ticketing.stock,
			sessionStock: this.session?.stock
		});
		this.store.dispatch(new SetCart(updateOption));
	}

	checkSelectedTickets(_cart?: Cart) {
		_cart?.tickets?.forEach(_ticket => {
			if (_ticket.ticketTypeCategoryId) {
				const categories = this.categories.filter(_ => _ticket.ticketTypeCategoryId._id === _._id)[0];
				categories.tickets = categories.tickets.map(_ => _._id === _ticket._id ? _ticket : _);
			}
			else {
				this.noCategory = this.noCategory.map(_ => {
					return _ticket._id === _._id ? _ticket : _
				})
			}
		})
	}

	isCategoryExpand(_category: TicketTypeCategory): number {
		return _category?.tickets?.map(_ => _.quantity).reduce((a: any, c: any) => a + c)
	}

	sortAndGetAvailableTickets(): TicketType[] {
		let tickets: TicketType[] = [];
		this.categories?.forEach(_cat => {
			tickets = this.getAvailableTickets(tickets, _cat.tickets, _cat.name)
		})
		tickets = this.getAvailableTickets(tickets, this.noCategory)

		return tickets
	}

	getAvailableTickets(_ticketsArray: TicketType[], _tickets?: TicketType[], _categoryName: string = null): TicketType[] {
		_tickets?.forEach(_ => _ticketsArray.push(new TicketType({ ..._, categoryName: _categoryName })));
		return _ticketsArray
	}

	// separateAddOns(addons: AddOn[]) {
	// 	const allTicketsAddOns = [];
	// 	addons.forEach(_ => {
	// 		_.price = _.price / 100;
	// 		if (_.ticketSelectionWorkflow === 'allTickets') {
	// 			allTicketsAddOns.push(_);
	// 		}
	// 		else {
	// 			this.noCategory.forEach(_ticket => _ticket.addons.push(_));
	// 			if (this.categories.length > 0) {
	// 				this.categories.forEach(_cat => {
	// 					if (_cat.tickets && _cat.tickets.length > 0) {
	// 						_cat.tickets.forEach(_ticket => _ticket.addons.push(_))
	// 					}
	// 				}
	// 				);
	// 			}
	// 		}
	// 	})
	// 	this.store.dispatch(new SetKeysGlobal({ value: allTicketsAddOns, key: 'generalAddOns' }));
	// }

	calcRemainDays(event: Event): number {
		const endDate = event.ticketing.date.endDate
		if (endDate) {
			return this.getDaysBetweenDates(new Date(endDate), new Date())
		}
		else {
			return this.getDaysBetweenDates(new Date(event.dates.startDate), new Date());
		}
	}
	getDaysBetweenDates(date1: Date, date2: Date): number {
		return Math.floor((date1.getTime() - date2.getTime()) / 86400000);
	}
}