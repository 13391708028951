<p class="a-box-close-countdown">
    <ng-container *ngIf="remainDays > 0">
        <ng-container *ngIf="!registration">{{"La billetterie ferme dans" | customTranslation: "ticketingCloseIn"}}</ng-container>
        <ng-container *ngIf="registration">{{"Les inscriptions ferment dans" | customTranslation: "registrationCloseIn"}}</ng-container>

        <i class="webfont webfont-time"></i>
        <strong>{{remainDays}} {{"jour" | customTranslation: "day"}}{{remainDays > 1 ? 's' : ''}}</strong>
    </ng-container>
    <ng-container *ngIf="remainDays && !remainDays || remainDays === 0">
        <ng-container *ngIf="!registration">{{"La billetterie ferme" | customTranslation: "ticketingClose"}}</ng-container>
        <ng-container *ngIf="registration">{{"Les inscriptions ferment" | customTranslation: "registrationClose"}}</ng-container>
        <strong>&nbsp;{{ "aujourd'hui" | customTranslation}}</strong>
    </ng-container>
    <ng-container *ngIf="remainDays < 0">
        <ng-container *ngIf="!registration">{{"La billetterie est fermée" | customTranslation:"ticketingClosed"}}</ng-container>
        <ng-container *ngIf="registration">{{"Les inscriptions sont fermées" | customTranslation:"registrationClosed"}}</ng-container>
    </ng-container>

</p>
