import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { CalendarService } from 'src/app/services/calendar/calendar.service';
import * as moment from 'moment';
import Session from 'src/app/models/session.model';

@Component({
  selector: 'app-o-calendar-month',
  templateUrl: './o-calendar-month.component.html',
  styleUrls: ['./o-calendar-month.component.scss']
})
export class OCalendarMonthComponent implements OnInit {
  @Output() dateSelection: EventEmitter<Date> = new EventEmitter();
  @Input() availableSessions: Session[];
  selectedDay: { dayDate: Date, selectable: boolean, selected: boolean };
  selectableDays: Date[];
  firstAvailableDate: moment.Moment;
  days: { dayDate: Date, selectable: boolean, selected: boolean }[];
  constructor(
  ) { }

  ngOnInit(): void {
    moment.locale(currentLang);
    this.firstAvailableDate = moment(this.availableSessions[0]?.date);
    this.days = this.setDays();
  }

  get firstDayOfMonth(): moment.Moment {
    return moment(this.firstAvailableDate).startOf('month');
  }

  get lastDayOfMonth(): moment.Moment {
    return moment(this.firstAvailableDate).endOf('month');
  }

  get startDayOfView(): moment.Moment {
    const firstDayOfMonthWeekDayNum = this.firstDayOfMonth.weekday();
    if (firstDayOfMonthWeekDayNum === 0) {
      return this.firstDayOfMonth;
    }
    else {
      return this.firstDayOfMonth.add(-firstDayOfMonthWeekDayNum, 'days');
    }
  }

  get endDayOfView(): moment.Moment {
    const lastDayOfMonthWeekDayNum = this.lastDayOfMonth.weekday();
    if (lastDayOfMonthWeekDayNum === 6) {
      return this.lastDayOfMonth;
    }
    else {
      return this.lastDayOfMonth.add((6 - lastDayOfMonthWeekDayNum), 'days');
    }
  }

  get currentMonth(): string {
    return moment(this.firstAvailableDate).format('MMMM YYYY');
  }

  setDays(): any {
    this.selectableDays = [];
    const days = [];
    let day = this.startDayOfView;
    while (day <= this.endDayOfView) {
      let dayObject: any = {};
      dayObject.dayDate = day.toDate();
      if (this.setSelectable(dayObject.dayDate)) {
        dayObject.selectable = true;
        if(this.checkIfSelected(dayObject)){
          dayObject = this.selectedDay;
        }
        else {
          dayObject.selected = false;
        }
        this.selectableDays.push(dayObject.dayDate);
      }
      days.push(dayObject);
      day = day.clone().add(1, 'd');
    }
    return days;
  }

  isToday(date): boolean {
    return true ? moment().isSame(date, 'day') : false;
  }

  isOutsideMonth(date): boolean {
    if (moment(date).isBetween(this.firstDayOfMonth, this.lastDayOfMonth, null, '[)')) {
      return false;
    } else {
      return true;
    }
  }

  displayDayNum(date): string {
    return moment(date).format('DD');
  }

  previousMonth(): void {
    this.firstAvailableDate = moment(this.firstAvailableDate).add(-1, 'month');
    this.days = this.setDays();
  }
  nextMonth(): void {
    this.firstAvailableDate = moment(this.firstAvailableDate).add(1, 'month');
    this.days = this.setDays();
  }

  checkIfSelectable(day: Date): boolean {
    for(const selectableDay of this.selectableDays){
      const check = this.compareDate(day, selectableDay);
      if(check){
        return check;
      }
    }
    return false;
  }

  checkIfSelected(day: any): boolean {
    return this.compareDate(day.dayDate, this.selectedDay?.dayDate);
  }

  compareDate(date1: Date, date2?: Date){
      const dayTime = date1.getTime();
      const selectedTime = date2?.getTime();
      return dayTime === selectedTime;
  }

  setSelectable(day): boolean {
    const date = new Date(day)
    const nextDay = new Date(day);
    nextDay.setDate(date.getDate() + 1)
    const concernedSessions = this.availableSessions.filter(session => {
      const sessionTime = session.date.getTime();
      return date.getTime() <= sessionTime && sessionTime < nextDay.getTime()
    })
    if (concernedSessions.length > 0) {
      return true;
    }
  }

  clickOnDay(day:{dayDate: Date, selectable: boolean, selected: boolean}) {
    if (day.selectable) {
      day.selected = !day.selected
      if(day.selected){
        if(this.selectedDay){
          this.selectedDay.selected = false;
        }
        this.selectedDay = day;
      }
      else {
        this.selectedDay = null;
      }
      this.dateSelection.emit(this.selectedDay?.dayDate);
    }
  }

}


