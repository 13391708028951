import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import Requester from '../requester';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from 'src/app/etc/queryParams';
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthService extends Requester {
  protected url = "auth";
  public isAdmin: boolean;
  public readonly isAuth$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  /** `adminSimpleToken` will always be a token without organisationId. 
   * It will be used to reinit the token when no event is selected  */
  public adminSimpleToken: string;

  /*
  public checkPromoCode(eventId: string, code: string){
	const url = `${environment.apiUrl}/v1//events/promo-codes/${eventId}/${code}`;
	return this.http.get(url);
	}
  */
  constructor(
    protected http: HttpClient,
		protected queryParam: QueryParams
  ) {
    super('auth', null, http, queryParam);
  }

  public authWallet(body: { email: string, password: string }): Observable<any> {
    const url = `${environment.apiUrl}/v1/wallet`;
	  return this.http.post(url, body);
  }

  public renewTokenWallet(): Observable<any> {
    const url = `${environment.apiUrl}/v1/wallet`;
	  return this.http.get(url);
  }
}
