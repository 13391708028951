import checkField from './check-field';
import { FormFieldType, FormFieldOptions } from './type.definition';
import TicketForm from './ticket-form.model';
import { translateString } from '../etc/custom-translation/custom-translation.pipe';
import { formatDate } from '@angular/common';

export default class FormField {
    _id: string;
    isRequired: boolean;
    type: FormFieldType;
    form: TicketForm
    label: string;
    description: string;
    constraintsInfos: string = '';
    options: FormFieldOptions[];
    value: any;
    isValid: boolean;
    constraints: {
        startDate?: Date,
        endDate?: Date,
        extension?: string[],
        minLength?: number,
        maxLength?: number,
        min?: number,
        max?: number
    };

    validator: () => boolean;

    constructor(_formField?: any) {
        if (checkField(_formField?._id)) {
            this._id = _formField._id;
        }
        if (checkField(_formField?.isRequired)) {
            this.isRequired = _formField.isRequired;
        }
        if (checkField(_formField?.label)) {
            this.label = translateString(_formField.label);
        }
        if (checkField(_formField?.value)) {
            this.value = _formField.value;
        }
        if (checkField(_formField?.description)) {
            this.description = _formField.description;
        }
        if (checkField(_formField?.options)) {
            this.options = _formField.options;
        }
        if (checkField(_formField?.constraints)) {
            this.constraints = _formField.constraints;
        }
        if (checkField(_formField?.type)) {
            this.type = _formField.type;
            this.validator = this.getValidator();
            if (this.options && this.type === 'radio') {
                this.value = this.options[0].value;
                this.isValid = true;
            }
        }
        this.setConstraintsInfos();
    }

    createField(type, label, options = null, description = null) {
        const constraints = [];
        if (type === 'upload') {
            constraints.push({ type: 'fileType', extension: 'png' });
            constraints.push({ type: 'fileType', extension: 'jpeg' });
            constraints.push({ type: 'fileType', extension: 'jpg' });
        }
        else if (type === 'date') {
            constraints.push({ type: 'dateRange', startDate: '08/01/2020', endDate: '08/06/2020' });
        }
        return { type, label, isRequired: true, options, constraints, description }
    }

    setValue(_value: any): void {
        if (this.type == 'checkbox') {
            if (!this.value) {
                this.value = [_value];
            }
            else if (this.value.includes(_value)) {
                this.value = this.value.filter((_: string) => _ !== _value);
            }
            else {
                this.value.push(_value)
            }
            this.isValid = !!this.value.length
        }
        else {
            this.value = _value;
            if (!this.isRequired && [undefined, null, ''].includes(_value)) {
                this.isValid = null;
            }
            else {
                this.isValid = this.validateField();
            }
        }
    }

    getValidator(): () => boolean {
        switch (this.type) {
            case 'email': return this.checkEmail;
            case 'upload': return this.checkFile;
            case 'phone': return this.checkPhone;
            case 'number': return this.checkNumber;
            case 'checkbox': return this.checkCheckbox;
            case 'score': return this.checkScore;
            default: return this.checkText;
        }
    }

    checkIfValid = () => this.isValid = this.validateField();

    validateField = (): boolean => this.validator() ? this.nextValidation() : false;

    checkText = (): boolean => this.value?.trim().length > 0;
    checkEmail = (): boolean => /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,5}$/.test(this.value);
    checkPhone = (): boolean => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(this.value);
    checkNumber = (): boolean => /^[0-9]+$/.test(this.value);
    checkFile = (): boolean => this.value instanceof File;
    defaultCheck = (): boolean => checkField(this.value);
    checkCheckbox = (): boolean => !this.isRequired || this.value.length > 0;
    checkScore = (): boolean => !isNaN(this.value);

    nextValidation = (): boolean => !this.constraints || this.checkAllConstraints();

    checkAllConstraints(): boolean {
        if (this.constraints.startDate || this.constraints.endDate) {
            return this.checkDateConstraint();
        }
        else if (this.constraints.min || this.constraints.max) {
            return this.checkNumberRangeConstraint()
        }
        else if (this.constraints.minLength || this.constraints.maxLength) {
            return this.checkLengthConstraint()
        }
        else if (this.constraints.extension?.length) {
            if (!this.checkExtensionConstraint()) {
                this.value = null;
                return false
            }
            return true;
        }
        return true
    }

    checkDateConstraint(): boolean {
        const initDate = (date: Date) => {
            const newDate = new Date(date);
            newDate.setHours(0);
            newDate.setMinutes(0);
            newDate.setSeconds(0);
            return newDate
        }
        const selectedDate = new Date(this.value).getTime();
        const startDate = initDate(this.constraints.startDate)
        const endDate = initDate(this.constraints.endDate);
        endDate.setDate(endDate.getDate() + 1);
        return startDate.getTime() <= selectedDate && selectedDate <= endDate.getTime();
    }

    checkExtensionConstraint = (): boolean => {
        return this.constraints.extension.includes('.' + this.value.name.split('.').slice(-1)[0])
    };

    checkLengthConstraint(): boolean {
        const min = this.constraints.minLength;
        const max = this.constraints.maxLength
        return min <= this.value.length && this.value.length <= max;
    }

    checkNumberRangeConstraint(): boolean {
        const value = parseInt(this.value);
        const min = this.constraints.min;
        const max = this.constraints.max
        return min <= value && value <= max;
    }

    setConstraintsInfos(): void {
        this.setDateConstraintsInfos(this.constraints?.startDate, this.constraints?.endDate);
        this.setLengthConstraintsInfos(this.constraints?.minLength, this.constraints?.maxLength);
        this.setValueConstraintsInfos(this.constraints?.min, this.constraints?.max);
        this.setExtensionsConstraintsInfos(this.constraints?.extension);

    }

    setDateConstraintsInfos(startDate: Date, endDate: Date): void {
        if (startDate && endDate) {
            this.constraintsInfos = `Doit se situer entre le ${this.getDateFormat(startDate)} et le ${this.getDateFormat(endDate)}`;
        }
        else if (startDate) {
            this.constraintsInfos = `Doit se situer à partir du ${this.getDateFormat(startDate)}`;
        }
        else if (endDate) {
            this.constraintsInfos = `Ne doit pas dépasser le ${this.getDateFormat(endDate)}`;
        }
    }

    getDateFormat(date: Date): string {
        return `${formatDate(date, 'dd/MM/yyyy', 'fr')}`
    }

    setLengthConstraintsInfos(min: number, max: number): void {
        if (nbOk(min) && nbOk(max)) {
            this.constraintsInfos = `Doit contenir entre ${min} et ${max} caractères`;
        }
        else if (nbOk(min)) {
            this.constraintsInfos = `Doit contenir au moins ${min} caractères`;
        }
        else if (nbOk(max)) {
            this.constraintsInfos = `Doit contenir jusqu'à ${max} caractères`;
        }
    }

    setValueConstraintsInfos(min: number, max: number): void {
        if (nbOk(min) && nbOk(max)) {
            this.constraintsInfos = `Doit se situer entre ${min} et ${max}`;
        }
        else if (nbOk(min)) {
            this.constraintsInfos = `Minium: ${min}`;
        }
        else if (nbOk(max)) {
            this.constraintsInfos = `Maximum: ${max}`;
        }
    }


    setExtensionsConstraintsInfos(ext: string[]): void {
        if (this.type == 'upload') {
            if (ext.length) {
                this.constraintsInfos = ext.join(', ') + '. ';
            }
            this.constraintsInfos += '5mo max'
        }
    }

}


function nbOk(nb: number) {
    return !isNaN(nb) && nb !== null;
}
