import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopInService {

  displayPopIn$: Subject<boolean> = new Subject()

  constructor() { }

  displayPopIn(data){
    this.displayPopIn$.next(data);
  }

  closePopIn(){
    this.displayPopIn$.next(null);
  }

}
