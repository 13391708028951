import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-a-form-toggle-field',
  templateUrl: './a-form-toggle-field.component.html',
  styleUrls: ['./a-form-toggle-field.component.scss']
})
export class AFormToggleFieldComponent implements OnInit {

  @Input() label: string;
  @Input() checked: boolean;
  @Input() text: string;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onToggleChange(value: boolean): void {
    this.checkedChange.emit(value);
  }

}
