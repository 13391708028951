import Requester from '../requester';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Widget from 'src/app/models/widget.model';
import { QueryParams } from 'src/app/etc/queryParams';
import { Observable } from 'rxjs';

@Injectable()
export class EventWidgetService extends Requester {

  constructor(
    protected http: HttpClient,
    protected queryParam: QueryParams
  ) {
    super('events/widgets', Widget, http, queryParam);
  }

  public getById(_id, _body = null): Observable<any> {
    return super.getById(_id, _body);
  }

}
