<div class="a-form-textarea-field">
  <label [for]="id">{{label}} <span *ngIf="required">*</span></label>
  <div class="textarea-container" [id]="id" [class]="validate">
    <textarea [(ngModel)]="value" (ngModelChange)="onChange()" autosize></textarea>
    <i *ngIf="validate === 'success'" class="webfont webfont-check"></i>
    <i *ngIf="validate === 'error'" class="webfont webfont-close"></i>
  </div>
  <p class="description" *ngIf="description">
    {{description}}
  </p>

  <p class="constraints" *ngIf="constraintsInfos">
    {{constraintsInfos}}
  </p>

</div>
