import checkField from './check-field';
import Event from './event.model';
import FormField from './form-field.model';
import Order from './order.model';
import TicketForm from './ticket-form.model';
import TicketType from './ticket-type.model';
import { Price } from './type.definition';


export default class Ticket {
    _id: string;
    orderId: Order;
    price: Price;
    firstName: string;
    form: {
        id: TicketForm;
        fields: {
            formFieldId: FormField;
            label: string;
            values: string[];
        }
    };
    ticketTypeId: TicketType;
    ticketNumber: string;
    qrCode: string;
    eventId: Event;

    constructor(_ticket?: any) {
        if (checkField(_ticket?._id)) {
            this._id = _ticket._id;
        }
        if (checkField(_ticket?.orderId)) {
            this.orderId = _ticket.orderId;
        }
        if (checkField(_ticket?.price)) {
            this.price = _ticket.price;
        }
        if (checkField(_ticket?.firstName)) {
            this.firstName = _ticket.firstName;
        }
        if (checkField(_ticket?.form)) {
            this.form = _ticket.form;
        }
        if (checkField(_ticket?.ticketTypeId)) {
            this.ticketTypeId = _ticket.ticketTypeId;
        }
        if (checkField(_ticket?.ticketNumber)) {
            this.ticketNumber = _ticket.ticketNumber;
        }
        if (checkField(_ticket?.qrCode)) {
            this.qrCode = _ticket.qrCode;
        }
        if (checkField(_ticket?.eventId)) {
            this.eventId = _ticket.eventId;
        }
    }
}
