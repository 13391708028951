<div class="a-form-select-field">
  <label [for]="id">{{label}} <span *ngIf="required">*</span></label>
  <select [id]="id" [(ngModel)]="value" (ngModelChange)="onChange()">
    <option [value]="null">Choisir une option</option>
    <option *ngFor="let option of options" [value]="option.value || option">{{option.label || option}}</option>
  </select>
</div>
<p class="description" *ngIf="description">
  {{description}}
</p>
