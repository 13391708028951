export class LocalStorageWrapper {

  data: Object;

  constructor() { }

  // returns an empty model of the data property
  getEmptyModel() {
    return {
      eventId: '',
      widgetId: '',
      categories: [],
    };
  }

  // initializes the data property (load or create)
  init() {
    if (!localStorage.getItem('object')) {
      this.data = this.getEmptyModel();
      this.save();
    }
    this.load();
  }

  // saves the data property to the localStorage
  save() {
    localStorage.setItem('object', JSON.stringify(this.data));
  }

  // load the data property from the localStorage
  load() {
    this.data = JSON.parse(localStorage.getItem('object'));
  }

  // set a value for a given field
  set(field, value) {
    this.data[field] = value;
    this.save();
  }

  // empties the data property
  empty() {
    this.data = this.getEmptyModel();
    this.save();
  }

  // GETTERS

  get(field) {
    return this.data[field];
  }

  getNbTickets() {
    let nb = 0;
    for (const category of this.get('categories')) {
      for (const ticket of category.tickets) {
        nb += ticket.qty;
      }
    }
    return nb;
  }

  getTicketsTotal() {
    let total = 0;
    for (const category of this.get('categories')) {
      for (const ticket of category.tickets) {
        total += ticket.qty * ticket.price;
      }
    }
    return +total.toFixed(2);
  }

  getTotal() {
    let total = 0;
    for (const category of this.get('categories')) {
      for (const ticket of category.tickets) {
        total += ticket.qty * ticket.price;
      }
    }
    return +total.toFixed(2);
  }

  getAllowedCategories() {
    const allowedCategories = [];
    for (const category of this.get('categories')) {
      if (category.tickets.length > 0) {
        allowedCategories.push(category.id);
      }
    }
    return allowedCategories;
  }

  getAllowedTickets() {
    const allowedTickets = [];
    for (const category of this.get('categories')) {
      for (const ticket of category.tickets) {
        if (ticket.qty > 0) {
          allowedTickets.push(ticket.id);
        }
      }
    }
    return allowedTickets;
  }
}
