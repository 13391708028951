<app-t-content-w-sidebar *ngIf="loaded">

    <!-- SLOT TOP PAGE -->
    <!-- <app-m-content-section slotTopPage>
    <h2 slotTitle>Choix sur plan de salle :</h2>
    <app-m-banner-seat-selection slotContent></app-m-banner-seat-selection>
    </app-m-content-section> -->

    <!-- SLOT CONTENT -->
    <app-m-content-section slotContent *ngIf="categories?.length > 0">
        <h2 slotTitle>{{"Choix par catégorie" | customTranslation:"categoryChoice"}} :</h2>
        <div slotContent>
            <ng-container *ngFor="let category of categories">
                <app-m-category-accordion-item *ngIf="category.tickets" [categoryName]="category.name"
                    [allFree]="allFree" [categoryStartRate]="getCategoryLowestPrice(category)"
                    [ticketsNumber]="isCategoryExpand(category)">

                    <div slotListRates *ngFor="let ticket of category.tickets">
                        <app-m-card-rate [allFree]="allFree" [rateName]="ticket.name" [rateAmount]="ticket.price"
                            [unavailable]="ticket.unavailable" [rateMinSelectable]="ticket.tickets?.minimumSelectable"
                            [rateQuantity]="ticket.quantity" [rateDescription]="ticket.description"
                            [rateIsLimited]="ticket.quota" [rateMaxSelectable]="ticket.tickets?.maximumSelectable"
                            [rateQuota]="ticket.quota" [rateStock]="ticket.stock  || 0" [rateStockBuffer]="ticket.stockBuffer || 0"
                            [rateCategoryQuota]="category.quota" [rateCategoryStock]="category.stock || 0" [rateCategoryStockBuffer]="category.stockBuffer || 0"
                            [rateEventQuota]="event.ticketing?.quota || 0"
                            [endDate]="ticket.scheduled.endDate"
                            [showCountDown]="ticket.showCountDown" [showRemainingPlace]="ticket.showRemainingPlace"
                            [rateEventStock]="event.ticketing?.stock || 0" [rateSessionQuota]="session?.quota || 0"
                            [rateSessionStock]="session?.stock || 0" [rateCategoryId]="category.id"
                            [stockChange$]="stockChange$" (update)="updateQuantity(ticket,  $event)">
                        </app-m-card-rate>
                    </div>
                </app-m-category-accordion-item>
            </ng-container>
        </div>
    </app-m-content-section>
    <app-m-content-section *ngIf="noCategory?.length" slotContent>
        <h2 *ngIf="categories?.length > 0" slotTitle>{{"Autre choix" | customTranslation:"otherChoice"}} :</h2>
        <ul slotContent class="list-rates">
            <!-- Tickets whitout category -->
            <li *ngFor="let ticket of noCategory">
                <app-m-card-rate [allFree]="allFree" [showCountDown]="ticket.showCountDown" [showRemainingPlace]="ticket.showRemainingPlace" [rateName]="ticket.name" [rateAmount]="ticket.price"
                    [rateQuantity]="ticket.quantity" [rateQuota]="ticket.quota" [rateDescription]="ticket.description"
                    [endDate]="ticket.scheduled.endDate"
                    [rateStock]="ticket.stock || 0" [rateEventStock]="event.ticketing?.stock || 0" [rateStockBuffer]="ticket.stockBuffer || 0"
                    [rateEventQuota]="event.ticketing?.quota || 0" [rateSessionQuota]="session?.quota || 0" [rateCategoryStockBuffer]="0"
                    [rateSessionStock]="session?.stock || 0" [rateMinSelectable]="ticket.tickets?.minimumSelectable"
                    [rateMaxSelectable]="ticket.tickets?.maximumSelectable" (update)="updateQuantity(ticket,  $event)"
                    [rateIsLimited]="ticket.quota" [unavailable]="ticket.unavailable" [stockChange$]="stockChange$">
                </app-m-card-rate>
            </li>
        </ul>
    </app-m-content-section>

    <!-- SLOT SIDEBAR -->
    <app-o-summary *ngIf="ticketsAvailable" slotSidebar></app-o-summary>

    <!-- IF ALL TICKETS SOULDOUT -->
    <app-o-soldout *ngIf="ticketsAvailable === false" slotSidebar></app-o-soldout>

</app-t-content-w-sidebar>