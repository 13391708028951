import { SetKeyModel, SetCartModel, AppPrice } from 'src/app/models/type.definition';
import AddOn from 'src/app/models/add-on.model';
import TicketType from 'src/app/models/ticket-type.model';
import PromoCode from 'src/app/models/promo-code.model';

export class SetKeysGlobal {
    static readonly type = '[GLOBAL] SetKeys';
    constructor(public payload: SetKeyModel) {}
}

export class SetCart {
    static readonly type = '[GLOBAL] SetCart';
    constructor(public payload?: SetCartModel) {}
}

export class SetAddOn {
    static readonly type = '[GLOBAL] SetAddon';
    constructor(public payload?: {ticket?: TicketType, addOn?: AddOn}) {}
}

export class SetInsurance {
    static readonly type = '[GLOBAL] SetInsurance';
    constructor(public payload: {amount: AppPrice}) {}
}

export class SetPromoCode {
    static readonly type = '[GLOBAL] SetAddSetPromoCode';
    constructor(public payload?: {code?: PromoCode, qty?: number}) {}
}

export class RemovePromoCode {
    static readonly type = '[GLOBAL] RemovePromoCode';
}

export class SetBookingTime {
    static readonly type = '[GLOBAL] SetBookingTime';
    constructor(public payload?: {value: number}) {}
}
