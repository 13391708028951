import Requester from '../requester';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from 'src/app/etc/queryParams';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class WalletService {

	constructor(
		protected http: HttpClient,
		protected queryParam: QueryParams
	) {
    
	}
  
  public userExists(_email) {
    const url = `${environment.apiWalletUrl}/v1/user/check-email/${_email}`;
		return this.http.get(url);
  }

}
