import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import Currency from 'src/app/models/currency.model';
import { CurrencyService } from 'src/app/providers';

@Component({
  selector: 'app-a-category-seat-item',
  templateUrl: './a-category-seat-item.component.html',
  styleUrls: ['./a-category-seat-item.component.scss']
})
export class ACategorySeatItemComponent implements OnInit, OnDestroy {

  @Input() categoryColor: string;
  @Input() categoryName: string;
  @Input() categoryPriceStart: number;
  subscriptions = new Subscription();
  currency: Currency;

  constructor(private currencyService: CurrencyService) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.currencyService.currency$.subscribe(res => {
      this.currency = res;
    }));
  }

}
