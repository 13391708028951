import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { translateString } from 'src/app/etc/custom-translation/custom-translation.pipe';
import Currency from 'src/app/models/currency.model';
import { CurrencyService } from 'src/app/providers';

@Component({
  selector: 'app-m-card-rate',
  templateUrl: './m-card-rate.component.html',
  styleUrls: ['./m-card-rate.component.scss']
})
export class MCardRateComponent implements OnInit, OnDestroy {
  @Input() rateName: string;
  @Input() rateAmount: number;
  @Input() rateDescription: string;
  @Input() rateQuantity: number;
  @Input() rateStock: number;
  @Input() rateStockBuffer: number;
  @Input() rateQuota: number;
  @Input() rateIsLimited: boolean;
  @Input() rateCategoryQuota: number;
  @Input() rateMinSelectable: number;
  @Input() rateMaxSelectable: number;
  @Input() rateCategoryStock: number;
  @Input() rateCategoryStockBuffer: number;
  @Input() rateCategoryId: number;
  @Input() rateSessionQuota: number;
  @Input() rateSessionStock: number;
  @Input() rateEventQuota: number;
  @Input() rateEventStock: number;
  @Input() unavailable: boolean;
  @Input() showCountDown: boolean;
  @Input() endDate?: Date;
  @Input() showRemainingPlace: boolean;
  @Input() stockChange$: BehaviorSubject<any>;
  @Input() allFree: boolean;
  limitString: string;
  limit: number;
  currency: Currency;
  subscriptions = new Subscription();

  @Output() update = new EventEmitter<{ quantity: number, sum: number }>();

  showDescription = false;

  constructor(private currencyService: CurrencyService) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.currencyService.currency$.subscribe(res => {
      this.currency = res;
    }));
    this.subscriptions.add(this.stockChange$.subscribe((data: any) => {
      if (data) {
        if (this.rateCategoryId === data.categoryId) {
          this.rateCategoryStock = data.categoryStock;
        }
        this.rateSessionStock = data.sessionStock;
        this.rateEventStock = data.eventStock;
      }
      this.calcLimit();
    }));
  }

  calcLimit(): void {

    this.limit = null;
    if (this.rateIsLimited) {
      this.limit = this.rateStock - this.rateStockBuffer - this.rateQuantity;
      if (!this.limit) {
        this.setLimitTo0(translateString("ce ticket.", "thisTicket"));
      }
    }
    if (this.rateCategoryQuota && this.isSet(this.rateCategoryStock) && (this.isNotSet(this.limit) || this.rateCategoryStock < this.limit)) {
      this.limit = this.rateCategoryStock - this.rateCategoryStockBuffer;
    }
    if (this.rateSessionQuota && this.isSet(this.rateSessionStock) && (this.isNotSet(this.limit) || this.rateSessionStock < this.limit)) {
      this.limit = this.rateSessionStock;
    }
    if (this.rateEventQuota && (this.isNotSet(this.limit) || this.rateEventStock < this.limit)) {
      this.limit = this.rateEventStock;
    }
    if (this.rateEventQuota && !this.rateEventStock) {
      this.setLimitTo0(translateString("cet événement.", 'thisEvent'));
    }
    else if (this.rateSessionQuota && this.isSet(this.rateSessionStock) && !this.rateSessionStock) {
      this.setLimitTo0(translateString("cette session.", 'thisSession'));
    }
    else if (this.rateCategoryQuota && this.isSet(this.rateCategoryStock) && !this.rateCategoryStock) {
      this.setLimitTo0(translateString("cette catégorie.", 'thisCategory'));
    }
    else if ((this.rateIsLimited && !this.rateStock) || (this.limit < this.rateMinSelectable && this.rateQuantity < this.rateMinSelectable)) {
      this.setLimitTo0(translateString("ce ticket.", "thisTicket"));
    }

  }

  setLimitTo0(limitString: string): void {
    this.limitString = ` ${limitString}`;
    this.limit = 0;
  }

  disableTicket() {
    this.unavailable = true
  }

  private isSet(value: any): boolean {
    return !this.isNotSet(value);
  }

  private isNotSet(value: any): boolean {
    return [null, undefined].includes(value);
  }

  updateRateQuantity(newQuantity): void {
    const sum = (newQuantity - this.rateQuantity) * -1;

    if (this.rateMinSelectable && !this.rateQuantity) {
      for (let i = 1; i <= this.rateMinSelectable; i++) {
        this.changeStockAndEmit(sum, i);
      }
    }
    else if (this.rateMinSelectable && this.rateMinSelectable === newQuantity + 1) {
      for (let i = this.rateQuantity - 1; i >= 0; i--) {
        this.changeStockAndEmit(sum, i);
      }
    }
    else {
      this.changeStockAndEmit(sum, newQuantity);
    }
  }

  changeStockAndEmit(sum, quantity) {
    this.rateQuantity += -sum;

    this.update.emit({ quantity, sum });
  }

}
