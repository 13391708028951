import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-a-loader',
  templateUrl: './a-loader.component.html',
  styleUrls: ['./a-loader.component.scss']
})
export class ALoaderComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }



}
