<div class="contain-pop-in" (click)="clickOut()">
    <div class="pop-in" (click)="clickIn()">
        <div class="pop-in-content">
            <p>{{infoText}}</p>
            <p class="bold">{{boldText}}</p>
            <div class="buttons">
                <div class="button">
                    <app-a-button [text]="firstButtonText" (buttonClicked)="clickFirst()"></app-a-button>
                </div>
                <div class="button" *ngIf="secondButtonText">
                    <app-a-button [text]="secondButtonText" (buttonClicked)="clickSecond()"></app-a-button>
                </div>
            </div>
        </div>
    </div>
</div>