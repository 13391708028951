import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-a-form-score-field',
  templateUrl: './a-form-score-field.component.html',
  styleUrls: ['./a-form-score-field.component.scss']
})
export class AFormScoreFieldComponent implements OnInit {

  @Input() label: string;
  @Input() required: boolean;
  @Input() validate: string;
  @Input() description: string
  @Input() maxScore = 5;
  @Output() InputChange = new EventEmitter<string>();
  @Input() constraintsInfos: string;
  public value: number;

  currentHover = null;

  arrayOne(n: number): any[] {
    return Array(n);
  }

  constructor() { }

  ngOnInit(): void {
  }

  changeValue(_value) {
    this.value = _value;
    this.InputChange.emit(_value)
  }

  onMouseOver(index: number): void {
    this.currentHover = index;
  }
  onMouseOut(): void {
    this.currentHover = null;
  }

}
