import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { GlobalState, GlobalStateModel } from 'src/app/store/states/global.state';
import { TimeLineService, FileService, CurrencyService, TicketFormService, FormFieldService, AddonService } from 'src/app/providers';
import { Subscription, Observable, Subject } from 'rxjs';
import BillingForm from 'src/app/models/billing-form.model';
import TicketType from 'src/app/models/ticket-type.model';
import TicketForm from 'src/app/models/ticket-form.model';
import OrderTicketForm from 'src/app/models/order-ticket-form.model';
import FormField from 'src/app/models/form-field.model';
import AddOn from 'src/app/models/add-on.model';
import { SetAddOn } from 'src/app/store/actions/global.actions';
import Currency from 'src/app/models/currency.model';
import Event from 'src/app/models/event.model';
import { translateString } from 'src/app/etc/custom-translation/custom-translation.pipe';
import Widget from 'src/app/models/widget.model';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.html',
  styleUrls: ['./contact-information.scss']
})
export class ContactInformationPage implements OnInit, OnDestroy {
  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;
  private billingFormKeys: string[];
  private subscriptions = new Subscription();
  public initDone: boolean;
  public orderTicketForms: OrderTicketForm[] = [];
  public tickets: TicketType[];
  public invalidForm = true;
  public minAddOnRequired = false;
  public event: Event;
  public widget: Widget;
  public billingForm: TicketForm;
  public ticketsSendingOptionSelected: string;
  public allFree: boolean;
  public isRegistration: boolean;
  public currentAddonHovered: number;
  public products: TicketType[];
  public addonsDisabled = {};
  public ticketsSendingOptions = [
    {
      value: 'same',
      label: translateString('Je souhaite recevoir tous les tickets sur la même adresse e-mail.', "radioSameMail")
    },
    {
      value: 'multiple',
      label: translateString('Je souhaite que chaque participant reçoive son e-ticket.', "radioAllMails")
    }
  ];

  public fastScanPrice = 5;
  public isFastScanSelected = false;
  public isTicketReceptionDigital = true;
  public isTicketReceptionPhysical = false;
  public ticketReceptionDigitalPrice = 1;
  public currency: Currency;
  public errorMessageOpen = true;
  public minAddOnMessageOpen = false;
  public expendedSubscription = new Subject();

  /** @todo Temp */
  public addons: AddOn[] = [];


  constructor(
    private timeLineService: TimeLineService,
    public router: Router,
    public route: ActivatedRoute,
    public addonService: AddonService,
    private fileService: FileService,
    private store: Store,
    private currencyService: CurrencyService,
    private ticketFormService: TicketFormService,
    private formFieldService: FormFieldService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.currencyService.currency$.subscribe(res => {
      this.currency = res;
    }));
    this.timeLineService.updateTimeLine('contactInformation');
    this.subscriptions.add(this.globalState$.subscribe(async (state: GlobalStateModel) => {
      if (!this.initDone) {
        this.isRegistration = state.globalObject.isRegistration;
        this.allFree = state.globalObject.allFree;
        const eventId = state.globalObject.eventId;
        this.widget = state.globalObject.widget;
        this.event = this.widget.eventId;

        this.tickets = [];
        const cart = state.globalObject.cart;
        this.addons = state.globalObject.generalAddOns;
        this.tickets = cart.tickets;

        await this.getAddons(eventId._id);
        this.setBillingForm(state);
        if (!this.widget.forProduct) {
          this.tickets.forEach(_ => this.orderTicketForms.push(new OrderTicketForm(_._id)));
          await this.getForms(eventId._id);
        }
        else {
          this.createForms([]);
          this.products = [];
          let i = 0;
          let tempTickets = this.tickets;
          while (i < tempTickets.length) {
            const same = tempTickets.filter((current, j) => i !== j && current._id === tempTickets[i]._id);
            this.products.push(new TicketType({
              ...tempTickets[i],
              name: `${same.length + 1}x ${tempTickets[i].name}`
            }));
            tempTickets = tempTickets.filter((current, j) => i !== j && current._id !== tempTickets[i]._id);
          }
          this.products.sort((a,b) => a.ticketTypeCategoryId > b.ticketTypeCategoryId ? -1 : 1)
        }
        this.initDone = true;
      }
      else if (this.isRegistration && this.allFree !== state.globalObject.allFree) {
        this.allFree = state.globalObject.allFree;
        this.setBillingForm(state);
        this.checkMissingFields();
      }
      state.globalObject.orderTicketForms = this.orderTicketForms;
      this.tickets.forEach((ticket, index) => {
        this.addonsDisabled[index] = this.addonsDisabled[index] ?? false
      })

      if (this.event.ticketing.minimumAddonAmount) {
        //this.minAddOnRequired = true
      }

    }));
  }

  private setBillingForm(state: GlobalStateModel): void {
    state.globalObject.billingForm = this.createBillingForm();
    this.billingForm = state.globalObject.billingForm;
    this.billingFormKeys = Object.keys(this.billingForm);
  }

  async getAddons(eventId: string): Promise<void> {
    const ticketTypeIds = [...new Set(this.tickets.map(_ => _._id))].join(',');
    const filter = { eventId, ticketTypeIds }
    const addonsResults = await this.addonService.getList({ filter, perPage: 500 }).toPromise();
    if (addonsResults) {
      const addons: AddOn[] = addonsResults.data.map(addon => ({ ...addon, price: addon.price / 100 })) || [];
      this.tickets.forEach(ticket => {
        ticket.addons = [];
        addons.forEach(addon => {
          if (addon.ticketTypeIds.map(_ => _._id).includes(ticket._id)) {
            ticket.addons.push(new AddOn(addon))
          }
        })
      })
    }
  }

  placeholderFunction(_value: any, _field: FormField, _form: TicketForm | BillingForm | OrderTicketForm): void {

    if (_value == "null") {
      _value = null;
    }
    _field.setValue(_value);
    _form.missingFields = this.checkErrorInForm(_form);
    if (_form == this.billingForm && this.billingForm.isValid) {
      this.orderTicketForms.forEach(form => form.fields.forEach(field => field.checkIfValid()))
      this.checkMinimumAddons();
    }
    this.checkMissingFields();
    if (_form == this.billingForm && this.billingForm.isValid && this.invalidForm) {
      this.expendedSubscription.next();
    }
  }

  checkErrorInForm(form: TicketForm | BillingForm | OrderTicketForm): number {
    let error = 0;
    if (form instanceof TicketForm || form instanceof OrderTicketForm) {
      form.fields.forEach(_field => {
        if ((_field.isRequired || !['', null, undefined].includes(_field.value)) && !_field.isValid) {
          error++;
        }
      })
    }
    else if (form instanceof BillingForm) {
      this.billingFormKeys.forEach(_key => {
        if (this.billingForm[_key].isRequired && !this.billingForm[_key].isValid) {
          error++
        }
      })
    }
    return error;
  }

  checkMissingFields(): void {
    let errors = 0;
    this.tickets.forEach(_ticket => {
      _ticket.form?.forEach(form => {
        errors += form.missingFields
      })
    });
    if (this.billingForm) {
      errors += this.billingForm.missingFields;
    }
    if (this.orderTicketForms) {
      this.orderTicketForms.forEach(_ => errors += _.missingFields);
    }
    this.invalidForm = errors > 0;
  }

  checkIfValidate(_field: FormField): string {
    switch (_field.isValid) {
      case true: return 'success';
      case false: return 'error';
    }
  }

  getType(_type: string): string {
    switch (_type) {
      case 'phone': return 'tel';
      case 'textfield': return 'text';
      case 'upload': return 'file';
      case 'link': return 'url';
      default: return _type;
    }
  }

  treatFile(_file: any, _field: FormField, _form: TicketForm): void {
    this.fileService.create(_file)
      .then(res => this.placeholderFunction(res, _field, _form));
  }

  checkMinimumAddons() {
    let minAddOnRequired = false
    if (this.event.ticketing?.minimumAddonAmount) {
      this.tickets.forEach(ticket => {
        const activeAddons = ticket.addons.filter(addon => addon.isChecked)
        if (ticket.addons.length >= this.event.ticketing?.minimumAddonAmount && ticket.addons.length > 0 && activeAddons.length < this.event.ticketing?.minimumAddonAmount) {
          minAddOnRequired = true
        }
        if (ticket.addons.length < this.event.ticketing?.minimumAddonAmount && activeAddons.length < ticket.addons.length) {
          minAddOnRequired = true
        }
      })
    }
    this.minAddOnRequired = this.minAddOnMessageOpen =  minAddOnRequired
  }
  addOnChecked(_value: boolean, _addOn: AddOn, _index: number, _ticket?: TicketType) {

    _value ? _addOn.add(_index) : _addOn.remove(_index);
    _addOn.isChecked = !_addOn.isChecked;
    this.store.dispatch(new SetAddOn(_ticket ? { ticket: _ticket } : { addOn: _addOn }));
    if (this.minAddOnRequired || !_value) {
      this.checkMinimumAddons();
    }
    if (this.event.ticketing?.limitAddonAmount) {
      const activeAddons = _ticket.addons.filter(addon => addon.isChecked)
      if (activeAddons.length >= this.event.ticketing?.limitAddonAmount) {
        this.addonsDisabled[_index] = true
      }
      else {
        this.addonsDisabled[_index] = false
      }
    }
  }

  getAuthorizeExtensions = (_field) => _field.constraints.extension.join(', ');

  getForms(eventId: string): Promise<void> {
    const getFormsOptions = {
      filter: { eventId }
    }
    return new Promise(async res => {
      const forms: TicketForm[] = (await this.ticketFormService.getList(getFormsOptions).toPromise())?.data || [];
      await this.getFields(forms);
      this.createForms(forms);
      res();
    });
  }

  getFields(forms: TicketForm[]): Promise<void> {
    if (forms.length) {
      return new Promise(async res => {
        for (let i = 0; i < forms.length; i++) {
          const options = {
            select: ['type', 'label', 'isRequired', 'description', 'constraints', 'options'],
            filter: { formId: forms[i]._id }
          }
          forms[i].fields = (await this.formFieldService.getList(options).toPromise())?.data;
          if (i === forms.length - 1) {
            res();
          }
        };
      });
    }
  }

  createForms(forms: TicketForm[]) {
    this.tickets.forEach(ticket => {
      ticket.form = [];
      forms.forEach(form => {
        let addForm = !form.ticketTypes.restrict || form.ticketTypes.ids.includes(ticket._id);
        if (addForm) {
          ticket.form.push(new TicketForm(form));
        }
      })
    })
  }

  createBillingForm() {
    if (!this.allFree) {
      const civilities = [{ label: translateString('Madame', 'ms'), value: 'mme' }, { label: translateString('Monsieur', 'mr'), value: 'mr' }];
      let terms = translateString(`J'accepte que les coordonnées renseignées soient utilisées pour éditer
      le(s) ticket(s) et la facture et permettent de me recontacter si nécessaire.`, 'defaultTerm')
      if (this.event.ticketing.termAndConditions.text) {
        terms = this.event.ticketing.termAndConditions.text;
      }
      if (this.event.ticketing.termAndConditions.url) {
        terms += ` <a target="_blank" href='${this.event.ticketing.termAndConditions.url}'>`
      }
      else {
        terms += ` <a target="_blank" href='https://www.oandb.fr/cgv'>`
      }
      terms += `${translateString("En savoir plus", "readMore")}</a>`
      const fields = [
        this.createBillingFormField('acceptTerms', terms, 'checkbox', [{ value: 'terms', checked: false }]),
        this.createBillingFormField('civility', 'Civilité', 'radio', civilities),
        this.createBillingFormField('company', 'Société', 'textfield', null, false),
        this.createBillingFormField('firstName', 'Prénom'),
        this.createBillingFormField('lastName', 'Nom'),
        this.createBillingFormField('emailAddress', 'Adresse e-mail', 'email'),
      ]
      if (this.widget.forProduct && this.widget.askZone) {
        fields.push(this.createBillingFormField('zone', 'Emplacement (Zone, Place, Loge)', 'textfield'));
      }
      return new TicketForm({fields});
    }
    else return new TicketForm({
      fields: [this.createBillingFormField('emailAddress', 'Adresse e-mail', 'email', null, true)]
    })
  }

  createBillingFormField(_key, _label, _type = 'textfield', _options = null, isRequired = !this.allFree) {
    const newFormField = {
      label: _label,
      isRequired,
      type: _type,
      options: _options,
      value: null,
    }
    return new FormField(newFormField);
  }

  changeSendingOption(option: string) {

    if (option == 'multiple') {
      OrderTicketForm.setEmails();
    }
    else {
      OrderTicketForm.deleteEmails();
    }
    this.checkMissingFields();
  }

  formMissingFields(ticket: TicketType): number {
    const missingFields = ticket.form.map(_ => _.missingFields);
    return missingFields.length ? missingFields.reduce((a, b) => a + b) : 0;
  }

  formAddonRequired(ticket: TicketType): boolean {
    if (ticket.addons.length == 0) {
      return false
    }
    if (this.event.ticketing?.minimumAddonAmount) {
      const activeAddons = ticket.addons.filter(addon => addon.isChecked)
      if (ticket.addons.length >= this.event.ticketing?.minimumAddonAmount && ticket.addons.length > 0 && activeAddons.length < this.event.ticketing?.minimumAddonAmount) {
        return true
      }
      if (ticket.addons.length < this.event.ticketing?.minimumAddonAmount && activeAddons.length < ticket.addons.length) {
        return true
      }
    }
    return false
  }

}
