import checkField from './check-field';

export default class RequestBody {
    filter: any;
    select: string[];
    populate: string[];
    sort: [any[]];
    page: number
    perPage: number

    constructor(_requestBody?: any) {

        if (checkField(_requestBody?.filter)) {
            this.filter = _requestBody.filter;
        }
        if (checkField(_requestBody?.select)) {
            this.select = _requestBody.select;
        }
        if (checkField(_requestBody?.populate)) {
            this.populate = _requestBody.populate;
        }
        if (checkField(_requestBody?.sort)) {
            this.sort = _requestBody.sort;
        }
        if (![undefined, null].includes(_requestBody?.page)) {
            this.page = _requestBody.page;
        }
        if (![undefined, null].includes(_requestBody?.perPage)) {
            this.perPage = _requestBody.perPage;
        }
    }
}
