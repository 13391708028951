import RequestBody from 'src/app/models/request-body.model';

export class QueryParams {

	constructor() { }

	// Exemple body

	// {
	// 	filter: {
	// 		name: "String"
	// 	},
	// 	select: ['name', 'path', 'eventId'],
	// 	populate: ['event', 'user'],
	// 	sort: [['name', 1]]
	// }

	translate(_body: RequestBody): string {
		let query = '';
		let filter = null;
		let customFilter: string | null = null;
		let select = null;
		let populate = null;
		let sort = null;
		let firstQuery = false;
		let page = null
		let perPage = null
		if (_body !== undefined && _body !== null) {
			if (_body.filter !== undefined) {
				filter = this.makeFilter(_body.filter);
			}
			if (_body.customFilter !== undefined) {
				customFilter = this.makeCustomFilter(_body.customFilter);
			}
			if (_body.select !== undefined) {
				select = this.makeSelect(_body.select);
			}

			if (_body.populate !== undefined) {
				populate = this.makePopulate(_body.populate);
			}

			if (_body.sort !== undefined) {
				sort = this.makeSort(_body.sort);
			}
			if (_body.page || _body.page == 0) {
				page = this.makePage(_body.page);
			}
			if (_body.perPage || _body.perPage == 0) {
				perPage = this.makePage(_body.perPage);
			}

			if (filter !== null) {
				query = `?filter=${filter}`;
				firstQuery = true;
			}

			if (customFilter !== null) {
				if (firstQuery) {
					query = `${query}&customFilter=${customFilter}`;
				} else {
					query = `?customFilter=${customFilter}`;
					firstQuery = true;
				}
			}

			if (select !== null) {
				if (firstQuery) {
					query = `${query}&select=${select}`;
				} else {
					query = `?select=${select}`;
					firstQuery = true;
				}
			}

			if (populate !== null) {
				if (firstQuery) {
					query = `${query}&populate=${populate}`;
				} else {
					query = `?populate=${populate}`;
					firstQuery = true;
				}
			}

			if (sort !== null) {
				if (firstQuery) {
					query = `${query}&sort=${sort}`;
				} else {
					query = `?sort=${sort}`;
					firstQuery = true;
				}
			}
			if (page != null) {
				if (firstQuery) {
				  query = `${query}&page=${page}`;
				} else {
				  query = `?page=${page}`;
				  firstQuery = true;
				}
			  }
			  if (perPage != null) {
				if (firstQuery) {
				  query = `${query}&perPage=${perPage}`;
				} else {
				  query = `?perPage=${perPage}`;
				  firstQuery = true;
				}
			  }
			return query;
		} else {
			return query;
		}
	}

	makeCustomFilter(_data: any): string | null {
		let customFilter: string | null = null;
		const keys = Object.keys(_data);
		for (let i = 0; i < keys.length; ++i) {
			const value = (_data[keys[i]] === true);
			if (i === 0) {
				customFilter = `${keys[i]}==${value}`;
			} else {
				customFilter = `${customFilter}__${keys[i]}==${value}`;
			}
		}
		return customFilter
	}

	makeFilter(_data: any): string {

		let filter: string = null;

		const keys = Object.keys(_data);

		for (let i = 0; i < keys.length; ++i) {
			let operator = '==';
			let filterValue = _data[keys[i]];
			if (typeof filterValue === 'object') {
				const key = Object.keys(filterValue);
				operator = `[${key[0]}]`;
				filterValue = filterValue[key[0]];
			}
			if (i === 0) {
				filter = `${keys[i]}${operator}${filterValue}`;
			} else {
				filter = `${filter}__${keys[i]}${operator}${filterValue}`;
			}
		}

		return filter;
	}

	makeSelect(_data: any): string {

		let sort: string = null;

		for (let i = 0; i < _data.length; ++i) {
			if (i === 0) {
				sort = _data[i];
			} else {
				sort = `${sort}__${_data[i]}`;
			}
		}

		return sort;
	}

	makePopulate(_data: any): string {

		let populate: string = null;

		for (let i = 0; i < _data.length; ++i) {
			if (i === 0) {
				populate = _data[i];
			} else {
				populate = `${populate}__${_data[i]}`;
			}
		}

		return populate;
	}

	makeSort(_data: any): string {

		let sort = null;

		for (let i = 0; i < _data.length; i++) {

			if (i === 0) {
				sort = `${_data[i][0]}==${_data[i][1]}`;
			} else {
				sort = `${sort}__${_data[i][0]}==${_data[i][1]}`;
			}
		}

		return sort;
	}

	makePage(data: number): string {
		return `${data}`;
	  }
}
