<header>
  <p><span *ngFor="let seatInfo of seatInfos">{{seatInfo}}</span></p>
  <div class="actions">
    <button (click)="showSeat()" aria-label="Voir la place" title="Voir la place" class="webfont webfont-pin"></button>
    <button (click)="deleteSeat()" aria-label="Supprimer cette place" title="Supprimer cette place"
      class="webfont webfont-delete"></button>
  </div>
</header>
<div class="content">
  <p class="top"><span>{{categoryName}}</span><strong>{{priceType}}</strong></p>
  <p class="bottom">{{price | currency:currency.isoCode}}</p>
</div>
