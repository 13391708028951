import Requester from '../requester';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from 'src/app/etc/queryParams';
import { Observable } from 'rxjs';
import Addon from 'src/app/models/add-on.model';
import { RequestResponseList } from 'src/app/models/type.definition';

@Injectable()
export class AddonService extends Requester {

    constructor(
        protected http: HttpClient,
        protected queryParam: QueryParams
    ) {
        super('events/addons', Addon, http, queryParam);
    }

    public getList(_body = null): Observable<RequestResponseList> {
        return super.getList(_body);
    }

}