import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-a-tooltip',
  templateUrl: './a-tooltip.component.html',
  styleUrls: ['./a-tooltip.component.scss']
})
export class ATooltipComponent implements OnInit {
  @Input() duration: number;
  closed = false
  constructor() { }

  ngOnInit(): void {
    if (this.duration > 0) {
      setTimeout(() => {
        this.closed = true
      }, this.duration);
    }
  }

}
