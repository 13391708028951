import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-a-form-select-field',
  templateUrl: './a-form-select-field.component.html',
  styleUrls: ['./a-form-select-field.component.scss']
})
export class AFormSelectFieldComponent implements OnInit {

  @Input() label: string;
  @Input() id: string;
  @Input() value: string;
  @Input() required: boolean;
  @Input() description: string;
  @Input() options: Array<any>;
  @Output() InputChange = new EventEmitter<string>();
  @Input() constraintsInfos: string;

  constructor() { }

  ngOnInit(): void {

  }

  onChange(): void {
    this.InputChange.emit(this.value);
  }

}
