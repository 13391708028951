<app-o-pop-in
    (firstClick)="cancelRouting(true)"
    (secondClick)="cancelRouting(false)"
    (close)="cancelRouting(false)"
    [infoText]="'Cette action réinitialisera votre panier.' | customTranslation: 'resetCart'"
    [boldText]="'Continuer ?' | customTranslation: 'continue'"
    [firstButtonText]="'Oui' | customTranslation"
    [secondButtonText]="'Non' | customTranslation"
    *ngIf="preventRouting"
></app-o-pop-in>

<app-o-pop-in
    *ngIf="popIn"
    (firstClick)="popIn.firstClick()"
    (secondClick)="popIn.secondClick()"
    (close)="closePopIn() && popIn.close()"
    [infoText]="popIn.infoText"
    [boldText]="popIn.boldText"
    [firstButtonText]="popIn.firstButtonText"
    [secondButtonText]="popIn.secondButtonText"
></app-o-pop-in>
<app-l-default>

    <app-o-header
        *ngIf="!isMaintenance && currentStep !== 'closed'"
        slotHeader
    ></app-o-header>
    <app-m-timeline
        *ngIf="currentStep !== 'closed'"
        slotTimeline
    ></app-m-timeline>

    <router-outlet slotContent></router-outlet>

</app-l-default>
