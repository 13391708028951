<div class="count-down-timer" [ngClass]="{'expired': expired}">
  <div class="wrapper">
    <i class="webfont webfont-timer"></i>
    <div class="times">
      <div class="time days">
        <span class="value" #days>--</span>
        <span class="label">Jours</span>
      </div>
      <div class="time hours">
        <span class="value" #hours>--</span>
        <span class="label">Heures</span>
      </div>
      <div class="time minutes">
        <span class="value" #minutes>--</span>
        <span class="label">Minutes</span>
      </div>
      <div class="time seconds">
        <span class="value" #seconds>--</span>
        <span class="label">Secondes</span>
      </div>
    </div>
    <div class="expired" *ngIf="expired">
      <span>Vente terminée</span>
    </div>
  </div>
</div>
