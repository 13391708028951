import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import TicketForm from 'src/app/models/ticket-form.model';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-m-form-ticket-accordion-item',
  templateUrl: './m-form-ticket-accordion-item.component.html',
  styleUrls: ['./m-form-ticket-accordion-item.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ maxHeight: '0px', opacity: 0 }),
        animate('500ms ease-in', style({ maxHeight: '300px', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ maxHeight: '0px', opacity: 0 }))
      ])
    ])
  ]
})
export class MFormTicketAccordionItemComponent implements OnInit, OnDestroy {

  @Input() expended = false
  @Input() ticketCategory: string;
  @Input() ticketName: string;
  @Input() seatInfos: Array<string>;
  @Input() canExpand = true;
  @Input() errorsNum: number;
  @Input() expendedSubscription: Subject<void>;
  private subscription = new Subscription();
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.expendedSubscription.subscribe(() => {
      if (this.errorsNum) {
        this.expended = true;
      }
    })
  }

}
