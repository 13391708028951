import checkField from './check-field';
import Event from './event.model';
import Organisation from './organisation.model';

export default class Widget {
    _id: string;
    langCodes: string[];
    defaultLangCode: string;
    path: string;
    customStyles: {
        background?: string,
        title?: string,
        text?: string,
        link?: string,
        font?: string
    };
    tracking?: {
        gtm?: string,
        pixelMeta?: string,
        ga?: string
    };
    hasInsuranceOption?: boolean
    eventId: Event;
    trackingScript: string;
    forProduct: boolean;
    askZone: boolean;
    askAddress: boolean;
    isRegistration: boolean
    ticketTypes: {
      restrict: boolean,
      ids: string[]
    };
    organisationId: Organisation

    constructor(_widget?: any) {
        if (checkField(_widget?._id)) {
            this._id = _widget._id;
        }
        if (checkField(_widget?.langCodes)) {
            this.langCodes = _widget.langCodes;
        }
        if (checkField(_widget?.defaultLangCode)) {
            this.defaultLangCode = _widget.defaultLangCode;
        }
        if (checkField(_widget?.path)) {
            this.path = _widget.path;
        }
        if (checkField(_widget?.customStyles)) {
            this.customStyles = _widget.customStyles;
        }
        if (checkField(_widget?.tracking)) {
            this.tracking = _widget.tracking;
        }
        if (checkField(_widget?.eventId)) {
            this.eventId = _widget.eventId;
        }
        if (checkField(_widget?.ticketTypes)) {
            this.ticketTypes = _widget.ticketTypes;
        }
        if (checkField(_widget?.trackingScript)) {
            this.trackingScript = _widget.trackingScript;
        }
        if (checkField(_widget?.isRegistration)) {
            this.isRegistration = _widget.isRegistration;
        }
        if (checkField(_widget?.forProduct)) {
            this.forProduct = _widget.forProduct;
        }
        if (checkField(_widget?.askZone)) {
            this.askZone = _widget.askZone;
        }
        if (checkField(_widget?.askAddress)) {
            this.askAddress = _widget.askAddress;
        }
        if (checkField(_widget?.organisationId)) {
            this.organisationId = _widget.organisationId
        }
        this.hasInsuranceOption = false
        if (_widget?.hasInsuranceOption === undefined || _widget?.hasInsuranceOption === true) {
            this.hasInsuranceOption = true
        }
    }

}
