import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-a-form-input-field',
  templateUrl: './a-form-input-field.component.html',
  styleUrls: ['./a-form-input-field.component.scss']
})
export class AFormInputFieldComponent {

  @Input() label: string;
  @Input() id: string;
  @Input() description: string;
  @Input() value: string;
  @Input() required: boolean;
  @Input() validate: string;
  @Input() type = 'text';
  @Input() autocomplete: string;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  @Input() creditCardType: string;
  @Input() constraintsInfos: string;

  @Output() InputChange = new EventEmitter<string>();

  constructor() { }

  onChange(): void {
    this.InputChange.emit(this.value);
  }

}
