import Requester from '../requester';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import TicketType from 'src/app/models/ticket-type.model';
import { QueryParams } from 'src/app/etc/queryParams';
import { Observable } from 'rxjs';
import { RequestResponseList } from 'src/app/models/type.definition';

@Injectable()
export class TicketTypeService extends Requester {

	constructor(
		protected http: HttpClient,
		protected queryParam: QueryParams
	) {
		super('events/ticket-types', TicketType, http, queryParam);
	}

	public getList(_body = null): Observable<RequestResponseList> {
		return super.getList(_body);
	}

	public getById(_id, _body = null): Observable<any> {
		return super.getById(_id, _body);
	}
}
