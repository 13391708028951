import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-m-banner-seat-selection',
  templateUrl: './m-banner-seat-selection.component.html',
  styleUrls: ['./m-banner-seat-selection.component.scss']
})
export class MBannerSeatSelectionComponent implements OnInit {
  widgetId: string;
  constructor(
    public route: ActivatedRoute,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(_params => {
      this.widgetId = _params.id;
    });
  }

  goToSeatSelectionPage(): void {
    this.router.navigate([`${this.widgetId}/seat-selection`]);
  }

}
