import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { QueryParams } from 'src/app/etc/queryParams';
import { Observable } from 'rxjs';
import RequestBody from 'src/app/models/request-body.model';
import { RequestResponseList } from 'src/app/models/type.definition';

export default class Requester {
	urlKey: string;
	model: any;

	constructor(
		protected _urlKey: string,
		protected _model: any,
		protected httpClient: HttpClient,
		protected queryTranslator: QueryParams,
	) {
		this.urlKey = _urlKey;
		this.model = _model;
	}

	protected getList(_body = null): Observable<RequestResponseList> {
		const body = new RequestBody(_body);
		const url = `${environment.apiUrl}/v1/${this.urlKey}/list${this.queryTranslator.translate(body)}`;
		return this.httpClient.get<RequestResponseList>(url).pipe(
			map((_response) => {
				if (_response) {
					_response.data = _response.data.map(_ => new this.model(_));
				}
				return _response;
			})
		);
	}

	protected create(_body: any): Observable<any> {
		const url = `${environment.apiUrl}/v1/${this.urlKey}`;
		return this.httpClient.post<{ data: any, success: boolean, msg: string }>(url, _body);
	}

	protected getById(_id, _body = null): Observable<any> {
		const body = new RequestBody(_body);
		const url = `${environment.apiUrl}/v1/${this.urlKey}/${_id}${this.queryTranslator.translate(body)}`;
		return this.httpClient.get<{ data: any, success: boolean, msg: string }>(url).pipe(
			map((_response) => {
				return new this.model(_response);
			})
		);
	}

}
