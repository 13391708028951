import { Component } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { FormControl, Validators  } from '@angular/forms';
import { Router, Params, ActivatedRoute } from '@angular/router';
import FormField from 'src/app/models/form-field.model';

import { GlobalStateModel, GlobalState } from 'src/app/store/states/global.state';
import { Cart, GlobalStateObject } from 'src/app/models/type.definition';
import { Select } from '@ngxs/store';

import { HttpClient } from '@angular/common/http';

import Order from 'src/app/models/order.model';
import { TimeLineService, OrderService, WalletService } from 'src/app/providers';
import OrderTicketForm from 'src/app/models/order-ticket-form.model';
import TicketType from 'src/app/models/ticket-type.model';
import Widget from 'src/app/models/widget.model';
import TicketForm from 'src/app/models/ticket-form.model';
import { count } from 'console';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.html',
  styleUrls: ['./order-summary.scss']
})

export class OrderSummaryPage {
  public emailsForm:any[];

  subscriptions = new Subscription();
  widgetId: string;

  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;
  globalObject: GlobalStateObject;

  public emailsList:any[];
  order: Order;
  orderTicketForms:OrderTicketForm[];
  widget:Widget;
  billingForm: TicketForm;
  public tickets: TicketType[];
  cart: Cart;

  emailField: FormField;

  constructor(
    private timeLineService: TimeLineService,
    protected route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private orderService: OrderService,
    private walletService: WalletService,
  ) {
    this.emailsList = [];
    this.emailsForm = [];
    this.emailField = new FormField({ label: 'Email', isRequired: true, type: 'email' });
  }

  ngOnInit(): void {

    this.timeLineService.updateTimeLine('order-summary');

    this.subscriptions.add(this.route.params.subscribe((_params: Params) => {
      this.widgetId = _params.id;
    }));

    this.subscriptions.add(this.globalState$.subscribe(async (state: GlobalStateModel) => {
      this.globalObject = state.globalObject;
      this.order = this.globalObject.order;
      this.cart = this.globalObject.cart;
      this.orderTicketForms = this.globalObject.orderTicketForms;
      this.widget = this.globalObject.widget;
      this.billingForm = this.globalObject.billingForm;
      this.tickets = this.cart.tickets;

      if (state.globalObject.isNft) {
        this.order.isNft = true;
      }

      let emailFound = false;

      if (this.orderTicketForms) {
        for (const formFields of this.orderTicketForms) {
          if(!formFields.fields) {
            continue;
          }
          const emails = formFields.fields.filter(formField => formField.type == "email").map(formField => {return {value: formField.value, exists: false}} );

          for (const email of emails) {
            this.checkUserExists(email, (res) => {
              if(res) email.exists = true;
              this.emailsList.push(email);
              this.emailsForm.push(new FormControl(email.value,
              [
                Validators.required,
                Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
              ]));
              this.emailsForm[this.emailsForm.length - 1].disable();
              if(this.emailsForm.length == this.orderTicketForms.length) {
                this.saveEmails();
              }
            });
            emailFound = true;
          }
        }
      }

      /* L'utilisateur n'a pas rentré de mails pour chaque ticket, on utilise donc le mail de la facturation */
      if (!emailFound) {
        for (const formFields of this.orderTicketForms) {
          if(!formFields.fields) {
            continue;
          }
          const emails = this.globalObject.billingForm.fields.filter(item => item.type == "email").map(formField => {return {value: formField.value, exists: false}} );
          this.checkUserExists(emails[0], (res) => {
            if(res) emails[0].exists = true;
            this.emailsList.push(emails[0]);
            this.emailsForm.push(new FormControl(emails[0].value,
            [
              Validators.required,
              Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
            ]));
            this.emailsForm[this.emailsForm.length - 1].disable();
            if(this.emailsForm.length == this.orderTicketForms.length) {
              this.saveEmails();
            }
          });
        }

      }
    }));
  }

  enableField(_index) {
    this.emailsForm[_index].enable();
  }

  checkIfValidate(_field: FormField): string {
    switch (_field.isValid) {
      case true: return 'success';
      case false: return 'error';
    }
  }

  validateEmail(_index) {
    if(this.emailsForm[_index].invalid && this.emailsForm[_index].touched) {
      alert('Invalid email');
      return;
    }
    this.saveEmails();
    this.checkUserExists(this.emailsForm[_index], (res) => {
      if(res) this.emailsList[_index].exists = true;
      else this.emailsList[_index].exists = false;
      this.emailsForm[_index].disable();
    });
  }

  checkUserExists(_email, _callback) {
    this.walletService.userExists(_email.value).subscribe(_callback);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  async saveEmails() {
    let body = {
      order: this.order,
      emails: [],
      tickets: this.cart.tickets
    };

    for(let i = 0; i < this.emailsForm.length; ++i) {
      body.emails.push(this.emailsForm[i].value);
    }

    /* Dans le cas où tous les tickets sont envoyés à la même personne, on insert le mail de facturation pour chaque ticket */
    if(body.tickets.length != body.emails.length && body.emails.length) {
      for(var y = 1; y < body.tickets.length; ++y) {
        body.emails.push(body.emails[0]);
      }
    }

    this.subscriptions.add(this.orderService.updateDraftNft(this.order.token, body).subscribe((res: any) => {

    }));
  }
  proceed(): void {
    this.nextStep();
  }

  async nextStep(): Promise<void> {
    window.parent.postMessage({
      changeStep: true
    }, '*');
    const pathList = [
      'tickets-list',
      'contact-information',
      'order-summary',
      'summary',
      'confirmation/order',
      'registration'
    ];
    /* Mettre à jour les données */
    let body = {
      order: this.order,
      emails: [],
      tickets: this.cart.tickets
    };

    for(let i = 0; i < this.emailsForm.length; ++i) {
      body.emails.push(this.emailsForm[i].value);
    }

    /* Dans le cas où tous les tickets sont envoyés à la même personne, on insert le mail de facturation pour chaque ticket */
    if(body.tickets.length != body.emails.length && body.emails.length) {
      for(var y = 1; y < body.tickets.length; ++y) {
        body.emails.push(body.emails[0]);
      }
    }

    this.subscriptions.add(this.orderService.updateDraftNft(this.order.token, body).subscribe((res: any) => {
      this.router.navigate([`${this.widgetId}/${pathList[3]}`]);
    }));

  }

}
