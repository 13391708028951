import Requester from '../requester';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from 'src/app/etc/queryParams';
import Event from 'src/app/models/event.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class EventService extends Requester {

	constructor(
		protected http: HttpClient,
		protected queryParam: QueryParams
	) {
		super('events', Event, http, queryParam);
	}

	bufferStock(_id: string): Observable<{id: string, msg: string}> {
		const url = `${environment.apiUrl}/v1/${this.urlKey}/buffer-stock/${_id}`;
		return this.httpClient.get<{id: string, msg: string}>(url);
	}

	waitingList(_body): Observable<{id: string, msg: string}> {
		const url = `${environment.apiUrl}/v1/${this.urlKey}/waiting-list`;
		return this.httpClient.post<{id: string, msg: string}>(url, _body);
	}

	public async getBookType(id: string): Promise<'registration' | 'ticketing'> {
		const result = await this.getById(id, { select: ['bookType'] }).toPromise();
		return result.bookType;
	}

}
