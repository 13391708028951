import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MediaService } from "src/app/providers";


@Injectable()
export class FilesHandler {

  mediaCreated: string[] = [];

  constructor(private http: HttpClient, private mediaService: MediaService) { }

  async checkForUploads(value: any, token: string, index: number): Promise<string> {
    if (value?.size) {
      return await this.uploadMedia({
        size: `${value.size}`,
        name: value.name,
        mimetype: value.type,
        type: 'ticketId',
        token,
        upl: value,
        index,
        private: false
      })
    }
    else {
      return value
    }
  }

  async uploadMedia(file: any): Promise<string> {
    const formData = this.createFormData(file);
    const res = await this.mediaService.upload(formData).toPromise();
    return res.id
  }

  createFormData(form: any): FormData {
    const formData = new FormData();
    for (const key in form) {
      if (![undefined, ''].includes(form[key])) {
        if (Array.isArray(form[key])) {
          for (const element of form[key]) {
            formData.append(key + (key !== 'upl' ? '[]' : ''), element);
          }
        }
        else {
          formData.append(key, form[key]);
        }
      }
    }
    return formData;
  }
}