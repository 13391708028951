import checkField from './check-field';
import Event from './event.model';
import Tax from './tax.model';
import TicketType from './ticket-type.model';
import User from './user.model';

export default class Session {
    _id: string;
    name: string;
    date: Date;
    eventId: Event;
    isTicketTypeRestricted?: boolean;
    ticketTypeIds?: TicketType[]
    taxId: Tax;
    quota: number;
    stock: number;
    authorId: User;
    updatedAt: Date;
    createdAt: Date;

    constructor(_sessions?: any) {
        if (checkField(_sessions._id)) {
            this._id = _sessions._id;
        }
        if (checkField(_sessions.name)) {
            this.name = _sessions.name;
        }
        if (checkField(_sessions.date)) {
            this.date = _sessions.date;
        }
        if (checkField(_sessions.eventId)) {
            this.eventId = _sessions.eventId;
        }
        if (checkField(_sessions.taxId)) {
            this.taxId = _sessions.taxId;
        }
        if (checkField(_sessions.authorId)) {
            this.authorId = _sessions.authorId;
        }
        if (checkField(_sessions.updatedAt)) {
            this.updatedAt = _sessions.updatedAt;
        }
        if (checkField(_sessions.createdAt)) {
            this.createdAt = _sessions.createdAt;
        }
        if (checkField(_sessions.isTicketTypeRestricted)) {
            this.isTicketTypeRestricted = _sessions.isTicketTypeRestricted;
        }
        if (checkField(_sessions.ticketTypeIds)) {
            this.ticketTypeIds = _sessions.ticketTypeIds;
        }
        this.stock = _sessions.stock;
        this.quota = _sessions.quota;
    }

}