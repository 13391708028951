<app-t-content-w-sidebar type="medium_sidebar" *ngIf="globalObject">

  <!-- ///////////////////////////// -->
  <!-- ////////// PAYMENT ///////// -->
  <!-- /////////////////////////// -->

  <div slotContent>

    <app-a-title-main [text]='title'></app-a-title-main>

    <app-o-summary-table>
      <table>
        <thead>
          <tr>
            <th>{{"Intitulé" | customTranslation: "finalTitle"}}</th>
            <th>{{"Prix" | customTranslation: "finalPrice"}}</th>
            <th>{{"Qté" | customTranslation: "finalQty"}}</th>
            <th>{{"Total" | customTranslation: "finalTotal"}}</th>
          </tr>
        </thead>
        <tbody *ngIf="tickets">
          <ng-container *ngFor="let ticket of tickets">
            <tr class="ticket" [ngClass]="{'has--options' : hasAddOn(ticket)}">
              <td>
                <div class="inner">{{getTicketName(ticket)}}</div>
                <!-- <div class="seat">
                  <span>Accès : J </span>
                  <span>Rang : Q</span>
                  <span>Siège : 158</span>
                </div> -->
              </td>
              <td>
                <div class="inner">{{ticket.price | currency:currency.isoCode}}</div>
              </td>
              <td>
                <div class="inner">{{ticket.quantity}} </div>
              </td>
              <td>
                <div class="inner">{{ticket.price*ticket.quantity | currency:currency.isoCode}}</div>
              </td>
            </tr>
            <ng-container *ngFor="let addOn of ticket.addons">
              <tr *ngIf="addOn.quantity" class="option">
                <td>
                  <div class="inner">{{addOn.name}}</div>
                </td>
                <td>
                  <div class="inner">{{addOn.price | currency:currency.isoCode}}</div>
                </td>
                <td>
                  <div class="inner">{{addOn.quantity}}</div>
                </td>
                <td>
                  <div class="inner">{{addOn.price*addOn.quantity | currency:currency.isoCode}}</div>
                </td>
              </tr>
            </ng-container>
          </ng-container>




          <tr *ngFor="let addOn of addons">
            <td>
              <div class="inner">{{addOn.name}}</div>
            </td>
            <td>
              <div class="inner">{{addOn.price | currency:currency.isoCode}}</div>
            </td>
            <td>
              <div class="inner"></div>
            </td>
            <td>
              <div class="inner">{{addOn.price*nbTickets | currency:currency.isoCode}}</div>
            </td>
          </tr>



          <tr *ngIf="promoCode">
            <td>
              <div class="inner">{{"Code promo" | customTranslation: "finalPromoCode"}}
                <span class="description">

                  <ng-container [ngSwitch]="promoCode.reduction.type">
                    <ng-container *ngSwitchCase="percentage">-{{promoCode.totalReduction}}%</ng-container>
                    <ng-container *ngSwitchDefault>-{{promoCode.totalReduction | currency: currency.isoCode}}
                    </ng-container>
                  </ng-container> {{"sur le tarif normal" | customTranslation: "onNormalRate"}}
                </span>
              </div>
            </td>
            <td>
              <div class="inner">-{{promoCode.totalReduction | currency: currency.isoCode}}</div>
            </td>
            <td>
              <div class="inner"></div>
            </td>
            <td>
              <div class="inner">-{{promoCode.totalReduction | currency: currency.isoCode}}</div>
            </td>
          </tr>

          <tr *ngIf="globalObject.insurance">
            <td>
              <div class="inner">{{"Assurance" | customTranslation: "service"}}
              </div>
            </td>
            <td>
              <div class="inner"></div>
            </td>
            <td>
              <div class="inner"></div>
            </td>
            <td>
              <div class="inner">{{globalObject.insurance.formatted}}</div>
            </td>
          </tr>

          <tr *ngIf="charges">
            <td>
              <div class="inner">{{"Frais de service" | customTranslation: "service"}}
              </div>
            </td>
            <td>
              <div class="inner"></div>
            </td>
            <td>
              <div class="inner"></div>
            </td>
            <td>
              <div class="inner">{{charges | currency: currency.isoCode}}</div>
            </td>
          </tr>

        </tbody>
        <tfoot>
          <tr>
            <td colspan="2">{{"Total" | customTranslation: "finalTotal"}} ({{"TTC" | customTranslation: "vatIncluded"}})
            </td>
            <td colspan="2">{{totalToPay | currency:currency.isoCode}}</td>
          </tr>
        </tfoot>
      </table>
    </app-o-summary-table>

  </div>

  <!-- ///////////////////////////// -->
  <!-- ////////// PAYMENT ///////// -->
  <!-- /////////////////////////// -->

  <app-o-payment-box slotSidebar (tokenValid)="validateOrder($event)" [order]="globalObject.order"></app-o-payment-box>



</app-t-content-w-sidebar>