import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() {}

  create(file) {
    if (file) {
        return new Promise(resolve => {
          const reader = new FileReader();
          reader.onload = () => resolve(file);
          reader.readAsDataURL(file);
        });
    }
  }
}

