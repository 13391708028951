import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-t-content-w-sidebar',
  templateUrl: './t-content-w-sidebar.component.html',
  styleUrls: ['./t-content-w-sidebar.component.scss']
})
export class TContentWSidebarComponent implements OnInit {
  @Input() type: string;
  constructor() { }

  ngOnInit(): void {
  }

}
