import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { clearTimeout } from 'timers';

@Component({
  selector: 'app-a-button',
  templateUrl: './a-button.component.html',
  styleUrls: ['./a-button.component.scss']
})
export class AButtonComponent implements OnInit {
  @Input() text: string;
  @Input() hover: string;
  @Input() clickTooltip: string;
  @Input() type = 'default';
  @Input() isDisabled: boolean;
  @Input() isLoading: boolean;
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter();
  displayHover = false;
  displayTooltip = false;
  tooltipTimeout = null;
  hoverTop: number;
  hoverLeft: number;

  constructor() { }

  ngOnInit(): void { }

  clickButton(): void {
    this.buttonClicked.emit();
  }

  setMouseClick(): void {
    if (this.clickTooltip) {
      if (this.tooltipTimeout !== null) {
        window.clearTimeout(this.tooltipTimeout)
      }
      this.displayTooltip = true
      this.tooltipTimeout = window.setTimeout(() => {
        this.displayTooltip = false
      }, 5500);
    }
  }

  setMouseHover(value: boolean): void {
    if (this.hover) {
      this.displayHover = value;
    }
  }

}
