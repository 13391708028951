<div class="o-summary">
    <header>
        <h2>{{"Récapitulatif" | customTranslation: "summary"}}</h2>
    </header>

    <ul class="list--summary">
        <li>
            <div class="price-line">
                <p class="label">{{nbTickets}}
                    <ng-container *ngIf="!isRegistration">
                        <ng-container>
                            {{(globalObject.widgetId.forProduct ? 'produit' : 'ticket') | customTranslation}}{{nbTickets > 1 ? 's' : ''}}
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="isRegistration">{{'inscription' | customTranslation}}{{nbTickets > 1 ? 's' : ''}}</ng-container>

                    </p>
                <p class="price">
                    {{ allFree ? "" : (ticketsTotal | currency:currency.isoCode)}}
                </p>
            </div>
            <div class="tickets-options" *ngIf="ticketsOptionsPrice">
                <span>{{"Options" | customTranslation}}</span>
                <span>
                    {{ticketsOptionsPrice | currency:currency.isoCode}}
                </span>
            </div>
        </li>
        <li *ngFor="let addOn of addons">
            <div class="price-line">
                <p class="label">{{ addOn.name }}</p>
                <p class="price">
                    {{ addOn.price*nbTickets | currency:currency.isoCode}}
                </p>
            </div>
        </li>
        <li *ngIf="promoCode">
            <div class="price-line promo-code">
                <p class="label">{{"Code Promo" | customTranslation: 'promoCode'}} : {{ promoCode.code }}</p>
                <p class="price" *ngIf="promoReduct !== null">
                    -{{ promoReduct | currency:currency.isoCode}}
                </p>
            </div>
        </li>
    </ul>
    <footer>
        <div *ngIf="insurancePrice" class="price-line service_charges">
            <p class="label">{{"Assurance" | customTranslation: 'service'}}</p>
            <p class="price">{{ insurancePrice.formatted }}</p>
        </div>
        <div *ngIf="charges" class="price-line service_charges">
            <p class="label">{{"Frais de service" | customTranslation: 'service'}}</p>
            <p class="price">{{ charges | currency:currency.isoCode}}</p>
        </div>
        <p class="total">
            <small>{{"Total" | customTranslation}}</small>
            <strong>{{allFree ? ("Gratuit" | customTranslation: 'free') : (total | currency:currency.isoCode)}}</strong>
        </p>

        <app-a-button [clickTooltip]="clickTooltipText" [hover]="hoverText" [text]="textButton" [isDisabled]="!nbTickets  || buttonIsDisabled"
            (buttonClicked)='nextStep()' [isLoading]="loading">
        </app-a-button>

        <app-m-coupon-add *ngIf="showCouponForm"></app-m-coupon-add>

        <p *ngIf="!allFree" class="description">
            {{"Les prix affichés sont toutes taxes comprises" | customTranslation: "infoPriceDisplayed"}}.
        </p>
    </footer>
</div>