import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-a-form-checkbox-radio-field',
  templateUrl: './a-form-checkbox-radio-field.component.html',
  styleUrls: ['./a-form-checkbox-radio-field.component.scss']
})
export class AFormCheckboxRadioFieldComponent implements OnInit {
  @Input() label: string;
  @Input() type: string;
  @Input() value: any;
  @Input() description: string;
  @Input() name: string;
  @Input() checked: boolean;
  @Input() id: number;
  @Input() validate: boolean;
  @Input() required: boolean;
  @Output() checkedChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void { }

  onSelectionChange(checked: boolean, value: string | any): void {
    // if (this.type === 'checkbox') {
    //   this.value.checked = !this.value.checked;
    // }
    this.checkedChange.emit(value);
  }

}
