import { translateString } from '../etc/custom-translation/custom-translation.pipe';
import FormField from './form-field.model';


export default class BillingForm {
    missingFields: number;
    civility: FormField;
    companyName: FormField;
    emailAddress: FormField;
    lastName: FormField;
    firstName: FormField;
    postalAddress: FormField;
    additionalPostalAddress: FormField;
    zipCode: FormField;
    city: FormField;
    acceptTerms: FormField;

    constructor() {
        const civilities = [{ label: translateString('Madame', "ms"), value: 'mme' }, { label: translateString('Monsieur', "mr"), value: 'mr' }];
        this.civility =  this.createFormField('civility', 'Civilité', 'radio', civilities);
        this.civility.isValid = null;
        this.emailAddress = this.createFormField('emailAddress', 'Adresse e-mail', 'email');
        this.lastName = this.createFormField('lastName', 'Nom');
        this.firstName = this.createFormField('firstName', 'Prénom');
        const terms = `J'accepte que les coordonnées  renseignées soient utilisées pour éditer le(s)
        ticket(s) et la facture et permettent de me recontacter si nécessaire. <a target="_blank" href='/'>En savoir plus</a>`
        this.acceptTerms = this.createFormField('acceptTerms', terms, 'checkbox', [{value: 'terms', checked: false}]);
        this.missingFields = 5;
    }

    createFormField(_key, _label, _type = 'text', _options = null) {
        const newFormField = {
            label: _label,
            isRequired: true,
            type: _type,
            options: _options,
            value: null,
        }
        return new FormField(newFormField);
    }

    isValid(): boolean {
        return !this.missingFields;
    }

}
