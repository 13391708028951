import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-a-quantity-selector',
  templateUrl: './a-quantity-selector.component.html',
  styleUrls: ['./a-quantity-selector.component.scss']
})
export class AQuantitySelectorComponent {
  @Input() quantity: number;
  @Input() quantityMin = 0;
  @Input() quantityMax: number;
  @Input() limitString: string;
  minusDisabled: boolean;
  plusDisabled: boolean;
  tooltipDisplayed: boolean;
  @Output() quantityUpdate: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  isPlusDisabled() {
    this.plusDisabled = this.quantityMax == 0;
    return this.plusDisabled;
  }

  isMinusDisabled() {
    this.minusDisabled = this.quantity === this.quantityMin;
    return this.minusDisabled;
  }

  increment(): void {
    if (!this.plusDisabled) {
      this.quantityUpdate.emit(this.quantity + 1);
    }
  }
  decrement(): void {
    if (!this.minusDisabled) {
      this.quantityUpdate.emit(this.quantity - 1);
    }
  }

  displayTooltip() {
    this.tooltipDisplayed = true;
  }

  hideTooltip() {
    this.tooltipDisplayed = false;
  }
}
