import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Select } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { GlobalState, GlobalStateModel } from 'src/app/store/states/global.state';
import { SetBookingTime, SetKeysGlobal, SetPromoCode } from 'src/app/store/actions/global.actions';
import TicketType from 'src/app/models/ticket-type.model';
import AddOn from 'src/app/models/add-on.model';
import { CurrencyService, OrderService, PopInService, PromiserService, TicketService } from 'src/app/providers';
import PromoCode from 'src/app/models/promo-code.model';
import Order, { TicketOrder } from 'src/app/models/order.model';
import OrderTicketForm from 'src/app/models/order-ticket-form.model';
import TicketForm from 'src/app/models/ticket-form.model';
import { translateString } from 'src/app/etc/custom-translation/custom-translation.pipe';
import { FilesHandler } from 'src/app/etc/files-handler';
import Widget from 'src/app/models/widget.model';
import Currency from 'src/app/models/currency.model';
import { AppPrice, GlobalStateObject } from 'src/app/models/type.definition';
import Event from 'src/app/models/event.model';

@Component({
  selector: 'app-o-summary',
  templateUrl: './o-summary.component.html',
  styleUrls: ['./o-summary.component.scss']
})
export class OSummaryComponent implements OnInit, OnDestroy {

  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;
  @Input() buttonIsDisabled: boolean;
  @Input() billingForm: TicketForm;
  @Input() orderTicketForms: OrderTicketForm[];
  @Input() ticketsToOrder: any;
  public allFree: boolean;
  public hasPromoCode: boolean
  public isRegistration: boolean;
  public showCouponForm: boolean = false;
  widgetId: Widget;
  eventId: Event;
  sessionId: string;
  nbTickets: number;
  ticketsTotal: number;
  total: number;
  ticketsOptionsPrice: number;
  addons: AddOn[];
  tickets: TicketType[];
  subscriptions = new Subscription();
  textButton: string;
  hoverText: string;
  clickTooltipText: string;
  order: Order;
  orderError: string;
  currentPage: string;
  promoCode: PromoCode;
  promoCodeQty: number;
  insurancePrice: AppPrice | null
  public charges: number;
  promoReduct: number;
  currency: Currency;
  globalObject: GlobalStateObject;
  loading: boolean = false;
  textButtons: { [key: string]: string } = {
    'tickets-list': translateString('Acheter', 'purchase'),
    'insurance': translateString('Procéder au paiement', 'infoPurchase'),
    'contact-information': translateString('Procéder au paiement', 'infoPurchase'),
    'order-summary': translateString('Procéder au paiement', 'infoPurchase'),
    'summary': translateString('Payer', 'finalPay')
  };
  clickTooltipTexts: { [key: string]: string } = {
    'tickets-list':translateString('Veuillez sélectionner un ou plusieurs tickets', "noTickets")
  };
  hoverTexts: { [key: string]: string } = {
    'contact-information': translateString('Veuillez compléter les champs manquants', "incompleteForms")
  };

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private orderService: OrderService,
    private ticketService: TicketService,
    private promiser: PromiserService,
    private filesHandler: FilesHandler,
    private store: Store,
    private currencyService: CurrencyService,
    private popInService: PopInService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.currencyService.currency$.subscribe(res => {
      this.currency = res;
    }))
    if (this.isRegistration) {
      this.clickTooltipTexts['tickets-list'] = translateString('Veuillez indiquer le nombre de personnes à inscrire')
    }
    this.currentPage = this.router.url.split('/')[2];
    this.hoverText = this.hoverTexts[this.currentPage];
    this.clickTooltipText = this.clickTooltipTexts[this.currentPage];
    this.subscriptions.add(this.route.params.subscribe((_params: Params) => {
      this.widgetId = _params.id;
    }));
    this.subscriptions.add(this.globalState$.subscribe((state: GlobalStateModel) => {
      this.globalObject = state.globalObject;
      this.widgetId = this.globalObject.widget;
      this.insurancePrice = this.globalObject.insurance;
      this.isRegistration = this.globalObject.isRegistration;
      this.allFree = this.globalObject.allFree;
      this.hasPromoCode = this.globalObject.cart.promoCode !== null;
      this.showCouponForm = ((this.allFree && this.hasPromoCode) || !this.allFree) && this.currentPage == 'tickets-list';
      if (this.allFree) {
        this.textButtons['tickets-list'] = translateString("S'inscrire", 'register');
        this.textButtons['contact-information'] = translateString("S'inscrire", 'register');
      }
      else {
        this.textButtons['tickets-list'] = translateString('Acheter', 'purchase');
        this.textButtons['contact-information'] = translateString('Procéder au paiement', 'infoPurchase');
      }
      if (this.globalObject.isNft) {
        this.textButtons['contact-information'] = translateString('Continuer', 'continue');
      }
      if (this.widgetId.hasInsuranceOption) {
        this.textButtons["contact-information"] = translateString('Continuer', 'infoPurchase');
      }
      this.textButton = this.textButtons[this.currentPage];
      this.ticketsOptionsPrice = 0;
      this.eventId = this.globalObject.eventId;
      this.sessionId = this.globalObject.session?._id;

      this.order = this.globalObject.order;
      const cart = this.globalObject.cart;
      this.promoCode = cart.promoCode;
      this.promoCodeQty = cart.promoCodeQty;
      this.addons = cart.addons;
      if (cart.tickets) {
        this.tickets = cart.tickets;
        this.nbTickets = cart.tickets.length;
        this.ticketsTotal = cart.tickets.length > 0 ? this.calcPrice(cart.tickets) : 0;
        cart.tickets.map(_ticket => {
          _ticket.addons.map(_addOn => {
            if (_addOn.isChecked) {
              this.ticketsOptionsPrice += _addOn.price;
            }
          });
        });
        this.total = this.getTotalCost();
      }
    }));
  }

  calcPrice = (_tickets: TicketType[]) => {
    return _tickets.map(_ => _.price).reduce((a: any, c: any) => a + c)
  };

  getTotalCost(): number {
    let total = 0;
    total += this.ticketsTotal;
    total += this.ticketsOptionsPrice;
    this.addons.forEach(_ => total += _.price * this.nbTickets);
    this.promoReduct = this.calcPromoReduc(this.ticketsTotal);
    total -= this.promoReduct || 0;
    if (total < 0) {
      total = 0;
    }
    this.charges = 0;
    if (total) {
      if (this.widgetId.eventId.commissionIncreased) {
        this.charges += this.widgetId.eventId.commission.percentage * total / 100
        this.charges += (this.widgetId.eventId.commission.flat / 100) * this.getNonFreeTicketsLength();
      }
      total += this.charges
    }
    if (this.insurancePrice) {
      total += this.insurancePrice.floated;
    }

    if (this.isRegistration) {
      if (this.allFree !== !total) {
        this.store.dispatch(new SetKeysGlobal({ value: !total, key: 'allFree' }))
      }
    }
    const totalIsZero = (!total)
    if (this.globalObject.totalIsZero !== totalIsZero) {
      this.store.dispatch(new SetKeysGlobal({ value: totalIsZero, key: 'totalIsZero' }))
    }

    return total;
  }

  getNonFreeTicketsLength(): number {
    return this.tickets.filter(ticket => {
      return ticket.price || ticket.addons.filter(addon => addon.isChecked && addon.price).length
    }).length
  }

  calcPromoReduc(_total: number): number {

    if (this.promoCode) {

      const ticketTypesIds: string[] = this.promoCode.constraintTicketTypesIds.map(_ => _._id);
      let concernedTickets: TicketType[] = this.tickets.filter(_ => _.price > 0);

      if (ticketTypesIds?.length) {
        concernedTickets = concernedTickets.filter(_ => ticketTypesIds.includes(_._id));
      }

      if (this.promoCode.counterScope === "ticket" &&  concernedTickets.length > this.promoCode.available) {
        concernedTickets = concernedTickets.slice(0, this.promoCode.available);
      }

      const total = concernedTickets.length ? this.calcPrice(concernedTickets) : 0;
      const totalReduction = this.getReduction(total, this.promoCode.reduction, concernedTickets);

      if (this.promoCode.totalReduction !== totalReduction) {
        this.promoCode.totalReduction = totalReduction;
        this.store.dispatch(new SetPromoCode({ code: this.promoCode, qty: concernedTickets.length }));
      }
      return totalReduction
    }
  };

  getReduction(total: number, reduction: any, concernedTickets: TicketType[]) {
    let calculatedReduction = 0;
    for (const ticket of concernedTickets) {
      if (reduction.type =="flat") {
        calculatedReduction += (ticket.price < reduction.amount) ? ticket.price : reduction.amount;
      }
      else if (reduction.type == "percentage") {
        calculatedReduction += (ticket.price * reduction.amount) / 100;
      }
      else if (reduction.type == "free") {
        calculatedReduction += ticket.price;
      }
    }

    return (calculatedReduction > total) ? total : calculatedReduction;
  }

  addCoupon(): void {

  }

  async nextStep(): Promise<void> {
    window.parent.postMessage({
      changeStep: true
    }, '*');
    if (this.widgetId !== undefined) {
      this.loading = true;
      let pathList;
      if(this.globalObject && this.globalObject.isNft) {
        pathList = [
          'tickets-list',
          'contact-information',
          'order-summary',
          'summary',
          'confirmation/order',
          'registration'
        ];
      }
      else {
        pathList = [
          'tickets-list',
          'contact-information',
          'summary',
          'confirmation/order',
          'registration'
        ];
        if (this.globalObject.hasInsurance && !this.globalObject.totalIsZero) {
          pathList.splice(2, 0, 'insurance')
        }
      }

      if (this.allFree) pathList.splice(2, 1);
      for (const [i, pathValue] of pathList.entries()) {
        const nextPath = pathList[+i + 1];
        if (this.router.url.includes(pathValue) && typeof nextPath !== 'undefined') {
          if (pathValue == 'insurance') {
            if (this.globalObject.insurance) {
              this.subscriptions.add(this.orderService.updateDraft(this.order.token, {
                insurance: {
                  type: "neat",
                  amount: this.globalObject.insurance.amount
                }
              }).subscribe((res: any) => {
                this.router.navigate([`${this.widgetId._id}/${nextPath}`]);
                this.loading = false;
              }))
            }
            else {
              this.router.navigate([`${this.widgetId._id}/${nextPath}`]);
              this.loading = false;
            }
          }
          else if (pathValue == 'tickets-list' && !this.order) {
            const createResult: any = await this.promiser.waitFor(this.createOrder, this);
            if (createResult.error) {
              console.error("Erreur lors du passage de la commande");
            }
            else {

              const checkResult = this.checkDraftCode(createResult);

              if (checkResult === true) {
                this.store.dispatch(new SetBookingTime({ value: createResult.bookingTime }));
                ['bookingTime', 'code', 'expire'].forEach(_ => delete createResult[_]);
                this.store.dispatch(new SetKeysGlobal({ value: createResult, key: 'order' }));
                this.router.navigate([`${this.widgetId._id}/${nextPath}`]);
              }
              else {
                this.orderError = checkResult;
                this.popInService.displayPopIn({
                  firstClick: this.deleteOrderError,
                  close: this.deleteOrderError,
                  infoText: checkResult,
                  firstButtonText: 'Ok'
                })
              }
            }
            this.loading = false;
          }
          else if (pathValue == 'contact-information') {
            if (this.allFree) {
              if (this.order) {
                const body = await this.getUpdateDraftBody(this.order.token);
                this.subscriptions.add(this.orderService.updateDraft(this.order.token, body).subscribe((res: any) => {
                  if (res.code == 2008) this.addonOutOfStock(res)
                  else this.orderService.proceedPayment(this.order.token).subscribe(_result => {
                    if (_result && _result['free']) {
                      this.router.navigate([`${this.widgetId._id}/confirmation/ticketing/${this.order.token}`]);
                    }
                  });
                  this.loading = false;
                }));
              }
            }
            else {
              const body = await this.getUpdateDraftBody(this.order.token);
              this.subscriptions.add(this.orderService.updateDraft(this.order.token, body).subscribe((res: any) => {
                if (res.code == 2008) this.addonOutOfStock(res)
                else this.router.navigate([`${this.widgetId._id}/${nextPath}`]);
                this.loading = false;
              }));
            }
          }
          else {
            this.router.navigate([`${this.widgetId._id}/${nextPath}`]);
            break;
            this.loading = false;
          }
        }
      }
    }
  }

  public addonOutOfStock(res: any): void {
    const addon = res.data.addons[0]
    addon.name = this.getAddonName(addon);
    if (addon.available) {
      this.orderError = translateString(`Seulement ${addon?.available} options ${addon?.name} en stock`, 'addonLimited', { stock: addon?.available, name: addon?.name });
    }
    else {
      this.orderError = translateString(`Plus de stock disponible pour l'option ${addon?.name}`, 'noAddonAvailable', { name: addon?.name });
    }
    this.popInService.displayPopIn({
      firstClick: this.deleteOrderError,
      close: this.deleteOrderError,
      infoText: this.orderError,
      firstButtonText: 'Ok'
    })
  }

  public getAddonName(_addon: any): string {
    for (const ticket of this.ticketsToOrder) {
      for (const addon of ticket.addons) {
        if (addon._id == _addon.id) {
          return addon.name
        }
      }
    }
  }

  checkDraftCode(result): true | string {
    const name = result.name;
    const available = result.available;
    let value: string;
    switch (result.code) {
      case 1000: return true;
      case 3001: return this.getStockValue('Cet événement', 'event', available);
      case 3011: return this.getStockValue('Cette option', 'addon', available);
      case 3010: return this.getStockValue('Ce code promo', 'promoCode', available);
      case 3002: return this.getStockValue('Cette session', 'session', available);
      case 3003: return translateString(`La catégorie ${name} n'est pas ouverte à l'achat`, "categoryNotOpened", { name });
      case 3004: return this.getStockValue('La catégorie', 'category', available, name)
      case 3005:
        value = result.msg?.match(/(\d+)/)[0];
        return translateString(`Vous devez sélectionner un minimum de ${value} ticket(s) de type ${name}`, 'minSelect', { value, name });
      case 3006:
        value = result.msg?.match(/(\d+)/)[0];
        return translateString(`Vous ne pouvez pas sélectionner plus de ${value} ticket(s) de type ${name}`, 'maxSelect', { value, name });
      case 3007: return translateString(`La limite de temps est dépassée pour l'achat du type de ticket ${name}`, 'timeLimit', { name });
      case 3008: return this.getStockValue('Le ticket', 'ticket', available, name);
      case 3009: return translateString(`Le type de ticket ${name} n'existe pas`, 'ticketDoesntExist', { name });
    }
  }

  public getStockValue(type: string, key: string, available: number, name?: string): string {
    if (!available) {
      const text = `${type} ${name ? name : ''} n'a plus de place disponible`
      return translateString(text, `${key}NoMoreTicket`, { name })
    }
    else {
      const text = `Seulement ${available} place(s) restante(s) pour ${type.toLowerCase() + (name ? ' ' + name : '')}`
      return translateString(text, `${key}LimitTicket`, { name, stock: available.toString() })
    }
  }

  deleteOrderError() {
    this.orderError = null;
  }

  async createOrder(res, rej, ref = this) {
    const tickets = [];
    let current: string;

    //return false
    [...ref.tickets].reverse().forEach(ticket => {
      if (ticket._id !== current) {
        current = ticket._id;
        tickets.push({
          ticketTypeId: ticket._id,
          quantity: ticket.quantity
        });
      }
    })

    const body = {
      sessionId: ref.sessionId,
      currency: ref.currency._id,
      promoCode: ref.promoCode?.code || null,
      promoCodeQty: ref.promoCodeQty || 0,
      tickets: tickets
    }

    ref.orderService.generateDraft(body, ref.eventId._id, ref.widgetId._id)
      .subscribe(result => res(result), err => rej(err))
  }

  async getUpdateDraftBody(token: string): Promise<{
    invoiceData: any,
    tickets: TicketOrder[],
    sendingMethod: 'separate' | 'same'
    isProduct: boolean
  }> {
    const tickets: TicketOrder[] = []
    let i = 0;
    let separated = false;
    await Promise.all(this.ticketsToOrder.map(async ticket => {
      const isProduct = this.widgetId.forProduct
      const body: TicketOrder = {
        form: await Promise.all(ticket.form.map(async form => ({
          id: form._id,
          fields: await Promise.all(form.fields.map(async field => ({
            formFieldId: field._id,
            values: await this.filesHandler.checkForUploads(field.value, token, i)
          }))
          )
        }))),
        addons: ticket.addons.filter(addon => addon.isChecked).map(addon => addon._id),
        ticketTypeId: ticket._id,
        firstName: !isProduct &&  this.orderTicketForms[i].fields[0].value,
        lastName: !isProduct && this.orderTicketForms[i].fields[1].value,
      };
      const email = !isProduct && this.orderTicketForms[i].getEmail();
      if (email) {
        body.email = email;
        separated = true;
      }
      tickets.push(body);
      i++
    }));
    return {
      invoiceData: {
        company: this.getBillingField('Société')?.value,
        civility: this.getBillingField('Civilité')?.value,
        firstName: this.getBillingField('Prénom')?.value,
        lastName: this.getBillingField('Nom')?.value,
        email: this.getBillingField('Adresse e-mail')?.value,
        zone: this.getBillingField('Emplacement (Zone, Place, Loge)')?.value,
        address: this.getBillingField('Adresse')?.value,
        address2: this.getBillingField('Adresse (suite)')?.value,
        zipCode: this.getBillingField('Code postal')?.value,
        city: this.getBillingField('Ville')?.value,
        sendInvoice: true
      }, tickets,
      sendingMethod: separated ? 'separate' : 'same',
      isProduct: this.widgetId.forProduct
    };
  }

  setTicketOrder(ticket: TicketOrder): TicketOrder {
    return
  }

  getBillingField(name: string) {
    return this.billingForm.fields.find(_ => _.label == translateString(name));
  }

  updateTicket() {

  }


}
