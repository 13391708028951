import Requester from '../requester';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import Order from 'src/app/models/order.model';
import { QueryParams } from 'src/app/etc/queryParams';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OrderService extends Requester {

	constructor(
		protected http: HttpClient,
		protected queryParam: QueryParams
	) {
		super('events/orders', Order, http, queryParam);
	}

	public getList(_body = null): Observable<any> {
		return super.getList(_body);
	}

	public getById(_id, _body = null): Observable<any> {
		return super.getById(_id, _body);
	}

	public create(_body: Order): Observable<any> {
		return super.create( _body);
	}


	public getInsurancePrice(_token: string) {
		const url = `${environment.apiUrl}/v1/events/orders/insurance/price/${_token}`;
		return this.http.get(url);
	}

	public holdSeat(_body, eventId: string){
		const url = `${environment.apiUrl}/v1/events/orders/draft/${eventId}/hold-seat`;
		return this.httpClient.post(url, _body).pipe(
			map((_response) => {
				return _response;
			})
		);
	}

	public generateDraft(_body, eventId: string, widgetId: string){
		const url = `${environment.apiUrl}/v1/events/orders/draft/${eventId}/${widgetId}`;
		return this.http.post(url, _body);
	}

	public proceedPayment(_token){
		const url = `${environment.apiUrl}/v1/events/orders/proceed-payment/${_token}`;
		return this.http.get(url);
	}

	public getOrder(_token){
		const url = `${environment.apiUrl}/v1/events/orders/get-order/${_token}`;
		return this.http.get(url);
	}

	public streamOrder(_type, _typeId, _token, _template: 'ticket' | 'badge' = "ticket"){
		const url = `${environment.apiUrl}/v1/events/orders/stream/${_type}/${_typeId}/${_token}?pdfTemplate=${_template}`;
		return this.http.get(url, {responseType: 'blob'});
	}

	public updateDraft(_token, _body) {
		const url = `${environment.apiUrl}/v1/events/orders/draft/${_token}`;
		return this.http.put(url, _body);
	}

	public updateDraftNft(_token, _body) {
		const url = `${environment.apiUrl}/v1/events/orders/draft/${_token}/nft`;
		return this.http.put(url, _body);
	}

	resendOrder(email: string, eventId: string): Observable<{msg: string}> {
		const url = `${environment.apiUrl}/v1/events/orders/resend-from-email/${eventId}`;
		return this.http.post<{msg: string}>(url,  {email});
	}
}
