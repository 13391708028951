import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TimeLineService } from 'src/app/providers';
import { Select, Store } from '@ngxs/store';
import { GlobalState, GlobalStateModel } from 'src/app/store/states/global.state';
import Widget from 'src/app/models/widget.model';
import Event from 'src/app/models/event.model';

@Component({
  selector: 'app-o-header',
  templateUrl: './o-header.component.html',
  styleUrls: ['./o-header.component.scss']
})
export class OHeaderComponent implements OnInit, OnDestroy {
  @Select(GlobalState) globalState$: Observable<GlobalStateModel>;
  public registration: boolean;
  public allFree: boolean;
  public resend = false;
  public countdownLaunch: boolean;
  private countdownMinutes: number;
  private countdownSeconds: number;
  private subscriptions = new Subscription();
  private refreshInterval: NodeJS.Timeout;
  public widget: Widget;
  private remainDays: number;
  public isNft: boolean;

  constructor(private timeLineService: TimeLineService, private store: Store) { }

  ngOnDestroy(): void {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.globalState$.subscribe((state: GlobalStateModel) => {
      this.registration = state.globalObject.isRegistration;
      this.allFree = state.globalObject.allFree;
      if (this.registration) {
        if (this.allFree) this.timeLineService.deletePayment();
        else this.timeLineService.restorePayment();
      }


      this.widget = state?.globalObject?.widget;
      if (this.widget) {
        if (this.remainDays === undefined) {
          this.remainDays = this.calcRemainDays(this.widget.eventId);
        }
      }
      if (state.globalObject.isNft) {
        this.isNft = true;
      }
    }));
    this.subscriptions.add(this.timeLineService.countDownSignal$.subscribe(value => {
      if (value && !this.countdownLaunch) {
        this.countdownLaunch = true;
        this.countdownSeconds = this.widget?.eventId?.ticketing?.bookingTime;
        this.setCountdown();
      }
      else if (!value && this.countdownLaunch) {
        this.countdownLaunch = false;
        clearInterval(this.refreshInterval);
      }
    }))
  }

  setCountdown() {
    this.countdownMinutes = 0;
    while (this.countdownSeconds > 59) {
      this.countdownMinutes += 1;
      this.countdownSeconds -= 60;
    }
    this.refreshInterval = setInterval(() => this.refreshCountdown(), 1000);
  }

  refreshCountdown() {
    this.countdownSeconds -= 1;
    if (!this.countdownSeconds && !this.countdownMinutes) {
      location.reload();
    }
    else if (this.countdownSeconds < 0) {
      this.countdownSeconds = 59;
      this.countdownMinutes -= 1;
    }
  }

  calcRemainDays(event: Event): number {
    let endDate: Date;
    let startDate: Date;
    const now = new Date();
    if (event.ticketing.date.startDate) {
      startDate = new Date(event.ticketing.date.startDate);
    }
    if (event.ticketing.date.endDate) {
      endDate = new Date(event.ticketing.date.endDate);
    }
    if (startDate) {
      if (startDate.getTime() > now.getTime()) {
        return -1;
      }
    }
    if (endDate) {
      return this.getDaysBetweenDates(new Date(endDate), now)
    }
  }

  getDaysBetweenDates(date1: Date, date2: Date): number {
    return Math.floor((date1.getTime() - date2.getTime()) / 86400000);
  }

}
