import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-o-pop-in',
  templateUrl: './o-pop-in.component.html',
  styleUrls: ['./o-pop-in.component.scss']
})
export class OPopInComponent implements OnInit {
  @Output() firstClick: EventEmitter<void> = new EventEmitter();
  @Output() secondClick: EventEmitter<void> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() firstButtonText: string;
  @Input() secondButtonText: string;
  @Input() infoText: string;
  @Input() boldText: string;
  clickedIn: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  clickFirst() {
    this.firstClick.emit();
    this.close.emit();
  }

  clickSecond() {
    this.secondClick.emit();
    this.close.emit();
  }

  clickIn() {
    this.clickedIn = true;
    setTimeout(() => this.clickedIn = false);
  }

  clickOut() {
    if (!this.clickedIn) {
      this.close.emit();
    }
  }

}
