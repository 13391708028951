import checkField from './check-field';

export default class Currency {
    _id: string;
    isoCode: string;
    value: number;
    weight: number;

    constructor(_currency?: any) {
        if (checkField(_currency?._id)) {
            this._id = _currency._id;
        }
        if (checkField(_currency?.isoCode)) {
            this.isoCode = _currency.isoCode;
        }
        if (checkField(_currency?.value)) {
            this.value = _currency.value;
        }
        if (checkField(_currency?.weight)) {
            this.weight = _currency.weight;
        }
    }
}