<header>
  <h2>{{"Toutes les places ont été vendues." | customTranslation: "noTicketsAvailable"}}</h2>
</header>
<figure>
  <figure><img src="assets/img/soldout.svg" alt="confirmation"></figure>
</figure>
<footer>
  <app-a-button [text]="'S\'inscrire sur liste d\'attente' | customTranslation: 'registerWaitingList'" (buttonClicked)='goWaitingList()'></app-a-button>

  <p class="description">{{"Les prix affichés sont toutes taxes comprises" | customTranslation: "infoPriceDisplayed"}}.</p>
</footer>
