import checkField from './check-field';
import TicketType from './ticket-type.model';
import { Reduction } from './type.definition';
export default class PromoCode {
    _id: string;
    reduction: Reduction;
    totalReduction: number;
    name: string;
    code: string;
    status: string;
    available: number;
    counterUsed: number;
    counterScope: 'order' | 'ticket';
    constraintTicketTypesIds?: TicketType[];
    constraintBeginDate?: Date;
    constraintEndDate?: Date;
    constraintQuantity?: number;


    constructor(_promoCode?: any) {
        if (checkField(_promoCode?._id)) {
            this._id = _promoCode._id;
        }
        if (checkField(_promoCode?.reduction)) {
            this.reduction = _promoCode.reduction;
        }
        if (checkField(_promoCode?.code)) {
            this.code = _promoCode.code;
        }
        if (checkField(_promoCode?.name)) {
            this.name = _promoCode.name;
        }
        if (checkField(_promoCode?.counterUsed)) {
            this.counterUsed = _promoCode.counterUsed;
        }
        if (checkField(_promoCode?.status)) {
            this.status = _promoCode.status;
        }
        if (checkField(_promoCode?.counterUsed)) {
            this.counterUsed = _promoCode.counterUsed;
        }
        if (checkField(_promoCode?.available)) {
            this.available = _promoCode.available;
        }
        if (checkField(_promoCode?.counterScope)) {
            this.counterScope = _promoCode.counterScope;
        }
        if (checkField(_promoCode?.constraintTicketTypesIds)) {
            this.constraintTicketTypesIds = _promoCode.constraintTicketTypesIds;
        }
        if (checkField(_promoCode?.constraintBeginDate)) {
            this.constraintBeginDate = _promoCode.constraintBeginDate;
        }
        if (checkField(_promoCode?.constraintEndDate)) {
            this.constraintEndDate = _promoCode.constraintEndDate;
        }
        if (checkField(_promoCode?.constraintQuantity)) {
            this.constraintQuantity = _promoCode.constraintQuantity;
        }
    }
}
