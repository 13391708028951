import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-a-box-close-countdown',
  templateUrl: './a-box-close-countdown.component.html',
  styleUrls: ['./a-box-close-countdown.component.scss']
})
export class ABoxCloseCountdownComponent {
  @Input() remainDays: number;
  @Input() registration: boolean;
}
