import FormField from './form-field.model';

export default class OrderTicketForm {
    static Forms: OrderTicketForm[] = [];
    emailField = new FormField({ label: 'Email', isRequired: true, type: 'email' });
    fields: FormField[] = [];
    ticketTypeId?: string;
    missingFields: number;

    constructor(ticketTypeId: string) {
        this.ticketTypeId = ticketTypeId;
        this.fields.push(new FormField({ label: 'Prénom', isRequired: true, type: 'textfield' }));
        this.fields.push(new FormField({ label: 'Nom', isRequired: true, type: 'textfield' }));
        this.checkMissingField();
        OrderTicketForm.Forms.push(this);
    }

    setEmail(): void {
        this.fields.push(this.emailField);
        this.checkMissingField();
    }

    deleteEmail(): void {
        this.fields.splice(2, 1);
        this.checkMissingField();
    }

    checkMissingField(): void {
        this.missingFields = this.fields.filter(_ => _.isRequired && !_.isValid).length;
    }

    static setEmails() {
        this.Forms.forEach(_ => _.setEmail())
    }

    static deleteEmails() {
        this.Forms.forEach(_ => _.deleteEmail())
    }

    public getEmail(): string {
        if (this.fields[2]) {
            return this.fields[2].value;
        }
    }

}
