import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import Currency from 'src/app/models/currency.model';
import { CurrencyService } from 'src/app/providers';

@Component({
  selector: 'app-m-category-seat-summary-item',
  templateUrl: './m-category-seat-summary-item.component.html',
  styleUrls: ['./m-category-seat-summary-item.component.scss']
})
export class MCategorySeatSummaryItemComponent implements OnInit, OnDestroy {

  @Input() seatInfos: Array<any>;
  @Input() categoryName: string;
  @Input() priceType: string;
  @Input() price: number;
  @Output() onShowSeat: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteSeat: EventEmitter<any> = new EventEmitter();
  currency: Currency;
  subscriptions = new Subscription();

  constructor(private currencyService: CurrencyService) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.currencyService.currency$.subscribe(res => {
      this.currency = res;
    }));
  }

  showSeat(): void {
    this.onShowSeat.emit();
  }
  deleteSeat(): void {
    this.onDeleteSeat.emit();
  }

}
