import Requester from '../requester';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryParams } from 'src/app/etc/queryParams';
import { BehaviorSubject, Observable } from 'rxjs';
import Currency from 'src/app/models/currency.model';
import { Media } from 'src/app/models/media.model';
import { environment } from 'src/environments/environment';
import { HTTPTools } from 'src/app/etc/http-tools';

@Injectable()
export class MediaService extends Requester {

    currency$: BehaviorSubject<Media> = new BehaviorSubject(null);

    constructor(
        protected http: HttpClient,
        protected queryParam: QueryParams
    ) {
        super('common/medias', Currency, http, queryParam);
    }

    public delete(id: string): Observable<any> {
        return this.httpClient.delete<any>(`${environment.apiUrl}/v1/common/medias/delete/${id}`);
    }

    public upload(body: FormData): Observable<any> {
        HTTPTools.setNextContentType(null);
        return this.httpClient.put<any>(`${environment.apiUrl}/v1/common/medias/create/upload-ticket-media`, body);
    }
}
