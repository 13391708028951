import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import TicketTypeCategory from 'src/app/models/ticket-type-category.model';
import Currency from 'src/app/models/currency.model';
import { CurrencyService } from 'src/app/providers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-m-category-accordion-item',
  templateUrl: './m-category-accordion-item.component.html',
  styleUrls: ['./m-category-accordion-item.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ maxHeight: '0px', opacity: 0 }),
        animate('500ms ease-in', style({ maxHeight: '300px', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ maxHeight: '0px', opacity: 0 }))
      ])
    ])
  ]
})
export class MCategoryAccordionItemComponent implements OnInit, OnDestroy {
  @Input() categoryName: string;
  @Input() categoryStartRate: number;
  @Input() ticketsNumber: number;
  @Input() allFree: boolean;
  expended: boolean;
  currency: Currency;
  subscriptions = new Subscription();

  constructor(private currencyService: CurrencyService) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(this.currencyService.currency$.subscribe(res => {
      this.currency = res;
    }));
    this.expended = this.ticketsNumber > 0;
  }

}
