import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GlobalState } from '../../store/states/global.state';
import TicketType from '../../models/ticket-type.model';
import { Cart } from '../../models/type.definition';

declare global {
  interface Window {
    dataLayer?: any;
  }
}
declare var gtag: Function;
declare var fbq: Function;

export enum TrackingEvent {
  PAGE_VIEW = 'page_view',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  PURCHASE = 'purchase',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_PAYMENT_INFO = 'add_payment_info',
}

export enum TrackingEventPixel {
  PAGE_VIEW = 'PageView',
  ADD_TO_CART = 'AddToCart',
  REMOVE_FROM_CART = 'RemoveFromCart',
  PURCHASE = 'Purchase',
  BEGIN_CHECKOUT = 'BeginCheckout',
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
}

export type EventData = {
  cartItem?: TicketType,
  cart?: Cart
}

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  gtmEnabled: boolean = false;
  fbPixelEnabled: boolean = false;
  gaEnabled: boolean = false;

  constructor(
    private store: Store
  ) {
    window.dataLayer = window.dataLayer || [];
  }

  loadGTM(gtm: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtm}`;
    document.head.appendChild(script);
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  }

  loadGA(ga1: string, ga2?: string): void {
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${ga1}`;
    document.head.appendChild(script1);
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${ga1}');
    `;
    if (ga2) {
      script2.innerHTML += `gtag('config', '${ga2}');`;
    }
    document.head.appendChild(script2);
  }

  loadPixel(pixelId: string): void {
    const script = document.createElement('script');
    script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', '${pixelId}');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(script);
  }

  sendEvent(eventName: TrackingEvent, eventData: EventData): void {
    const tracking: { gtm: string|null, ga: string|null, pixel: string|null } = this.store.selectSnapshot(state => GlobalState.get(state, 'tracking'));
    if (tracking.gtm) {
      this.sendEventToGTM(eventName, eventData);
    }
    this.sendEventToGA(eventName, eventData);
    if (tracking.pixel) {
      const pixelEvent = this.convertEventToPixelEvent(eventName);
      this.sendEventToFB(pixelEvent, eventData);
    }
  }

  protected sendEventToFB(eventName: TrackingEventPixel, eventData: EventData): void {
    if (typeof fbq !== 'undefined') {
      let data = {};
      if (eventData.cartItem) {
        data = this.convertCartItemToPixelItem(eventData.cartItem);
      }
      if (eventData.cart) {
        data = this.convertCartToGACart(eventData.cart);
      }
      fbq('track', eventName, data);
    }
  }

  protected sendEventToGTM(eventName: TrackingEvent, eventData: EventData): void {
    if (window.dataLayer) {
      let data = {};
      if (eventData.cartItem) {
        data = {
          ecommerce: this.convertCartItemToGAItem(eventData.cartItem)
        }
      }
      if (eventData.cart) {
        data = {
          ecommerce: this.convertCartToGACart(eventData.cart)
        }
      }
      window.dataLayer.push({
        event: eventName,
        ...data
      });
    }
  }

  protected sendEventToGA(eventName: TrackingEvent, eventData: EventData): void {
    if (typeof gtag !== 'undefined') {
      let data = {};
      if (eventData.cartItem) {
        data = this.convertCartItemToGAItem(eventData.cartItem);
      }
      if (eventData.cart) {
        data = this.convertCartToGACart(eventData.cart);
      }
      gtag('event', eventName, data);
    }
  }

  protected convertEventToPixelEvent(eventName: TrackingEvent): TrackingEventPixel {
    switch (eventName) {
      case TrackingEvent.PAGE_VIEW:
        return TrackingEventPixel.PAGE_VIEW;
      case TrackingEvent.ADD_TO_CART:
        return TrackingEventPixel.ADD_TO_CART;
      case TrackingEvent.REMOVE_FROM_CART:
        return TrackingEventPixel.REMOVE_FROM_CART;
      case TrackingEvent.PURCHASE:
        return TrackingEventPixel.PURCHASE;
      case TrackingEvent.BEGIN_CHECKOUT:
        return TrackingEventPixel.BEGIN_CHECKOUT;
      case TrackingEvent.ADD_PAYMENT_INFO:
        return TrackingEventPixel.ADD_PAYMENT_INFO;
    }
  }

  protected convertCartItemToPixelItem(item: TicketType,  getQty: boolean = false) {
    return {
      value: item.price,
      currency: 'EUR',
      contents: [
        {
          id: item._id,
          name: item.name,
          price: item.price,
          quantity: (getQty) ? item.quantity : 1
        }
      ]
    }
  }

  protected convertCartToGACart(cart: Cart) {
    const totalCart = this.store.selectSnapshot(state => GlobalState.getTotalCart(state))
    return {
      currency: "EUR",
      value: totalCart,
      coupon: cart.promoCode?.code,
      items: cart.tickets.map(_ => {
        return this.convertCartItemToGAItem(_, true)
      }),
    }
  }

  protected convertCartItemToGAItem(item: TicketType, getQty: boolean = false) {
    return {
      currency: 'EUR',
      value: item.price,
      items: [
        {
          item_id: item._id,
          item_name: item.name,
          price: item.price,
          quantity: (getQty) ? item.quantity : 1
        }
      ]
    }
  }

}