import checkField from './check-field';
import { FormLocation } from './type.definition';
import FormField from './form-field.model';

export default class TicketForm {
    _id: string;
    name: string;
    location: FormLocation;
    fields: FormField[];
    missingFields: number;
    ticketTypes: {
        restrict: boolean;
        ids: string[];
    }
    public get isValid(): boolean {
        return !this.missingFields
    }
    constructor(_ticketForm?: any) {
        if (checkField(_ticketForm?._id)) {
            this._id = _ticketForm._id;
        }
        if (checkField(_ticketForm?.name)) {
            this.name = _ticketForm.name;
        }
        if (checkField(_ticketForm?.location)) {
            this.location = _ticketForm.location;
        }
        if (checkField(_ticketForm?.fields)) {
            this.fields = _ticketForm.fields.map(_ => new FormField(_));
            this.missingFields = this.fields.filter(_ => _.isRequired && !_.isValid).length;
        }
        if (checkField(_ticketForm?.ticketTypes)) {
            this.ticketTypes = _ticketForm.ticketTypes;
        }
    }


}
