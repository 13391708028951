<div class="o-payment-box" *ngIf="order.amount > 0">

  <header>
    <h2>{{"Informations de paiement" | customTranslation: "bankDetails"}}</h2>
  </header>

  <div class="banner" *ngIf="displayMessage">
    <p>{{ displayMessage }}</p>
  </div>

  <div id="payment-request-button">
    <!-- Elements will create input elements here -->
  </div>

  <div class="paymentOr" *ngIf="displayApplePay">
    <span>{{"Ou" | customTranslation: "securePayment"}}</span>
  </div>

  <form id="payment-form">
    <app-m-form-row columns="full">
      <app-a-form-input-field label="{{'Titulaire de la carte' | customTranslation: 'cardOwner'}}" [required]="true" id="holderName" [value]=""
        (InputChange)="cardNameElement = $event">
      </app-a-form-input-field>
    </app-m-form-row>
    <div id="card-element">
      <!-- Elements will create input elements here -->
    </div>

    <!-- We'll put the error messages in this element -->
    <div id="card-errors" role="alert"></div>

    <!-- <button id="submit" (click)="validatePayment()">Pay</button> -->
    <app-a-button text="{{'Payer' | customTranslation: 'finalPay'}}" [isLoading]="loading" (buttonClicked)="validatePayment()"></app-a-button>

  </form>

  <footer>
    <p><i class="webfont webfont-Lock"></i><span>{{"Paiement sécurisé" | customTranslation: "securePayment"}}</span></p>
    <div class="list--logos">
      <img src="assets/img/reassurances/cb.svg" alt="carte bancaire" title="carte bancaire" />
      <img src="assets/img/reassurances/visa.svg" alt="visa" title="visa" />
      <img src="assets/img/reassurances/mastercard.svg" alt="mastercard" title="mastercard" />
    </div>
  </footer>
</div>

<div class="o-payment-box" *ngIf="order.amount == 0">
  <header>
    <h2>Confirmer la commande</h2>
  </header>

  <div class="banner" *ngIf="displayMessage">
    <p>{{ displayMessage }}</p>
  </div>

  <form id="payment-form">
    <app-a-button text="Valider" [isLoading]="loading" (buttonClicked)="validatePayment()"></app-a-button>
  </form>

</div>
