<div class="o-date-selection-box">
    <h1>{{"Choisir une date" | customTranslation: "chooseDate"}}</h1>

    <div
        class="form"
        *ngIf="date"
    >

        <div class="fields">

            <app-a-form-input-field
                [label]="'Date' | customTranslation"
                [required]="true"
                type="text"
                [value]="date | localizedDate : 'dd MMMM yyyy'"
                [readonly]="true"
            >
            </app-a-form-input-field>

            <app-a-form-select-field
                [label]="'Heure' | customTranslation"
                [required]="true"
                [options]="selectableHours"
                [value]="selectedSessionID"
                (InputChange)="changeSession($event)"
            >
            </app-a-form-select-field>
        </div>

        <app-a-button
            [text]="'Suivant' | customTranslation"
            (buttonClicked)="selectSession()"
            [isLoading]="loading"
        ></app-a-button>
    </div>

    <p
        *ngIf="!date"
        class="no_date_text"
    >
        {{"Veuillez sélectionner une date dans le calendrier" | customTranslation:"pleaseChooseDate"}}
    </p>

</div>
