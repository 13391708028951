<div class="a-form-toggle-field">
  <div class="left">
    <button (click)="onToggleChange(!checked)" [ngClass]="{checked: checked}">
      <i class="webfont webfont-check"></i>
    </button>
    <div class="label_text">
      <p class="label">{{label}}</p>
      <p *ngIf="text" class="description">{{text}}</p>
    </div>
  </div>
</div>
