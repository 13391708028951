import { Pipe, PipeTransform } from '@angular/core';
import { Translations } from "./translation"

// const currentLang: 'es' | 'fr' | 'en' = "es"

/**
 * Custom translator
 */
@Pipe({
  name: 'customTranslation'
})
export class CustomTranslationPipe implements PipeTransform {
  transform(value: string, arg?: string): string {
    const translation = Translations[arg || value];
    return !translation || currentLang == 'fr' ? value : translation[currentLang];;
  }
}

export function translateString(value: string, key?: string, replacements?: {[key in string]: string}): string {
  const translator = new CustomTranslationPipe();
  let translation = translator.transform(value, key);
  if (replacements) {
    for (const key in replacements) {
      translation = translation.replace(`{${key}}`, replacements[key]);
    }
  }
  return translation
}