import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CommonService {

	constructor(
		protected httpClient: HttpClient,
	) {

	}

	public healthCheck() {
		return this.httpClient.get<{ status: "ok" | "error", message: string }>(`${environment.apiUrl}/v1/healthCheck`);
	}

}
