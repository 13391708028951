<app-t-content-w-sidebar type="big_sidebar">

  <div *ngIf="sessions" slotContent>

    <app-o-calendar-month [availableSessions]="sessions" (dateSelection)="onDateSelection($event)"></app-o-calendar-month>
  </div>


  <app-o-date-selection-box slotSidebar [date]="selectedDate" [sessions] = "sessionsToSelect" [changeOfDate]="dateChange$" (sessionSelected)="goToTicketPage($event)"></app-o-date-selection-box>


</app-t-content-w-sidebar>