// Données persistantes du widget (Widget et énvement en cours + données du panier)

import { State, Action, StateContext, Selector } from '@ngxs/store';
import { GlobalStateObject, SetCartModel, Cart, AppPrice } from '../../models/type.definition';
import { SetKeysGlobal, SetCart, SetAddOn, SetPromoCode, SetBookingTime, RemovePromoCode, SetInsurance } from '../actions/global.actions';
import FormField from 'src/app/models/form-field.model';
import TicketForm from 'src/app/models/ticket-form.model';
import { Injectable } from '@angular/core';
import { TrackingEvent, TrackingService } from '../../providers';
import TicketType from '../../models/ticket-type.model';
import PromoCode from '../../models/promo-code.model';
import Widget from '../../models/widget.model';

export class GlobalStateModel {
  globalObject: GlobalStateObject;
}

@State<GlobalStateModel>({
  name: 'globalObject',
  defaults: {
    globalObject: {
      hasInsurance: false,
      isMaintenance: false,
      insurance: null,
      isNft: false,
      isRegistration: false,
      totalIsZero: false,
      allFree: false,
      eventId: null,
      sessions: null,
      session: null,
      widgetId: null,
      widget: null,
      order: null,
      currency: null,
      tickets: [],
      billingForm: null,
      generalAddOns: [],
      cart: {
        tickets: [],
        addons: [],
        promoCode: null,
        promoCodeQty: null
      },
      orderTicketForms: null,
      showSeatPlan: false,
      tracking: {
        gtm: null,
        pixel: null,
        ga: null,
      }
    }
  }
})

@Injectable({
  providedIn: 'root' // Permet d'avoir une instance unique dans toute l'application
})
export class GlobalState {

  constructor(
    private trackingService: TrackingService
  ) { }

  @Selector()
  static get(state: GlobalStateModel, key?: string): GlobalStateObject | any {
    return key ? state.globalObject['globalObject'][key] : state.globalObject['globalObject'];
  }

  static calcPromoReduc(promoCode: PromoCode, tickets: TicketType[]): number {
    if (!promoCode) {
      return 0
    }
    const ticketTypesIds: string[] = (promoCode?.constraintTicketTypesIds || [])?.map(_ => _._id)
    let concernedTickets: TicketType[] = tickets.filter(_ => _.price > 0);

    if (ticketTypesIds?.length) {
      concernedTickets = concernedTickets.filter(_ => ticketTypesIds.includes(_._id));
    }

    if (promoCode.counterScope === "ticket" &&  concernedTickets.length > promoCode.available) {
      concernedTickets = concernedTickets.slice(0, promoCode.available);
    }

    const total = concernedTickets.length ? this.calcPrice(concernedTickets) : 0;
    const totalReduction = this.getReduction(total, promoCode.reduction, concernedTickets);

    return totalReduction
  }

  static getReduction(total: number, reduction: any, concernedTickets: TicketType[]) {
    let calculatedReduction = 0;
    for (const ticket of concernedTickets) {
      if (reduction.type =="flat") {
        calculatedReduction += (ticket.price < reduction.amount) ? ticket.price : reduction.amount;
      }
      else if (reduction.type == "percentage") {
        calculatedReduction += (ticket.price * reduction.amount) / 100;
      }
      else if (reduction.type == "free") {
        calculatedReduction += ticket.price;
      }
    }

    return (calculatedReduction > total) ? total : calculatedReduction;
  }

  static calcPrice(tickets: TicketType[]): number {
    return tickets.map(_ => _.price).reduce((a: any, c: any) => a + c)
  }

  static calcOptionsPrice(tickets: TicketType[]): number {
    let optionsPrice = 0;
    if (tickets) {
      const nbTickets = tickets.length;
      const ticketsTotal = tickets.length > 0 ? this.calcPrice(tickets) : 0;
      tickets.map(ticket => {
        ticket.addons.map(addOn => {
          if (addOn.isChecked) {
            optionsPrice += addOn.price;
          }
        });
      });
    }
    return optionsPrice;
  }

  static getNonFreeTicketsLength(tickets: TicketType[]): number {
    return tickets.filter(ticket => {
      return ticket.price || ticket.addons.filter(addon => addon.isChecked && addon.price).length
    }).length
  }

  @Selector()
  static getTotalCart(state: GlobalStateModel): number {
    const cart: Cart = state.globalObject['globalObject'].cart;
    const widgetId: Widget = state.globalObject['globalObject'].widget;
    const addons = cart.addons
    const nbTickets = cart.tickets.length;
    const ticketsTotal = cart.tickets.length > 0 ? this.calcPrice(cart.tickets) : 0;
    const ticketsOptionsPrice = this.calcOptionsPrice(cart.tickets);
    const insurancePrice: AppPrice | null = state.globalObject['globalObject'].insurance;
    let charges = 0;

    let total = 0;
    total += ticketsTotal;
    total += ticketsOptionsPrice;
    addons.forEach(_ => total += _.price * nbTickets);
    const promoReduct = this.calcPromoReduc(cart.promoCode, cart.tickets);
    total -= promoReduct || 0;
    if (total < 0) {
      total = 0;
    }

    if (total) {
      if (widgetId.eventId.commissionIncreased) {
        charges += widgetId.eventId.commission.percentage * total / 100
        charges += (widgetId.eventId.commission.flat / 100) * this.getNonFreeTicketsLength(cart.tickets);
      }
      total += charges
    }
    if (insurancePrice) {
      total += insurancePrice.floated;
    }

    return total
  }

  @Action(SetKeysGlobal)
  setKeys({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetKeysGlobal): void {
    const state = getState();
    state.globalObject[payload.key] = payload.value;
    patchState({
      globalObject: state.globalObject
    });
  }

  @Action(SetCart)
  setCart({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetCart): void {

    const state = getState();
    const cart = state.globalObject.cart;

    let type = null;

    // If selected seat
    if (payload.ticket.seat) {
      type = cart.tickets.filter(_ => _._id === payload.ticket._id && _.seat === payload.ticket.seat);
    } else { // If classic method
      type = cart.tickets.filter(_ => _._id === payload.ticket._id);
    }

    if(type.length > payload.quantity){
      cart.tickets.splice(cart.tickets.indexOf(type[type.length - 1]), 1);
    }
    else {
      const newTicket = this.copyObject(payload.ticket);
      cart.tickets.push(newTicket)
    }
    patchState({
      globalObject: state.globalObject
    });

    if (payload.quantity < type.length) {
      this.trackingService.sendEvent(TrackingEvent.REMOVE_FROM_CART, { cartItem: payload.ticket })
    }

    if (payload.quantity > type.length) {
      this.trackingService.sendEvent(TrackingEvent.ADD_TO_CART, { cartItem: payload.ticket })
    }

  }

  @Action(SetAddOn)
  setAddOn({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetAddOn): void {
    const state = getState();
    const cart = state.globalObject.cart;
    if (payload.addOn) {
      if (payload.addOn.isChecked) {
        cart.addons.push(payload.addOn);
      }
      else {
        cart.addons.splice(cart.addons.indexOf(payload.addOn), 1);
      }
    }
    patchState({
      globalObject: state.globalObject
    });
  }

  @Action(SetInsurance)
  setInsurance({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetInsurance): void {
    const state = getState();
    patchState({
      globalObject: {
        ...state.globalObject,
        insurance: payload.amount
      }
    });
  }

  @Action(SetPromoCode)
  setPromoCode({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetPromoCode): void {
    const state = getState();
    const cart = state.globalObject.cart;
    if (payload.code) {
        cart.promoCode = payload.code;
    }
    cart.promoCodeQty = 0
    if (payload.qty) {
      cart.promoCodeQty = payload.qty;
    }
    patchState({
      globalObject: state.globalObject
    });
  }

  @Action(RemovePromoCode)
  removePromoCode({ getState, patchState }: StateContext<GlobalStateModel>): void {
    const state = getState();
    const cart = state.globalObject.cart;
    cart.promoCode = null;
    patchState({
      globalObject: state.globalObject
    });
  }

  @Action(SetBookingTime)
  setBookingTime({ getState, patchState }: StateContext<GlobalStateModel>, { payload }: SetBookingTime): void {
    const state = getState();
    state.globalObject.widget.eventId.ticketing.bookingTime = payload.value;
    this.trackingService.sendEvent(TrackingEvent.BEGIN_CHECKOUT, { cart: state.globalObject.cart })
    patchState({
      globalObject: state.globalObject
    });
  }

  updateTicket(_cart: Cart, _payload: SetCartModel): void {

  }

  copyObject(_object: any): any {
    const newObject = {};
    for (const key in _object) {
      if (Array.isArray(_object[key])) {
        newObject[key] = [];
        _object[key].forEach(_ => {
          if (_ instanceof Object) {
            if (_ instanceof FormField) {
              newObject[key].push(new FormField(this.copyObject(_)));
            }
            else {
              newObject[key].push(this.copyObject(_));
            }
          }
          else {
            newObject[key].push(_);
          }
        });
      }
      else if (_object[key] instanceof Object && typeof _object[key] !== 'function') {
        if (_object[key] instanceof TicketForm) {
          newObject[key] = new TicketForm(this.copyObject(_object[key]));
        }
        else {
          newObject[key] = this.copyObject(_object[key]);
        }
      }
      else {
        newObject[key] = _object[key];
      }
    }
    return newObject
  }

}
