import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderService } from 'src/app/providers';

@Component({
  selector: 'app-o-resend',
  templateUrl: './o-resend.component.html',
  styleUrls: ['./o-resend.component.scss']
})
export class OResendComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Input() eventId: string;
  public form: FormGroup;
  public loading = false;
  public emailSent = false;

  constructor(private formBuilder: FormBuilder, private orderService: OrderService) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: new FormControl('', [Validators.email, Validators.required])
    });
  }

  public setEmail(email: string): void {
    this.form.patchValue({ email });
  }

  public clickOut(): void {
    this.close.emit();
  }

  public send(): void {
    this.loading = true;
    this.orderService.resendOrder(this.form.value.email, this.eventId).subscribe(res => {
      this.loading = false;
      this.emailSent = true;
    }, () => this.loading = false)
  }

}
