import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-a-title-main',
  templateUrl: './a-title-main.component.html',
  styleUrls: ['./a-title-main.component.scss']
})
export class ATitleMainComponent implements OnInit {
  @Input() text: string;
  constructor() { }

  ngOnInit(): void {
  }

}
