<div class="o-calendar-month">
  <div class="o-calendar-month-inner">
    <header>
      <button class="previous webfont webfont-arrow-full" (click)="previousMonth()"></button>
      <h3>{{currentMonth}}</h3>
      <button class="next webfont webfont-arrow-full" (click)="nextMonth()"></button>
    </header>
    <ul class="header_week">
      <li>{{"Lun" | customTranslation}}</li>
      <li>{{"Mar" | customTranslation}}</li>
      <li>{{"Mer" | customTranslation}}</li>
      <li>{{"Jeu" | customTranslation}}</li>
      <li>{{"Ven" | customTranslation}}</li>
      <li>{{"Sam" | customTranslation}}</li>
      <li>{{"Dim" | customTranslation}}</li>
    </ul>

    <div class="grid-days">
      <div class="grid-day" [ngClass]="{'selectable' : day.selectable, 'selected' : day.selected}" (click)="clickOnDay(day)" *ngFor="let day of days; let index = index;" >
        <strong [ngClass]="{'is-today': isToday(day.dayDate), 'is-outside-month': isOutsideMonth(day.dayDate)}">
          {{ displayDayNum(day.dayDate) }}
        </strong>
      </div>
    </div>
  </div>
</div>
