<app-t-content-w-sidebar type="transparent_sidebar">

  <app-m-content-section slotContent type="white-content-box">
    <h2 slotTitle>Cliquez sur le plan pour choisir votre placement</h2>
    <div slotContent>
      <div id="seatsio" style="height: 720px"></div>
    </div>
  </app-m-content-section>

  <app-o-summary-seat-selection [categories]="categories" slotSidebar></app-o-summary-seat-selection>
</app-t-content-w-sidebar>
