<div class="place-left" [ngClass]="{'full': stock <= 0}">
  <div class="wrapper">
    <i class="webfont webfont-ticket"></i>
    <p *ngIf="stock <= 0">
      Plus de place disponible !
    </p>
    <p *ngIf="stock == 1">
      <span>{{ stock }}</span> place restante !
    </p>
    <p *ngIf="stock > 1">
      <span>{{ stock }}</span> places restantes !
    </p>
  </div>
</div>
