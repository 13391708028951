<div class="a-form-file-field">
  <label [for]="id">{{label}} <span *ngIf="required">*</span></label>
  <div class="input-container" [class]="required ? validate: ''">

    <input #uploader [id]="id" type="file" (change)="fileChange($event)" />

    <button *ngIf="!file" class="button-upload" (click)="uploader.click()">Télécharger un fichier</button>

    <div class="uploaded-file" *ngIf="file">
      <div class="left">
        <div class="file-type">
          <i class="webfont webfont-file"></i>
          <p>{{displayExt(file.type)}}</p>
        </div>
        <p>{{file.name}}</p>
      </div>
      <div class="right">
        <button class="button-delete webfont webfont-delete" (click)="deleteFile()"></button>
      </div>
    </div>
  </div>
  <div class="input-error">
    <p *ngIf="required && validate === 'error'">
      Veuillez télécharger un format valide
    </p>
  </div>
  <p class="description" *ngIf="description">
    {{description}}
  </p>

  <p class="constraints" *ngIf="constraintsInfos">
    {{constraintsInfos}}
  </p>
</div>