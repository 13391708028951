import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-m-message-box',
  templateUrl: './m-message-box.component.html',
  styleUrls: ['./m-message-box.component.scss']
})
export class MMessageBoxComponent implements OnInit {
  @Input() text: string;
  @Input() type: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  close(): void {
    this.onClose.emit();

  }

}
