<div class="m-category-accordion-item">
  <header (click)="expended = !expended">
    <p class="name">{{categoryName}}</p>
    <p class="price">
      <ng-container *ngIf="categoryStartRate">
        <small>{{"à partir de" | customTranslation: "startingAt"}}</small>
        <strong>{{categoryStartRate | currency:currency.isoCode}}</strong>
      </ng-container>
    </p>
    <i class="webfont webfont-arrow" [ngClass]="{expended: expended}"></i>
  </header>
  <div class="list-rates" *ngIf="expended" [@slideInOut]>
    <ng-content select="[slotListRates]"></ng-content>
  </div>
</div>