import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-m-form-checkbox-list',
  templateUrl: './m-form-checkbox-list.component.html',
  styleUrls: ['./m-form-checkbox-list.component.scss']
})
export class MFormCheckboxListComponent {
  @Input() options: Array<any>;
  @Input() name: string;
  @Input() selected: string;
  @Input() label: string;
  @Input() formNumber: number;
  @Input() required: boolean;
  @Input() description: string;
  @Output() updateSelection: EventEmitter<string> = new EventEmitter<string>();

  selectedCheckboxChanged(value: any): void {
    this.updateSelection.emit(value);
  }


}
