<div class="m-form-ticket-accordion-item">
  <header (click)="expended = !expended" [style.pointerEvents]="canExpand ? 'all' : 'none'">
    <div class="left">
      <i class="webfont webfont-ticket"></i>
      <div class="infos">
        <div class="top">
          <p><span *ngIf="ticketCategory">{{ticketCategory}}</span><strong>{{ticketName}}</strong></p>
        </div>
        <div class="bottom" *ngIf="seatInfos">
          <p>Place : {{ seatInfos }}</p>
        </div>
      </div>
    </div>
    <div class="right"  *ngIf="canExpand">
      <p *ngIf="errorsNum && errorsNum > 0" class="errors-num">{{errorsNum}}</p>
      <i class="webfont webfont-arrow" [ngClass]="{expended: expended}"></i>
    </div>
  </header>
  <div class="form-container" *ngIf="expended" [@slideInOut]>
    <ng-content></ng-content>
  </div>
</div>
