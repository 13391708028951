<div class="a-form-score-field" (mouseout)="onMouseOut()">

  <label>{{label}}</label>
  <div class="score-container">
    <button *ngFor="let item of arrayOne(maxScore); let i = index;" (mouseover)="onMouseOver(i)"
      (click)="changeValue(i)" [ngClass]="{'hover':(currentHover != null && currentHover >= i), 'active': value >= i}">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#FFF" fill-rule="evenodd" stroke="#DDE0EB"
          d="M12.027.623c-.117.077-.212.188-.27.323L9 7.402l-6.978.633c-.145.014-.28.07-.388.157-.11.087-.195.207-.24.346-.045.138-.047.284-.01.42.037.135.113.26.223.356l5.274 4.624-1.555 6.848c-.032.143-.021.29.029.42.049.132.136.249.254.334.129.093.279.14.429.14.129 0 .258-.035.374-.104l6.017-3.598 6.016 3.598c.125.075.267.11.407.103.14-.006.278-.053.396-.139.12-.085.206-.202.255-.333.05-.132.062-.278.03-.421l-1.556-6.848 5.274-4.624c.11-.097.186-.221.223-.356.037-.136.035-.282-.01-.422-.045-.138-.13-.257-.239-.345-.109-.087-.243-.142-.388-.156l-6.978-.633L13.1.946c-.057-.134-.152-.245-.27-.323-.116-.077-.255-.12-.804 0z" />
      </svg>
    </button>
  </div>
  <p class="description" *ngIf="description">
    {{description}}
  </p>

  <p class="constraints" *ngIf="constraintsInfos">
    {{constraintsInfos}}
  </p>

</div>