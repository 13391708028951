import { Component, OnInit, ElementRef, NgZone, ViewChild } from '@angular/core';
import { ResizeObserver } from 'resize-observer';


@Component({
  selector: 'app-l-default',
  templateUrl: './l-default.component.html',
  styleUrls: ['./l-default.component.scss']
})

export class LDefaultComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
    // this sends body height to iframe's parent
    const ro = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        window.parent.postMessage({
          frameHeight: cr.height
        }, '*');
      }
    });
    ro.observe(document.body);
  }


}
