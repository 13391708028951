import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-form-title-section',
  templateUrl: './a-form-title-section.component.html',
  styleUrls: ['./a-form-title-section.component.scss']
})
export class AFormTitleSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
