import checkField from './check-field';
import TicketForm from './ticket-form.model';
import FormField from './form-field.model';
import AddOn from './add-on.model';
import Tax from './tax.model';
import TicketTypeCategory from './ticket-type-category.model';

export default class TicketType {
    _id: string;
    isVisible: boolean;
    taxId: Tax;
    name: string;
    price: number;
    form: TicketForm[];
    description: string;
    ticketTypeCategoryId: TicketTypeCategory;
    tickets: {
        isLimited: boolean,
        minimumSelectable: number,
        maximumSelectable: number
    };
    categoryName: string;
    scheduled: {
        isScheduled: boolean;
        startDate: Date;
        endDate: Date;
    }
    ticketQuota: number;
    quantity: number;
    seat: string;
    quota: number;
    stock: number;
    stockBuffer?: number;
    stockAvailable: number;
    addons: AddOn[];
    unavailable: boolean;
    isProduct: boolean;
    showCountDown: boolean;
    showRemainingPlace: boolean;

    constructor(_ticketType?: any) {
        this.quantity = 0;
        this.addons = [];
        this.seat = null;
        this.stockBuffer = 0
        if (checkField(_ticketType?._id)) {
            this._id = _ticketType._id;
        }
        if (checkField(_ticketType?.isVisible)) {
            this.isVisible = _ticketType.isVisible;
        }
        if (checkField(_ticketType?.taxId)) {
            this.taxId = _ticketType.taxId;
        }
        if (checkField(_ticketType?.name)) {
            this.name = _ticketType.name;
        }
        if (checkField(_ticketType?.price)) {
        }
        if (checkField(_ticketType?.description)) {
            this.description = _ticketType.description;
        }
        if (checkField(_ticketType?.tickets)) {
            this.tickets = _ticketType.tickets;
        }
        if (checkField(_ticketType?.ticketTypeCategoryId)) {
            this.ticketTypeCategoryId = _ticketType.ticketTypeCategoryId;
        }
        if (checkField(_ticketType?.ticketQuota)) {
            this.ticketQuota = _ticketType.ticketQuota;
        }
        if (checkField(_ticketType?.stock)) {
            this.stock = _ticketType.stock;
            this.stockAvailable = _ticketType.stockAvailable;
        }
        else if (_ticketType?.quota !== null && _ticketType?.stock == 0) {
            this.unavailable = true;
        }
        if (checkField(_ticketType?.categoryName)) {
            this.categoryName = _ticketType.categoryName;
        }
        if (checkField(_ticketType?.addons)) {
            this.addons = _ticketType.addons;
        }
        if (checkField(_ticketType?.isProduct)) {
            this.isProduct = _ticketType.isProduct;
        }
        if (_ticketType != undefined) {
            this.quota = _ticketType.quota
        }
        this.price = _ticketType?.price ?  _ticketType?.price / 100 : 0.00;
        this.showCountDown = _ticketType?.showCountDown
        this.showRemainingPlace = _ticketType?.showRemainingPlace
        this.scheduled = _ticketType?.scheduled
    }

    createForm() {
        const fields = [];
        fields.push(this.createField('textfield', 'Nom Complet'));
        fields.push(this.createField('textarea', 'Dîtes nous vos pensées'));
        // fields.push(this.createField('email', 'Adresse Email', null, "Il faut l'adresse email pour pouvoir envoyer un email"));
        // fields.push(this.createField('phone', 'Numéro de Téléphone'));
        // fields.push(this.createField('date', 'Date entre 01/08 et 05/08'));
        // fields.push(this.createField('radio', 'Radio préférée', [{ label: 'RFM' }, { label: 'RMC' }, { label: 'RTL' }]));
        // fields.push(this.createField('checkbox', 'Encas', [
        //     { label: 'Cookie', checked: false },
        //     { label: 'Jus d\'orange', checked: false },
        //     { label: 'Brioche', checked: false }
        // ]));
        // fields.push(this.createField('select', 'Véhicule', [{ label: 'Voiture' }, { label: 'Vélo' }, { label: 'Chaussures' }]));
        // fields.push(this.createField('upload', 'CNI'));
        // fields.push(this.createField('time', 'Heure d\'arrivée'));
        // fields.push(this.createField('link', 'Profil twitter'));
        // fields.push(this.createField('number', 'Nombre d\'amis'));
        // fields.push(this.createField('score', 'Veuillez noter ce formulaire sur 10'));
        // fields.push(this.createField('address', 'Adresse Postale'));
        return fields;
    }

    createField(type, label, options = null, description = null) {
        const constraints = [];
        if (type === 'upload') {
            constraints.push({ type: 'fileType', extension: 'png' });
            constraints.push({ type: 'fileType', extension: 'jpeg' });
            constraints.push({ type: 'fileType', extension: 'jpg' });
        }
        else if (type === 'date') {
            constraints.push({ type: 'dateRange', startDate: '08/01/2020', endDate: '08/06/2020' });
        }
        return { type, label, isRequired: true, options, constraints, description }
    }

    createRandomForm(size: number): any[] {
        const forms = [];
        const types = ['textfield', 'textarea', 'email', 'phone', 'date', 'radio', 'checkbox',
            'select', 'upload', 'time', 'link', 'number', 'score', 'address'];
        for (let i = 0; i < size; i++) {
            const form = { fields: [] };
            for (let j = 0; j < 5; j++) {
                const type = types[Math.floor(Math.random() * types.length)];
                const label = `Champ ${j} - ${type}`;
                const isRequired = Math.floor(Math.random() * 2) === 1;
                const options = [];
                if (['checkbox', 'radio'].includes(type)) {
                    for (let k = 0; k < 3; k++) {
                        options.push({ label: `Option - ${type} ${k}`, value: `option${i}${j}${k}` })
                    }
                }
                form.fields.push(new FormField({ type, label, isRequired, options }))
            }
            forms.push(form);
        }
        return forms;
    }

}
