<app-t-content-w-sidebar>

  <div *ngIf="initDone" id="contact-information" slotContent>
    <app-a-title-main [text]='"Coordonnées" | customTranslation'></app-a-title-main>


    <app-m-content-section *ngIf="!widget.forProduct">
      <app-m-form-radio-list slotContent [options]="ticketsSendingOptions"
        (updateSelection)="changeSendingOption($event)" name="ticketsSendingOptions">
      </app-m-form-radio-list>
    </app-m-content-section>


    <!-- TICKET LIST -->

    <app-m-content-section>
      <h2 slotTitle>{{widget.forProduct ? ('Liste des produits' | customTranslation: 'productList') :( 'Liste des tickets' | customTranslation: 'ticketList')}} :</h2>
      <app-m-message-box slotContent *ngIf="billingForm.isValid && invalidForm && errorMessageOpen"
        (onClose)="errorMessageOpen = false"
        [text]="'Veuillez compléter les champs manquants pour procéder au paiement de votre commande.' | customTranslation: 'invalidForm'"
        type="error">
      </app-m-message-box>
      <app-m-message-box slotContent *ngIf="minAddOnRequired && minAddOnMessageOpen"
        (onClose)="minAddOnMessageOpen = false"
        [text]="'Veuillez sélectionner au moins ' + event.ticketing?.minimumAddonAmount + ' option(s) par ticket.' | customTranslation: 'invalidForm'"
        type="error">
      </app-m-message-box>

      <ng-container slotContent class="tickets_list" *ngFor="let ticket of (widget.forProduct ? products : tickets); let i = index">
        <app-m-form-ticket-accordion-item [ticketCategory]="ticket.categoryName" [ticketName]="ticket.name"
          [seatInfos]="ticket.seat"
          [expendedSubscription]="expendedSubscription"
          [canExpand]="!widget.forProduct"
          [errorsNum]="!widget.forProduct && formMissingFields(ticket) + orderTicketForms[i].missingFields + formAddonRequired(ticket)">
          <ng-container *ngIf="!widget.forProduct">
          <app-m-form-row columns="1fr-1fr">
            <ng-container *ngFor="let field of orderTicketForms[i].fields">
              <app-a-form-input-field [id]="'order_'+field.label+'_'+i" [label]="field.label"
                [validate]="checkIfValidate(field)" [required]="field.isRequired" [description]="field.description"
                [constraintsInfos]="field.constraintsInfos" [type]="getType(field.type)" [value]="field.value"
                (InputChange)="placeholderFunction($event, field, orderTicketForms[i])">
              </app-a-form-input-field>
            </ng-container>
          </app-m-form-row>
          <app-m-form-row *ngIf="ticket.form; let j = index" columns="1fr-1fr">
            <ng-container *ngFor="let form of ticket.form">
              <ng-container *ngFor="let field of form.fields; let k = index">
                <app-a-form-input-field
                  *ngIf="['textfield', 'email', 'phone', 'number', 'address', 'date', 'link', 'time'].includes(field.type)"
                  [id]="'form_'+field.id" [label]="field.label" [validate]="checkIfValidate(field)"
                  [required]="field.isRequired" [description]="field.description"
                  [constraintsInfos]="field.constraintsInfos" [type]="getType(field.type)" [value]="field.value"
                  (InputChange)="placeholderFunction($event, field, form)">
                </app-a-form-input-field>

                <app-a-form-textarea-field *ngIf="field.type === 'textarea'" [label]="field.label"
                  [id]="'form_'+field.id" [validate]="checkIfValidate(field)" [required]="field.isRequired"
                  [description]="field.description" [constraintsInfos]="field.constraintsInfos"
                  (InputChange)="placeholderFunction($event, field, form)">
                </app-a-form-textarea-field>

                <app-a-form-file-field *ngIf="field.type === 'upload'" [label]="field.label" [file]="field.value"
                  [id]="'form_'+field.id" [description]="field.description" [constraintsInfos]="field.constraintsInfos"
                  [required]="field.isRequired" [validate]="checkIfValidate(field)"
                  (FileChange)="treatFile($event, field, form)" (FileDelete)="placeholderFunction($event, field, form)">
                </app-a-form-file-field>

                <app-a-form-score-field *ngIf="field.type === 'score'" [description]="field.description"
                  [constraintsInfos]="field.constraintsInfos" [label]="field.label" [maxScore]="10"
                  (InputChange)="placeholderFunction($event, field, form)">
                </app-a-form-score-field>

                <app-m-form-radio-list *ngIf="field.type === 'radio'" [description]="field.description"
                  [constraintsInfos]="field.constraintsInfos" [label]="field.label" [options]="field.options"
                  [selected]="field.value" [required]="field.isRequired"
                  (updateSelection)="placeholderFunction($event, field, form)" [formNumber]="i+'_'+j +'_'+k">
                </app-m-form-radio-list>

                <app-m-form-checkbox-list *ngIf="field.type === 'checkbox'" [description]="field.description"
                  [constraintsInfos]="field.constraintsInfos" [label]="field.label" [options]="field.options"
                  [selected]="field.value" [required]="field.isRequired"
                  (updateSelection)="placeholderFunction($event, field, form)" [formNumber]="i+'_'+j +'_'+k">
                </app-m-form-checkbox-list>

                <app-a-form-select-field *ngIf="field.type === 'select'" [description]="field.description"
                  [constraintsInfos]="field.constraintsInfos" [id]="'form_'+field.id" [label]="field.label"
                  [required]="field.isRequired" [value]="field.value" [options]="field.options"
                  (InputChange)="placeholderFunction($event, field, form)">
                </app-a-form-select-field>

              </ng-container>
            </ng-container>
          </app-m-form-row>
          <div *ngIf="ticket.addons.length">
            <app-a-form-title-section>
              {{"Options" | customTranslation: "options"}}
              <span class="mandatory" *ngIf="event.ticketing.minimumAddonAmount">*</span>
              <span class="subtitle-section"
                *ngIf="event.ticketing.minimumAddonAmount && (event.ticketing.limitAddonAmount && ticket.addons.length > event.ticketing.limitAddonAmount); else elseBlock"
              >
                {{ event.ticketing.minimumAddonAmount }} minimum, {{ event.ticketing.limitAddonAmount }} maximum
              </span>
              <ng-template #elseBlock>
                <span class="subtitle-section" *ngIf="event.ticketing.minimumAddonAmount">
                  {{ event.ticketing.minimumAddonAmount }} minimum
                </span>
                <span class="subtitle-section" *ngIf="event.ticketing.limitAddonAmount && ticket.addons.length > event.ticketing.limitAddonAmount">
                  {{ event.ticketing.limitAddonAmount }} maximum
                </span>
              </ng-template>

            </app-a-form-title-section>
            <ul class="form-toggle-field-list">
              <li *ngFor="let addOn of ticket.addons; let a = index" [ngClass]="{'disabled': addOn.unavailableFor(i) || (addonsDisabled[i] && !addOn.isChecked)}"
              (mouseover)="currentAddonHovered=i + '' + a" (mouseleave)="currentAddonHovered=null">
                <div class="addon_content">
                  <app-a-form-toggle-field text="{{ addOn.description }}" label="{{addOn.name}} ({{addOn.price | currency:currency.isoCode}})"
                    [checked]="addOn.isChecked" (checkedChange)="addOnChecked($event, addOn, i, ticket)">
                  </app-a-form-toggle-field>

                </div>
                <div *ngIf="addOn.unavailableFor(i) && currentAddonHovered==i + '' + a" class="disabled-tooltip">
                  <div>
                    <span>{{'Plus de stock disponible pour' | customTranslation: 'noMoreStockFor'}} {{"cette option"|
                      customTranslation: 'thisAddon'}}</span>
                  </div>
                </div>
              </li>
            </ul>

          </div>

        </ng-container>

        </app-m-form-ticket-accordion-item>

      </ng-container>
    </app-m-content-section>

    <app-m-content-section *ngIf="billingForm" type="white-content-box">
      <h2 *ngIf="!allFree" slotTitle>{{"Informations de Facturation" | customTranslation: "billingInfos"}} :</h2>
      <h2 *ngIf="allFree" slotTitle>{{"Informations" | customTranslation: "infos"}}:</h2>
      <app-m-form-row-container slotContent>
        <app-m-form-row columns="1fr-1fr">
          <ng-container *ngFor="let field of billingForm.fields; let i = index">
            <ng-container *ngIf="i || allFree">
              <app-a-form-input-field
                *ngIf="['textfield', 'email', 'phone', 'number', 'address', 'date', 'link', 'time'].includes(field.type)"
                [label]="field.label" [id]="'billing_'+i" [validate]="checkIfValidate(field)"
                [required]="field.isRequired" [description]="field.description"
                [constraintsInfos]="field.constraintsInfos" [type]="getType(field.type)" [value]="field.value"
                (InputChange)="placeholderFunction($event, field, billingForm)">
              </app-a-form-input-field>

              <app-m-form-radio-list *ngIf="field.type === 'radio'" [description]="field.description"
                [constraintsInfos]="field.constraintsInfos" [label]="field.label" [options]="field.options"
                [selected]="field.value" [required]="field.isRequired"
                (updateSelection)="placeholderFunction($event, field, billingForm)" [formNumber]="i">
              </app-m-form-radio-list>

            </ng-container>
          </ng-container>
        </app-m-form-row>
        <app-m-form-row *ngIf="!allFree">
          <app-a-form-checkbox-radio-field [label]="billingForm.fields[0].label"
            [validate]="checkIfValidate(billingForm.fields[0])" [required]="billingForm.fields[0].isRequired"
            [value]="billingForm.fields[0].options[0]" type="checkbox"
            (checkedChange)="placeholderFunction($event, billingForm.fields[0], billingForm)">
          </app-a-form-checkbox-radio-field>
        </app-m-form-row>
      </app-m-form-row-container>
    </app-m-content-section>


  </div>

  <app-o-summary slotSidebar [billingForm]="billingForm" [orderTicketForms]="orderTicketForms"
    [ticketsToOrder]="tickets" [buttonIsDisabled]="invalidForm || minAddOnRequired"></app-o-summary>


</app-t-content-w-sidebar>