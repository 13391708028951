<main id="l-default">

    <div class="layout-header">
        <ng-content select="[slotHeader]"></ng-content>
    </div>
    <div class="layout-timeline">
        <ng-content select="[slotTimeline]"></ng-content>
    </div>
    <div class="layout-content">
        <ng-content select="[slotContent]"></ng-content>
    </div>

</main>
