import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
// import { AuthService } from 'src/app/providers';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HTTPTools } from '../http-tools';


@Injectable()
export class Interceptor implements HttpInterceptor {

    message = 0;

    constructor(
        public router: Router
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const contentType = HTTPTools.getNextContentType();
        const setHeaders = contentType ? {'Content-Type': contentType} : {};
        if (request.url.includes(environment.apiUrl)) {
            request = request.clone({setHeaders});
        } else {
            request = request.clone({setHeaders});
        }
        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 404) {
                    this.router.navigate(['/404']);
                }
                if (error.status === 401 || error.status === 403) {
                    this.router.navigate(['/403']);
                }
                return throwError(error);
            })
        );
    }
}
