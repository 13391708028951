import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store } from '@ngxs/store';
import { SetKeysGlobal } from 'src/app/store/actions/global.actions';

import { DatesType } from 'src/app/models/type.definition';
import Event from 'src/app/models/event.model';

// Providers
import {
  CurrencyService,
  EventService,
  EventWidgetService,
  CommonService,
  SessionsService,
  TimeLineService
} from 'src/app/providers';

import { Subscription } from 'rxjs';
import Widget from 'src/app/models/widget.model';

@Component({
  selector: 'app-init-page',
  templateUrl: './init-page.html',
  styleUrls: ['./init-page.scss']
})

export class InitPage implements OnInit, OnDestroy {

  loading: boolean;
  subscriptions = new Subscription();
  colors: any = {
    colorMain: '#0627d9',
    colorMainText: '#000000',
    colorMainBg: '#f6f7fa',
    colorSecondBg: '#fff'
  }
  remainDays: number;


  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private eventWidgetService: EventWidgetService,
    private commonService: CommonService,
    private eventService: EventService,
    private sessionService: SessionsService,
    private store: Store,
    private timeLineService: TimeLineService,
    private currencyService: CurrencyService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  healthCheck() {
    this.subscriptions.add(this.commonService.healthCheck().subscribe(async _ => {
      if (_.status === 'error') {
        this.router.navigate(['/maintenance'], { queryParams: { from: this.router.url } });
        this.store.dispatch(new SetKeysGlobal({ value: true, key: 'isMaintenance' }));
        return
      }
    }, async (err) => {
      this.router.navigate(['/maintenance'], { queryParams: { from: this.router.url } });
      this.store.dispatch(new SetKeysGlobal({ value: true, key: 'isMaintenance' }));
      return
    }))
  }

  ngOnInit(): void {
    this.loading = true;
    this.healthCheck();
    this.subscriptions.add(this.route.params.subscribe(_params => {

      // Store dans le State la valeure widgetId
      this.store.dispatch(new SetKeysGlobal({ value: _params.id, key: 'widgetId' }));

      // Body de la requête getById du WidgetService
      const body = {
        filter: {
          eventId: _params.id
        },
        populate: ['eventId', 'organisationId'],
        sort: [['name', 1]]
      };

      // Requête pour récupérer le widget
      this.subscriptions.add(this.eventWidgetService.getById(_params.id, body).subscribe(async (_result: Widget) => {
        if (_result.eventId) {
          const bookType = await this.eventService.getBookType(_result.eventId._id);
          const isRegistration = !_result.forProduct && (bookType == 'registration' || _result?.isRegistration === true);
          this.store.dispatch(new SetKeysGlobal({ value: isRegistration, key: 'isRegistration' }));
          this.store.dispatch(new SetKeysGlobal({ value: isRegistration, key: 'allFree' }));
          this.store.dispatch(new SetKeysGlobal({ value: {
            gtm: _result.tracking?.gtm || "",
            pixel: _result.tracking?.pixelMeta || "",
            ga: _result.tracking?.ga || ""
          }, key: 'tracking' }));
          if (_result.eventId.bookType === "nft") {
            this.store.dispatch(new SetKeysGlobal({ value: true, key: 'isNft' }))
          }
          if (_result.trackingScript) {
            this.setTrackingScript(_result);
          }
          if (_result.eventId.status !== 'published') {
            this.router.navigate([`${_params.id}/closed`]);
          }
          let sessions = null
          if (_result.eventId?.dates?.type == 'sessions') {
            const sessionBody = {
              filter: { eventId: _result.eventId._id }
            }
            const sessionsResult = await this.sessionService.getList(sessionBody).toPromise();
            if (sessionsResult) {
              sessions = sessionsResult?.data || [];
              this.store.dispatch(new SetKeysGlobal({ value: sessions, key: 'sessions' }));
            }
            else {
              this.router.navigate([`${_params.id}/closed`]);
            }
          }
          this.remainDays = this.calcRemainDays(_result.eventId);


          // GESTION DYNAMIQUE DES COULEURS
          const css = ` :root
          {
            --color-main: ${_result.customStyles.link};
            --color-main-text: ${_result.customStyles.text};
            --color-main-bg: ${_result.customStyles.background};
            --color-main-title: ${_result.customStyles.title};
            --color-second-bg: #fff;
          }`;
          const head = document.getElementsByTagName('head')[0];
          const style = document.createElement('style');
          style.type = 'text/css';
          style.appendChild(document.createTextNode(css));
          head.appendChild(style);

          const currencyId = _result?.eventId?.ticketing?.defaultCurrencyId._id
          this.subscriptions.add(this.currencyService.getById(currencyId).subscribe(async res => {
            this.currencyService.currency$.next(res);

            // Store dans le State le widget et l'eventId
            this.store.dispatch(new SetKeysGlobal({ value: _result, key: 'widget' }));
            this.store.dispatch(new SetKeysGlobal({ value: _result.eventId, key: 'eventId' }));

            const hasInsurance = _result.eventId.ticketing.hasInsurance || false

            this.store.dispatch(new SetKeysGlobal({ value: hasInsurance, key: 'hasInsurance' }));

            let route = (this.remainDays && this.remainDays < 0) ? 'closed' : this.checkType(_result.eventId?.dates?.type);

            if (_result.eventId?.useRoomPlacement && route !== 'closed' && (sessions == null || sessions?.length == 0)) {
              route = 'seat-selection';
            }

            if (!route) {
              this.router.navigate(['/404']);
            }
            else {
              // Suit la route récupérée et met à jour le component Timeline
              // this.timeLineService.updateTimeLine(route);
              this.router.navigate([`${_params.id}/${route}`]);
            }
          }, err => {
            this.loading = false;
          }));

        }
        else {
          this.router.navigate(['/404']);
        }
      }));
    }));
  }

  checkType(_eventType: DatesType): string | void {
    switch (_eventType) {
      case 'interval': return 'tickets-list';
      case 'sessions': return 'date-selection';
      default: return;
    }
  }

  getMaxDate() {

  }

  setTrackingScript(widget: Widget): void {
    this.setScript('script', widget);
    this.setScript('noscript', widget)
  }

  setScript(tag: 'script' | 'noscript', widget: Widget) {
    const contentStart = widget.trackingScript.indexOf(`<${tag}>`);
    const contentEnd = widget.trackingScript.indexOf(`</${tag}>`);
    if (contentStart > -1 && contentEnd > -1) {
      const content = widget.trackingScript.substring(contentStart + tag.length + 2, contentEnd);
      let node = document.createElement(tag);
      if (node instanceof HTMLScriptElement) {
        node.type = 'text/javascript';
      }
      node.innerHTML = content
      document.head.appendChild(node);
    }
  }

  calcRemainDays(event: Event): number {
    let endDate: Date;
    let startDate: Date;
    const now = new Date();
    if (event.ticketing.date.startDate) {
      startDate = new Date(event.ticketing.date.startDate);
    }
    if (event.ticketing.date.endDate) {
      endDate = new Date(event.ticketing.date.endDate);
    }
    if (startDate) {
      if (startDate.getTime() > now.getTime()) {
        return -1;
      }
    }
    if (endDate) {
      return this.getDaysBetweenDates(new Date(endDate), now)
    }
  }
  getDaysBetweenDates(date1: Date, date2: Date): number {
    return Math.floor((date1.getTime() - date2.getTime()) / 86400000);
  }
}
