<ol *ngIf="currentStep" id="m-timeline" [class]="'currentStep--'+currentStep"
[ngClass]="{'withDate': stepDateExist, 'noReservation': noPayment, 'isNft': isNft,'withInsurance': stepInsuranceExist}">
  <li *ngIf="stepDateExist"
    [ngClass]="{active:  currentStep === 'dateSelection', checked: currentStep === 'insurance' || currentStep == 'ticketsList' ||  currentStep === 'contactInformation' ||  currentStep === 'summary' || currentStep ==='confirmation' }">
    <i class="webfont webfont-check"><span>1</span></i>
    <p>{{"Date" | customTranslation}}</p>
  </li>
  <li
    [ngClass]="{active:  currentStep === 'ticketsList', checked:  currentStep === 'insurance' || currentStep === 'order-summary' || currentStep === 'contactInformation' ||  currentStep === 'summary' || currentStep ==='confirmation'}">
    <i *ngIf="stepDateExist" class="webfont webfont-check"><span>2</span></i>
    <i *ngIf="!stepDateExist" class="webfont webfont-check"><span>1</span></i>
    <p>{{"Réservation" | customTranslation: "timelineBooking"}}</p>
  </li>

  <li
    [ngClass]="{active:  currentStep === 'contactInformation', checked:  currentStep === 'insurance' || currentStep === 'order-summary' || currentStep === 'summary' || currentStep ==='confirmation'}">
    <i *ngIf="stepDateExist" class="webfont webfont-check"><span>3</span></i>
    <i *ngIf="!stepDateExist" class="webfont webfont-check"><span>2</span></i>
    <p>{{"Coordonnées" | customTranslation }}</p>
  </li>

  <li
    *ngIf="stepInsuranceExist && !isNft"
    [ngClass]="{active:  currentStep === 'insurance', checked:  currentStep === 'order-summary' || currentStep === 'summary' || currentStep ==='confirmation'}">
    <i *ngIf="stepDateExist" class="webfont webfont-check"><span>4</span></i>
    <i *ngIf="!stepDateExist" class="webfont webfont-check"><span>3</span></i>
    <p>{{"Assurance" | customTranslation }}</p>
  </li>

  <!-- NFT VERSION -->
  <li
    *ngIf="isNft"
    [ngClass]="{active:  currentStep === 'order-summary', checked: currentStep === 'summary' || currentStep ==='confirmation'}">
    <i *ngIf="stepDateExist" class="webfont webfont-check"><span>4</span></i>
    <i *ngIf="!stepDateExist" class="webfont webfont-check"><span>3</span></i>
    <p>{{"Vérification" | customTranslation: "timelineOrderSummary"}}</p>
  </li>

  <li *ngIf="!noPayment && isNft" [ngClass]="{active:  currentStep === 'summary',checked:  currentStep ==='confirmation'}">
    <i *ngIf="stepDateExist" class="webfont webfont-check"><span>5</span></i>
    <i *ngIf="!stepDateExist" class="webfont webfont-check"><span>4</span></i>
    <p>{{"Paiement" | customTranslation: "timelinePayment"}}</p>
  </li>
  <li
    *ngIf="isNft"
    [ngClass]="{active:  currentStep === 'confirmation',checked: currentStep ==='confirmation'}">
    <i *ngIf="stepDateExist" class="webfont webfont-finish"><span>6</span></i>
    <i *ngIf="(!stepDateExist && !noPayment) || (stepDateExist && noPayment)" class="webfont webfont-finish"><span>5</span></i>
    <i *ngIf="!stepDateExist && noPayment" class="webfont webfont-finish"><span>4</span></i>
    <p>{{"Confirmation" | customTranslation: "timelineConfirm"}}</p>
  </li>

  <!-- No NFT VERSION -->
  <li *ngIf="!noPayment && !isNft" [ngClass]="{active:  currentStep === 'summary',checked:  currentStep ==='confirmation'}">
    <i *ngIf="stepDateExist && stepInsuranceExist" class="webfont webfont-check"><span>5</span></i>
    <i *ngIf="stepDateExist && !stepInsuranceExist" class="webfont webfont-check"><span>4</span></i>
    <i *ngIf="!stepDateExist && stepInsuranceExist" class="webfont webfont-check"><span>4</span></i>
    <i *ngIf="!stepDateExist && !stepInsuranceExist" class="webfont webfont-check"><span>3</span></i>
    <p>{{"Paiement" | customTranslation: "timelinePayment"}}</p>
  </li>
  <li
    *ngIf="!isNft"
    [ngClass]="{active:  currentStep === 'confirmation',checked: currentStep ==='confirmation'}">
    <i *ngIf="stepDateExist && stepInsuranceExist" class="webfont webfont-finish"><span>6</span></i>
    <i *ngIf="stepDateExist && !stepInsuranceExist" class="webfont webfont-finish"><span>5</span></i>
    <i *ngIf="(!stepDateExist && !noPayment && stepInsuranceExist) || (stepDateExist && noPayment)" class="webfont webfont-finish"><span>5</span></i>
    <i *ngIf="(!stepDateExist && !noPayment && !stepInsuranceExist) || (stepDateExist && noPayment)" class="webfont webfont-finish"><span>4</span></i>
    <i *ngIf="!stepDateExist && noPayment" class="webfont webfont-finish"><span>3</span></i>
    <p>{{"Confirmation" | customTranslation: "timelineConfirm"}}</p>
  </li>
</ol>