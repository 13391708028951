<div id="t-content-w-sidebar" [class]="'type--' + type">
  <!-- slot top page -->
  <div class="top-page">
    <ng-content select="[slotTopPage]"></ng-content>
  </div>
  <div class="row">
    <!-- slot content -->
    <div id="content">
      <ng-content select="[slotContent]"></ng-content>
    </div>
    <!-- slot sidebar-->
    <aside class="relative">
      <div class="fixed">
        <ng-content select="[slotSidebar]"></ng-content>
      </div>
    </aside>
  </div>
</div>