<hr />
<app-a-button *ngIf="!showCouponForm" [text]="'Ajouter un code promo'" type="borderBlack" (buttonClicked)="showCouponForm = true">
</app-a-button>

<div *ngIf="showCouponForm" class="coupon-ctn">
  <input [disabled]="inputDisabled" type="text"
    attr.aria-label="{{'Code promo' | customTranslation: 'addPromoCode'}}"
    placeholder="{{'Code promo' | customTranslation: 'addPromoCode'}}" [(ngModel)]="coupon"
    (keydown.enter)="addCoupon()" />

  <button *ngIf="!inputDisabled" [disabled]="inputDisabled" aria-label="Ajouter" title="ajouter"
    class="submit add" (click)='addCoupon()'>
    {{'Valider' | customTranslation: 'addPromoCode'}}
  </button>
  <button *ngIf="inputDisabled" aria-label="retirer" title="retirer"
    class="submit webfont webfont-close remove" (click)='removeCoupon()'>
  </button>
</div>
<p class="message" *ngIf="messageText != ''" [class]="messageType">{{messageText}}</p>