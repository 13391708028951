import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PromiserService {

  constructor() { }

  async waitFor(method: (res, rej, ref) => any, ref) {
    return new Promise(async (res, rej) => method(res, rej, ref))
      .then(data => data)
      .catch(err => err)
  }

}
