import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-logo',
  templateUrl: './a-logo.component.html',
  styleUrls: ['./a-logo.component.scss']
})
export class ALogoComponent implements OnInit {
  @Input() registration: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
