<div [ngClass]="{'disabled' : isDisabled, 'btn-ctn': true}" (click)="setMouseClick()" (mouseenter)="setMouseHover(true)" (mouseleave)="setMouseHover(false)">
    <button (click)="clickButton()" [class]="'type--'+type" [ngClass]="{'disabled' : isDisabled, 'loading': isLoading, 'error': displayTooltip}"
        [disabled]="isDisabled">
        {{text}}
    </button>
    <app-a-tooltip [duration]="5000" *ngIf="isDisabled && displayTooltip">
        {{clickTooltip}}
    </app-a-tooltip>
</div>
<div *ngIf="isDisabled && displayHover" class="hover_infos">{{hover}}</div>
