import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, TimeLineService } from 'src/app/providers';
import { GlobalState } from 'src/app/store/states/global.state';
import { Store } from '@ngxs/store';
import { FormFieldOptions } from 'src/app/models/type.definition';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.html',
  styleUrls: ['./registration.scss']
})
export class RegistrationPage implements OnInit {

  widgetId: string;
  organisationId: string;
  registrationForm: FormGroup;
  tickets: FormFieldOptions[];
  sessionId: string;
  eventId: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private timeLineService: TimeLineService,
    private eventService: EventService,
    private store: Store,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.timeLineService.updateTimeLine('contactInformation');
    this.route.params.subscribe(_params => {
      this.widgetId = _params.id;
      const session = this.store.selectSnapshot(state => GlobalState.get(state, 'session'));
      this.sessionId = session?.id;
      const widget = this.store.selectSnapshot(state => GlobalState.get(state, "widget"));
      this.eventId = widget.eventId;
      this.organisationId = widget.event.organisationId
      const tickets = this.store.selectSnapshot(state => GlobalState.get(state, 'tickets'));
      this.tickets = tickets.map(_ => {
        return {
          label: (_.categoryName ? `${_.categoryName} - ` : '') + _.name, 
          value: _.id
        }
      });
      this.initForm();
    });
  }

  initForm(){
    this.registrationForm = this.formBuilder.group({
      ticketTypeId: new FormControl(null, Validators.required),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email])
    })
  }

  changeField(key, event){
    this.registrationForm.controls[key].setValue(event);
  }

  onSubmit(): void {
    const formData = {
      ...this.registrationForm.value,
      eventId: this.eventId,
      organisationId: this.organisationId,
      sessionId: this.sessionId || null
    }
    this.eventService.waitingList(formData).subscribe(res => {
      this.router.navigate([`${this.widgetId}/confirmation/registration/${res.id}`]);
    })
  }


}






