import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-m-form-row',
  templateUrl: './m-form-row.component.html',
  styleUrls: ['./m-form-row.component.scss']
})
export class MFormRowComponent implements OnInit {

  @Input() columns: string;
  constructor() { }

  ngOnInit(): void {
  }

}
