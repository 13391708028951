import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import Session from 'src/app/models/session.model';

import * as moment from 'moment';
moment.locale('fr');

@Component({
  selector: 'app-o-date-selection-box',
  templateUrl: './o-date-selection-box.component.html',
  styleUrls: ['./o-date-selection-box.component.scss']
})
export class ODateSelectionBoxComponent implements OnInit, OnDestroy {
  @Output() sessionSelected: EventEmitter<Session> = new EventEmitter();
  @Input() date: Date;
  @Input() sessions: Session[];
  @Input() changeOfDate: Subject<void>;
  private _subscription = new Subscription();
  selectedSession: Session;
  selectedSessionID: string;
  loading: boolean = false;
  public selectableHours: any = []
  constructor() { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {

    this._subscription.add(this.changeOfDate.subscribe(() => {
      setTimeout(() => {
        this.selectedSession = this.sessions[0];
        this.selectedSessionID = this.sessions[0]._id;
        this.selectableHours = [];
        for (let i = 0; i < this.sessions.length; i++) {

          let hour = {
            label: moment(this.sessions[i].date).format("HH[h]mm"),
            value: this.sessions[i]._id
          }
          this.selectableHours.push(hour);

        }
      });


    }));


  }

  changeSession(event): void {
    this.selectedSession = this.sessions.find(_ => _._id === event);
  }

  selectSession(): void {
    this.sessionSelected.emit(this.selectedSession);
  }

}
